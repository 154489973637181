<template>
  <div class="vh-100 vw-100">
    <b-row class="justify-content-center h-100">
      <div class="col-12 col-lg-6 d-flex position-relative">
        <form class="m-auto text-center w-75">
          <div class="font-weight-bolder text-left mt-5">
            <b-form-group id="input-group-1" label="Informe a nova senha" label-for="senha">
              <b-form-input
                id="senha"
                placeholder="Digite a nova senha"
                v-model="senha"
                required
                type="password"
                v-on:keydown.enter.prevent="RecuperarSenha"
              ></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-1" label="Confirmar senha" label-for="confirmarSenha">
              <b-form-input
                id="confirmarSenha"
                placeholder="Confirme a senha"
                v-model="confirmarSenha"
                required
                type="password"
                v-on:keydown.enter.prevent="RecuperarSenha"
              ></b-form-input>

            </b-form-group>
          </div>

          <p
            class="text-left text-primary"
            style="text-decoration: underline; cursor: pointer"
          >
            <router-link to="/login">Voltar</router-link>
          </p>

          <button
            class="btn w-100 mt-5 bg-info text-white font-weight-bold"
            type="button"
            @click="RecuperarSenha"
          >
            Alterar senha
          </button>
        </form>
        <figure
          class="position-absolute d-flex align-items-center"
          style="left: 2em; top: 1em"
        >
          <img
            src="@/assets/logo_no_bg.png"
            alt="Logo SDC"
            style="height: 52px"
          />
          <figcaption class="ml-3">
            <h4 class="font-weight-bolder">SDC</h4>
            <span>Sistema de Gestão de Convênios</span>
          </figcaption>
        </figure>
      </div>
      <div class="col-6 d-none d-lg-flex position-relative bg-info">
        <img src="@/assets/logo_no_bg.png" alt="Logo SDC" class="h-50 m-auto" />
        <div class="position-absolute" style="right: 2em; top: 1em">
          <img src="@/assets/brazao 1.svg" alt="Brazão Amapá" height="52px" />
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'RecuperarSenha',
  data() {
    return {
      senha: '',
      confirmarSenha: '',
      email: '',
      token: ''
    };
  },
  methods: {
    async RecuperarSenha() {
      if(!this.isPasswordEqual()) return

      if (this.email != '') {
        try {
          console.log('getRequest')
          const { data } = await this.$http.post('resetar-senha', {
            token: this.token,
            email: this.email,
            password: this.senha,
          });

          this.$router.push('/login')

          this.$swal({
            title: 'Senha redefinida com sucesso!',
            text: data.message,
            icon: 'success',
          });
        } catch (error) {
          this.$swal({
            title: 'Erro ao redefinir senha!',
            text: 'Preencha a senha e tente novamente',
            icon: 'error',
          });
          console.log(error.response);
        }
      } else {
        this.$swal({
          title: 'Erro ao redefinir senha!',
          text: 'Preencha a senha e tente novamente',
          icon: 'error',
        });
      }
    },
  isPasswordEqual () {
    if (this.senha.length < 8){
      this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          title: 'A senha deve ter pelo menos 8 caracteres!',
          icon: 'error',
      });

      return false;
    }

    if (this.confirmarSenha !== this.senha){
      this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          title: 'As senhas são diferentes!',
          icon: 'error',
      });

      return false;
    };

    return true;
  },
},
  async mounted() {
    if (this.$route.params.token && this.$route.params.email) {
      this.token = this.$route.params.token;
      this.email = this.$route.params.email;
    }else{
      this.$router.push('/login')
    }
  },
};
</script>
