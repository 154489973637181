<template>
  <div>
    <div v-if="!openPlanoTrabalho">
      <div>
        <p><strong>Descrição:</strong> {{ solicitacao.descricao }}</p>
      </div>

      <div v-if="solicitacao.anexos">
        <p><strong>Resposta:</strong></p>
        <div v-for="anexo in solicitacao.anexos" :key="anexo.id" class="mb-2">
          {{ anexo.tipo_anexo.descricao }} -
          {{ anexo.nome_original }}
        </div>
      </div>

      <div>
        <b-form @submit.prevent="handleSubmit">
          <b-form-textarea
            id="textarea-documentos"
            v-model="descricao"
            max-rows="6"
            placeholder="Responder a solicitação..."
            rows="3"
          ></b-form-textarea>
          <form-error
            v-if="validationErrors.descricao"
            :error="['O texto de resposta é obrigatório']"
          />
          <br />

          <b-file
            v-model="documento"
            accept=".pdf"
            placeholder="Nenhum arquivo selecionado"
            browse-text="Selecione o documento"
            class="col-6"
          ></b-file>
          <form-error
            v-if="validationErrors.anexo"
            :error="['É obrigatório anexar um documento do tipo pdf']"
          />

          <br v-if="solicitacao.tipo_solicitacao_id == 3 && documento && descricao" />
          <b-button v-if="solicitacao.tipo_solicitacao_id == 3 && documento && descricao" data-testid="button-visualizar-solicitacao-plano-trabalho" type="button" class="mt-4" variant="info" @click="togglePlanoTrabalho">
            <b-icon icon="plus"></b-icon>
            Cadastrar Plano de Trabalho Revisado
          </b-button>

          <br />
          <div class="d-flex flex-row items-center justify-content-end">
            <b-button v-if="solicitacao.tipo_solicitacao_id != 3" class="mt-5 mb-1" type="submit" variant="primary"
              >Enviar</b-button
            >
          </div>
        </b-form>
      </div>
    </div>

    <div v-else>
      <FormPlanoTrabalho :loading="loading" :processo="processo" :togglePlanoTrabalho="togglePlanoTrabalho" :plano_trabalho="plano_trabalho" :handleSubmit="handleSubmit" />
    </div>
  </div>
</template>
<script>
import FormError from "@/components/FormError";
import FormPlanoTrabalho from "@/components/PlanoTrabalho/FormPlanoTrabalho";

export default {
  components: { FormError, FormPlanoTrabalho },
  data() {
    return {
      descricao: "",
      documento: null,
      loading: false,
      openPlanoTrabalho: false,
      plano_trabalho: {
        orgao: ''
      },
      validationErrors: {
        descricao: null,
        anexo: null,
      },
    };
  },
  methods: {
    togglePlanoTrabalho(){
      this.openPlanoTrabalho = !this.openPlanoTrabalho;
    },
    createplanoTrabalho(form){
      const metas_etapas = form.itens.map((meta, index) => ({
        indice: `${index + 1}`,
        ...meta,
        subitens: meta.subitens.map((subitem, subindex) => ({ indice: `${index + 1}.${subindex + 1}`, ...subitem }))
      }))

      const cronograma_desembolso = form.cronograma.map((parcela) => ({
        ...parcela,
        itens: parcela.itens.map((item, index) => ({
          indice: `${index + 1}`,
          ...item,
          subitens: item.subitens.map((subitem, subindex) => ({ indice: `${index + 1}.${subindex + 1}`, ...subitem }))
        }))
      }))

      const formData = {
        orgao_convenente: form.cadastrais_proponente.orgao,
        esfera_administrativa_convenente: form.cadastrais_proponente.esfera,
        cnpj_convenente: form.cadastrais_proponente.cnpj,
        cep_convenente: form.cadastrais_proponente.cep,
        bairro_convenente: form.cadastrais_proponente.bairro,
        cidade_convenente: form.cadastrais_proponente.cidade,
        estado_convenente: form.cadastrais_proponente.estado,
        numero_convenente: form.cadastrais_proponente.numero,
        rua_av_convenente: form.cadastrais_proponente.endereco,
        telefone_convenente: form.cadastrais_proponente.telefone,
        email_convenente: form.cadastrais_proponente.email,
        titular_convenente: form.cadastrais_proponente.nome_completo_titular_responsavel,
        cargo_convenente: form.cadastrais_proponente.cargo,
        decreto_convenente: form.cadastrais_proponente.decreto,
        cpf_convenente: form.cadastrais_proponente.cpf,
        rg_convenente: form.cadastrais_proponente.rg,
        cep_titular_convenente: form.cadastrais_proponente.cep_responsavel,
        bairro_titular_convenente: form.cadastrais_proponente.bairro_responsavel,
        cidade_titular_convenente: form.cadastrais_proponente.cidade_responsavel,
        estado_titular_convenente: form.cadastrais_proponente.estado_responsavel,
        numero_titular_convenente: form.cadastrais_proponente.numero_responsavel,
        rua_av_titular_convenente: form.cadastrais_proponente.endereco_responsavel,
        email_titular_convenente: form.cadastrais_proponente.email_institucional,

        banco: form.bancarios.banco,
        agencia: form.bancarios.agencia,
        conta_corrente: form.bancarios.conta_corrente,
        praca_pagamento: form.bancarios.praca_pagamento,

        orgao_concedente: form.cadastrais_concedente.orgao,
        esfera_administrativa_concedente: form.cadastrais_concedente.esfera,
        cnpj_concedente: form.cadastrais_concedente.cnpj,
        cep_concedente: form.cadastrais_concedente.cep,
        bairro_concedente: form.cadastrais_concedente.bairro,
        cidade_concedente: form.cadastrais_proponente.cidade,
        estado_concedente: form.cadastrais_proponente.estado,
        numero_concedente: form.cadastrais_concedente.numero,
        rua_av_concedente: form.cadastrais_concedente.endereco,
        telefone_concedente: form.cadastrais_concedente.telefone,
        email_concedente: form.cadastrais_concedente.email,
        titular_concedente: form.cadastrais_concedente.nome_completo_titular_responsavel,
        cargo_concedente: form.cadastrais_concedente.cargo,
        decreto_concedente: form.cadastrais_concedente.decreto,
        cpf_concedente: form.cadastrais_concedente.cpf,
        rg_concedente: form.cadastrais_concedente.rg,
        cep_titular_concedente: form.cadastrais_concedente.cep_responsavel,
        bairro_titular_concedente: form.cadastrais_concedente.bairro_responsavel,
        cidade_titular_concedente: form.cadastrais_proponente.cidade_responsavel,
        estado_titular_concedente: form.cadastrais_proponente.estado_responsavel,
        numero_titular_concedente: form.cadastrais_concedente.numero_responsavel,
        rua_av_titular_concedente: form.cadastrais_concedente.endereco_responsavel,
        email_titular_concedente: form.cadastrais_concedente.email_institucional,

        objeto_convenio: form.projeto.objeto_convenio,
        identificacao_objeto: form.projeto.identificao_objeto,
        execucao_inicio: form.projeto.periodo_inicial,
        execucao_termino: form.projeto.periodo_final,
        justificativa: form.projeto.justificativa,

        metas_etapas: {
          total: this.$helper.getValorTotalItens(metas_etapas),
          bdi: this.$helper.getValorTotalBDI(metas_etapas),
          total_geral: this.$helper.getValorTotalItensComBDI(metas_etapas),
          itens: metas_etapas
        },
        plano_aplicacao: {
          total: this.$helper.totalGeral(form.planoDeAplicacao),
          itens: form.planoDeAplicacao
        },
        cronograma_desembolso: {
          total: this.$helper.totalGeral(cronograma_desembolso),
          total_convenente: this.$helper.totalConvenente(cronograma_desembolso),
          total_concedente: this.$helper.totalConcedente(cronograma_desembolso),
          itens: cronograma_desembolso
        },
        processo_id: this.processo_id
      }

      this.$http
        .post("/planos-de-trabalho", formData, {
          headers: {
            authorization: localStorage.getItem("token")
          },
        })
        .then(() => {
          this.loading = false;
          this.validationErrors = null;
          this.atualizarProcesso();
          this.$swal({
            title: "Plano de trabalho enviado com sucesso!",
            icon: "success",
          });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          this.$swal({
            title: error?.response?.data?.message || "Erro ao enviar plano de trabalho!",
            icon: "error",
          });
        });
    },
    handleSubmit(form) {
      this.loading = true;

      let formData = new FormData();
      formData.append("solicitacao_id", this.solicitacao.id);
      formData.append("anexo", this.documento);
      formData.append("descricao", this.descricao);
      formData.append("processo_id", this.processo_id);

      this.$http
        .post("/respostas", formData, {
          headers: {
            authorization: localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.validationErrors = null;

          if(form?.type == 'submit'){
            this.loading = false;
            this.atualizarProcesso();
            this.$swal({
              title: "Resposta enviada com sucesso!",
              icon: "success",
            });
          }else{
            this.createplanoTrabalho(form)
          }
        })
        .catch((error) => {
          this.loading = false;
          this.validationErrors = error.response.data
            ? error.response.data
            : null;
          console.log(error);
        });
    },
    atualizarProcesso() {
      this.$emit("done");
    },
  },
  props: {
    solicitacao: {
      type: Object,
    },
    processo_id: {
      type: Number,
    },
    processo: {
      type: Object
    }
  },
};
</script>
