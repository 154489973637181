<template>
  <div v-if="processo">
    <!-- <b-modal
      v-if="exibir === processo.id"
      id="visualizar"
      :no-close-on-backdrop="true"
      scrollable
      size="xl"
      title="Detalhes do Processo"
      hide-footer
      @cancel="close()"
      @close="close()"
    > -->

      <!-- <div class="mt-8 mb-8">
        <b-button variant="info" @click="() => {
          this.$router.push('/processos/minha-unidade')
        }">
          <b-icon icon="arrow-left"></b-icon>
          Voltar
        </b-button>
      </div> -->

      <h3 class="font-weight-bold mb-4">
        <router-link to="/processos/minha-unidade">
          <b-icon
            icon="arrow-left"
            style="cursor: pointer; color: #003e5d"
          />
        </router-link>
        Detalhes do Processo
      </h3>

      <div
        v-if="exibir === processo.id"
        id="visualizar"
      >
        <b-tabs content-class="mt-3" pills card>
          <b-tab
            v-if="$helper.verificaPermissao('visualizar_dados_processo')"
            class="px-2"
          >
            <template #title>
              <b-icon icon="columns-gap" class="mr-2" /> <span>Dados</span>
            </template>
            <DadosProcesso
              :prefeitura="perfil === 'prefeitura'"
              :processo="processo"
              :sdc="perfil === 'sdc'"
              @processo="atualizaProcesso"
              @fechaModal="close"
              @refresh="refreshProcessos"
            />
          </b-tab>
          <!-- <b-tab v-if="$helper.verificaPermissao('visualizar_checklist')">
            <template #title>
              <b-icon icon="list-check" class="mr-2" /> <span>Checklist</span>
            </template>
            <ChecklistComponente
              :prefeitura="perfil === 'prefeitura'"
              :processo="processo"
              :sdc="perfil === 'sdc'"
            />
          </b-tab> -->
          <div
            v-if="$helper.verificaPermissao('solicitar_documentacao_convenio')"
          >
            <b-tab v-if="perfil === 'prefeitura'">
              <template #title>
                <b-icon data-testid="button-visualizar-solicitacao-processo" icon="info-square" class="mr-2" />
                <span>Solicitações</span>
              </template>
              <Solicitacoes :processo="processo" @atualiza="atualizaProcesso" />
            </b-tab>
          </div>

          <div
            v-if="getStatusPlanoTrabalho()"
          >
            <b-tab v-if="perfil === 'prefeitura'">
              <template #title>
                <b-icon data-testid="button-visualizar-solicitacao-processo" icon="file-earmark-pdf" class="mr-2" />
                <span>Plano de Trabalho</span>
              </template>
              <PlanoTrabalho :isSDC="perfil == 'sdc'" :processo="processo" @atualiza="atualizaProcesso" />
            </b-tab>
          </div>

          <div v-if="perfil === 'sdc'">
            <b-tab
              v-if="$helper.verificaPermissao('visualizar_compartilhamento')"
            >
              <template #title>
                <b-icon icon="person-plus" class="mr-2" />
                <span>Compartilhamento</span>
              </template>
              <Compartilhamento
                :processo="processo"
                @processo="refreshProcessos"
              />
            </b-tab>
            <b-tab v-if="$helper.verificaPermissao('visualizar_historico')">
              <template #title>
                <b-icon icon="clock-history" class="mr-2" />
                <span>Histórico</span>
              </template>
              <Historico :processo="processo" />
            </b-tab>
            <b-tab v-if="$helper.verificaPermissao('visualizar_tarefas')">
              <template #title>
                <b-icon icon="list-task" class="mr-2" />
                <span>Tarefas</span>
              </template>
              <Tarefas :processo="processo" @processo="atualizaProcesso" />
            </b-tab>
            <b-tab v-if="$helper.verificaPermissao('visualizar_paginador')">
              <template #title>
                <b-icon icon="file-earmark-break" class="mr-2" />
                <span>Paginador</span>
              </template>
              <Paginador :processo="processo" />
            </b-tab>
          </div>
        </b-tabs>
      </div>
    <!-- </b-modal> -->
  </div>
</template>

<script>
import DadosProcesso from '@/components/tabs/DadosProcesso.vue';
import Solicitacoes from '@/components/tabs/Solicitacoes';
import PlanoTrabalho from '@/components/tabs/PlanoTrabalho';
import Compartilhamento from '@/components/tabs/Compartilhamento';
import Historico from '@/components/tabs/Historico';
import Tarefas from '@/components/tabs/Tarefas';
import Paginador from '@/components/tabs/Paginador';

export default {
  components: {
    Compartilhamento,
    DadosProcesso,
    Solicitacoes,
    PlanoTrabalho,
    Historico,
    Tarefas,
    Paginador,
  },
  name: 'ModalProcesso',
  /* props: {
    processo: Object,
    exibir: Number,
    perfil: String,
    teste: String,
  }, */
  data() {
    return {
      processo: null,
      exibir: 0,
      perfil: '',
      teste: ''
    }
  },
  emits: ['fechaModal', 'processo', 'refresh'],
  methods: {
    close() {
      this.$emit('fechaModal', this.exibir);
    },
    getStatusPlanoTrabalho(){
      return this.processo.planos_de_trabalho.length > 0;
    },
    atualizaProcesso() {
      this.$emit('processo', this.processo);

      this.$http
        .get(`processos/${this.processo.id}`)
        .then(({ data }) => {
          this.processo = {...data, planos_de_trabalho: data.planos_de_trabalho.sort((a,b) => Date.parse(a.created_at) - Date.parse(b.created_at))};

          localStorage.setItem('ModalProcessoData', JSON.stringify({
            exibir: this.exibir,
            perfil: this.perfil,
            processo: {...data, planos_de_trabalho: data.planos_de_trabalho.sort((a,b) => Date.parse(a.created_at) - Date.parse(b.created_at))}
          }));

          this.$emit('processo', {...data, planos_de_trabalho: data.planos_de_trabalho.sort((a,b) => Date.parse(a.created_at) - Date.parse(b.created_at))});
        })
        .catch(error => {
          console.log(error);
        });
    },
    refreshProcessos() {
      this.$emit('refresh');

      this.$http
        .get(`processos/${this.processo.id}`)
        .then(({ data }) => {
          this.processo = {...data, planos_de_trabalho: data.planos_de_trabalho.sort((a,b) => Date.parse(a.created_at) - Date.parse(b.created_at))};

          localStorage.setItem('ModalProcessoData', JSON.stringify({
            exibir: this.exibir,
            perfil: this.perfil,
            processo: {...data, planos_de_trabalho: data.planos_de_trabalho.sort((a,b) => Date.parse(a.created_at) - Date.parse(b.created_at))}
          }));

          this.$emit('processo', {...data, planos_de_trabalho: data.planos_de_trabalho.sort((a,b) => Date.parse(a.created_at) - Date.parse(b.created_at))});
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
  mounted(){
    if(this.$route.params.exibir && this.$route.params.perfil && this.$route.params.processo){
      this.exibir = this.$route.params.exibir;
      this.perfil = this.$route.params.perfil;
      this.processo = this.$route.params.processo;

      localStorage.setItem('ModalProcessoData', JSON.stringify({
        exibir: this.$route.params.exibir,
        perfil: this.$route.params.perfil,
        processo: this.$route.params.processo
      }));
    } else {
      this.exibir = JSON.parse(localStorage.getItem('ModalProcessoData')).exibir
      this.perfil = JSON.parse(localStorage.getItem('ModalProcessoData')).perfil
      this.processo = JSON.parse(localStorage.getItem('ModalProcessoData')).processo

      this.atualizaProcesso()
    }
  }
};
</script>

<style scoped></style>
