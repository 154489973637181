<template>
  <b-container
    fluid
    class="p-4 border h-100"
    v-if="
      $helper.verificaPermissao('visualizar_processos_inativos') ||
      $helper.verificaPermissao('visualizar_inativos')
    "
  >
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h4>Processos Inativos</h4>
    </div>
    <ReadonlyTable :items="items" @openModal="openModal" />
    <h5 v-if="items.length === 0 && !loading">Sem processos inativos até o momento!</h5>
    <footer class="d-flex justify-content-end align-items-center">
      <b-pagination
        v-if="paginator.total"
        v-model="filtros.page"
        :total-rows="paginator.total"
        :per-page="paginator.perPage"
        first-number
        last-number
        @input="(filtros.page = $event), getInativos()"
        class="w-auto"
      />
    </footer>
    <!-- <ModalReadonly
      @close-readonly="clearModalData"
      modal-id="inativos-modal"
      :processId="modalProcessId"
    /> -->
  </b-container>
  <h1 v-else>Você não tem permissões!</h1>
</template>

<script>
const ReadonlyTable = () => import('@/components/readonly/ReadonlyTable.vue');
// const ModalReadonly = () => import('@/components/readonly/ModalReadonly.vue');
export default {
  data() {
    return {
      loading: true,
      paginator: {
        perPage: null,
        total: null,
      },
      filtros: {
        limit: 10,
        page: 1,
      },
      items: [],
      modalProcessId: '',
    };
  },
  methods: {
    openModal(item) {
      this.modalProcessId = String(item.id);
      this.$bvModal.show('inativos-modal');

      this.$router.push({
        name: 'ModalReadonly',
        params: {
          processId: this.modalProcessId,
          modalId: "inativos-modal"
      }});
    },
    clearModalData() {
      this.modalProcessId = '';
    },
    spreadUnidadesCompartilhadas(unidades) {
      return unidades.map(unidade => unidade.user.name).join(', ');
    },
    async getInativos() {
      try {
        this.loading = true;
        const params = { ...this.filtros };
        const { data } = await this.$http.get('/processos/inativos', {
          params: {
            ...params,
            ...this.filtros
          },
        });
        const aux = data.inativos.data.map(elem => {
          return {
            id: elem.id,
            protocolo: elem.protocolo,
            titulo: elem.titulo,
            prefeitura: elem.prefeitura.nome,
            unidades_compartilhadas: this.spreadUnidadesCompartilhadas(
              elem.compartilhamento
            ),
            status: elem.status,
          };
        });

        this.items = aux
        this.paginator.perPage = data.per_page;
        this.paginator.total = data.total;

        this.loading = false;

        this.$store.commit('addInativos', aux)
      } catch (error) {
        console.error(error);
      }
    },
    async reloadInativos() {
      try {
        const params = { ...this.filtros };
        const { data } = await this.$http.get('/processos/inativos', {
          params: {
            ...params,
            ...this.filtros
          },
        });
        const aux = data.inativos.data.map(elem => {
          return {
            id: elem.id,
            protocolo: elem.protocolo,
            titulo: elem.titulo,
            prefeitura: elem.prefeitura.nome,
            unidades_compartilhadas: this.spreadUnidadesCompartilhadas(
              elem.compartilhamento
            ),
            status: elem.status,
          };
        });

        this.items = aux
        this.paginator.perPage = data.per_page;
        this.paginator.total = data.total;

        this.$store.commit('addInativos', aux)
      } catch (error) {
        console.error(error);
      }
    },
  },
  components: {
    ReadonlyTable,
    // ModalReadonly
  },
  mounted() {
    this.items = this.$store.state.inativos;

    if(this.$store.state.inativos.length == 0){
      this.getInativos();
    }else{
      this.reloadInativos();
    }
  },
};
</script>
