<template>
  <div class="vh-100 vw-100">
    <b-row class="justify-content-center h-100">
      <div class="col-12 col-lg-6 d-flex position-relative">
        <form class="m-auto text-center w-75">
          <h3 class="font-weight-bold">Bem vindo de volta!</h3>
          <span class="font-italic">Por favor, insira suas credenciais</span>
          <div class="font-weight-bolder text-left mt-5">
            <b-form-group id="input-group-1" label="Email" label-for="email">
              <b-form-input
                id="email"
                placeholder="Digite seu email..."
                v-model="email"
                required
                type="text"
                v-on:keyup.enter="login"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="font-weight-bold text-left">
            <b-form-group id="input-group-1" label="Senha" label-for="password">
              <b-form-input
                id="password"
                placeholder="Digite sua senha..."
                v-model="password"
                required
                type="password"
                v-on:keyup.enter="login"
              ></b-form-input>
            </b-form-group>
          </div>

          <p
            class="text-left text-primary"
            style="text-decoration: underline; cursor: pointer"
          >
          <router-link to="/esqueci-senha">
            Esqueci minha senha
          </router-link>
          </p>

          <p
            class="text-left text-primary"
            style="text-decoration: underline; cursor: pointer"
          >
            <router-link to="/verificar-assinatura"
              >Validar Assinatura</router-link
            >
          </p>
          <b-button
            class="w-100 mt-5 font-weight-bold"
            variant="info"
            type="button"
            :disabled="isLoading"
            @click="login"
          >
            ENTRAR
          </b-button>
        </form>
        <figure
          class="position-absolute d-flex align-items-center"
          style="left: 2em; top: 1em"
        >
          <img
            src="@/assets/logo_no_bg.png"
            alt="Logo SDC"
            style="height: 52px"
          />
          <figcaption class="ml-3">
            <h4 class="font-weight-bolder">SDC</h4>
            <span>Sistema de Gestão de Convênios</span>
          </figcaption>
        </figure>
      </div>
      <div class="col-6 d-none d-lg-flex position-relative bg-info">
        <img src="@/assets/logo_no_bg.png" alt="Logo SDC" class="h-50 m-auto" />
        <div class="position-absolute" style="right: 2em; top: 1em">
          <img src="@/assets/brazao 1.svg" alt="Brazão Amapá" height="52px" />
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      isLoading: false,
    };
  },
  methods: {
    async login() {
      this.isLoading = true;
      try {
        const res = await this.$http.post('auth/login', {
          email: this.email,
          password: this.password,
        });
        localStorage.setItem('token', res.data.access_token);
        await this.me();
      } catch (error) {
        this.$swal({
          title: 'Erro ao realizar login!',
          text: 'Verifique suas credenciais de acesso',
          icon: 'error',
        });
      } finally {
        this.isLoading = false;
      }
    },
    async me() {
      try {
        const response = await this.$http.post('auth/me', {});
        localStorage.setItem('user_id', response.data.id);
        localStorage.setItem('user', JSON.stringify(response.data));
        localStorage.setItem('perfil_id', response.data.perfil_id);
        localStorage.setItem('user_name', response.data.name ?? '');
        localStorage.setItem(
          'prefeitura_id',
          response.data.prefeitura_id ?? ''
        );
        localStorage.setItem('setor_id', response.data.setor_id);

        if (JSON.parse(localStorage.getItem('user')).perfil.grupo_perfil_id == '2') {
          this.$router.push('/processos/minha-unidade');
        } else if (JSON.parse(localStorage.getItem('user')).perfil.grupo_perfil_id == '1') {
          this.$router.push('/dashboard');
        } else {
          this.$router.push('/dashboard');
        }
      } catch (error) {
        console.log(error.response);
      }
    },
  },
};
</script>
