<template>
  <b-container fluid>
    <b-row class="mt-4">
      <div class="col-12">
        <h3 class="font-weight-bold">
          Processo Número: {{ processo.protocolo }}
        </h3>
      </div>
    </b-row>
    <b-row>
      <div class="col-12 col-xl-4 mt-3">
        <b-row>
          <div class="d-flex justify-content-between col-12">
            <b-button
              size="sm"
              variant="primary"
              @click="exportarTodos()"
              :disabled="isLoading"
              >Exportar Todos
            </b-button>
            <b-button
              size="sm"
              variant="primary"
              @click="enviarExportacao()"
              :disabled="isLoading"
              >Exportar Marcados
            </b-button>
          </div>
        </b-row>
        <b-row>
          <div class="col-12 mt-3">
            <div class="p-4 border">

              <div class="d-flex" v-if="processo.tem_capa">
                <b-form-checkbox
                  v-model="paginadorCheckbox"
                  :value="processo.capa.nome_armazenado"
                  inline
                />
                <p
                  @click.self="paginador(processo.capa)"
                  class="text-primary"
                  style="cursor: pointer; text-decoration: underline"
                >
                  {{ processo.capa.tipo_anexo.descricao }}
                </p>
              </div>

              <div
                class="d-flex"
                v-for="checklist in processo.checklist"
                :key="checklist.id + 'a'"
              >
                <b-form-checkbox
                  v-model="paginadorCheckbox"
                  :value="checklist.nome_armazenado"
                  inline
                />
                <p
                  @click="paginador(checklist)"
                  class="text-primary"
                  style="cursor: pointer"
                >
                  {{ checklist.item_check_list.descricao }}
                </p>
              </div>

              <div
                class="d-flex"
                v-for="documento in processo.documentos_redigidos"
                :key="documento.id + 'a'"
              >
                <b-form-checkbox
                  v-model="paginadorCheckbox"
                  :value="documento.nome_armazenado"
                  inline
                />
                <p
                  @click="paginador(documento)"
                  class="text-primary"
                  style="cursor: pointer"
                >
                  {{ documento.tipo_anexo.descricao }} -
                  {{ documento.nome_original }}
                </p>
              </div>

              <div
                class="d-flex"
                v-for="resposta in processo.respostas_solicitacao"
                :key="resposta.id + 'a'"
              >
                <b-form-checkbox
                  v-model="paginadorCheckbox"
                  :value="resposta.nome_armazenado"
                  inline
                />
                <p
                  @click="paginador(resposta)"
                  class="text-primary"
                  style="cursor: pointer"
                >
                  {{ resposta.tipo_anexo.descricao }} -
                  {{ resposta.nome_original }}
                </p>
              </div>

              <div class="d-flex" v-if="processo.plano_trabalho_aprovado">
                <b-form-checkbox
                  v-model="paginadorCheckbox"
                  :value="extractNamePlano(processo.plano_trabalho_aprovado)"
                  inline
                />
                <p
                  @click.self="paginador(processo.plano_trabalho_aprovado)"
                  class="text-primary"
                  style="cursor: pointer;"
                >
                  Plano de Trabalho
                </p>
              </div>
            </div>
          </div>
        </b-row>
      </div>
      <div class="col-12 col-xl-8 overflow-auto">
        <iframe :src="arquivoPaginador"
                width="100%" height="671px" style="border: none;">
        </iframe>
      </div>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      paginadorCheckbox: [],
      arquivoPaginador: '',
      isLoading: false,
    };
  },
  methods: {
    extractNamePlano(plano){
      return String(plano.caminho).split('/').pop()
    },
    exportarTodos() {
      const capa = this.processo.capa?.nome_armazenado || null;
      const extractNames = array =>
        (array || []).map(item => item.nome_armazenado);
      const checklist = extractNames(this.processo.checklist);
      const documentos_redigidos = extractNames(
        this.processo.documentos_redigidos
      );
      const respostas_solicitacao = extractNames(
        this.processo.respostas_solicitacao
      );

      const plano_trabalho = this.processo.plano_trabalho_aprovado ? this.extractNamePlano(this.processo.plano_trabalho_aprovado) : null

      const anexos = [
        capa,
        ...checklist,
        ...documentos_redigidos,
        ...respostas_solicitacao,
        plano_trabalho
      ];

      const data = {
        processo_id: this.processo.id,
        exportacao: anexos,
      };

      this.isLoading = true;
      this.$http
        .post('exportacao', data, {})
        .then(response => {
          const successMessage =
            'Todos os documentos foram exportados com sucesso!';
          this.$swal({ title: successMessage, icon: 'success' });
          window.open(response.data.caminho, '_blank');
          this.isLoading = false;
        })
        .catch(error => {
          const errorMessage =
            error.response.data.erro === 'No PDFs to merge.'
              ? 'Nenhum anexo selecionado'
              : error.response.data.erro;
          this.$swal({ title: errorMessage, icon: 'error' });
          this.isLoading = false;
        });

      this.limpar();
    },
    paginador(anexo) {
      this.arquivoPaginador = `${anexo.caminho}#toolbar=1`;
    },
    enviarExportacao() {
      if (this.processo.tem_capa) {
        const capaIndex = this.paginadorCheckbox.indexOf(
          this.processo.capa.nome_armazenado
        );

        // Element found in the array
        this.paginadorCheckbox.splice(capaIndex, 1); // Remove the element from its current position
        this.paginadorCheckbox.unshift(this.processo.capa.nome_armazenado); // Add it to the beginning of the array
      }

      console.log(this.paginadorCheckbox);

      let data = {
        processo_id: this.processo.id,
        exportacao: this.paginadorCheckbox,
      };
      if (this.paginadorCheckbox.length > 0) {
        this.isLoading = true;
        this.$http
          .post('exportacao', data, {})
          .then(({ data }) => {
            this.$swal({
              title: 'Documentos Exportados Com Sucesso!',
              icon: 'success',
            });

            window.open(data.caminho, '_blank');
            this.paginadorCheckbox = [];
            this.isLoading = false;
          })
          .catch(error => {
            let erro = error.response.data;
            if (
              erro.exportacao?.includes('O campo exportacao é obrigatório.')
            ) {
              erro = 'Nenhum anexo selecionado';
            }
            this.$swal({
              title: `${erro}`,
              icon: 'error',
            });
            this.isLoading = false;
          });
      } else {
        this.$swal({
          title: 'Nenhum anexo selecionado',
          icon: 'error',
        });
      }
      this.limpar();
    },
    limpar() {
      this.paginadorCheckbox = [];
    },
  },
  props: {
    processo: {
      type: Object,
    },
  },
};
</script>
