<template>
  <!-- <b-modal
    lazy
    :id="modalId"
    title="Detalhes do Processo"
    ok-only
    size="xl"
    scrollable
    @shown="fetchProcesso"
    @hide="clear"
  > -->
  <div :id="modalId" v-if="processId">
    <h3 class="font-weight-bold mb-4">
      <router-link :to="modalId == 'inativos-modal' ? '/processos/inativos' : modalId == 'busca-modal' ? '/processos/busca' : '/arquivados'">
        <b-icon
          icon="arrow-left"
          style="cursor: pointer; color: #003e5d"
        />
      </router-link>
      Detalhes do Processo
    </h3>

    <b-tabs content-class="mt-3" pills card>
      <b-tab class="px-2">
        <template #title>
          <b-icon icon="columns-gap" class="mr-2" /> <span>Dados</span>
        </template>
        <div v-if="isLoading" class="d-flex justify-content-center w-100 py-5">
          <b-spinner variant="primary"></b-spinner>
        </div>
        <div style="color: #003e5d" v-else>
          <b-row class="mt-2">
            <h3 class="col-12">Informações</h3>
          </b-row>
          <b-row class="mt-4">
            <h5 class="col-12">Título</h5>
            <p class="col-12">{{ process.titulo }}</p>
          </b-row>
          <b-row class="mt-3">
            <b-col lg="6">
              <h5>Status:</h5>
              <p>{{ process.status }}</p>
            </b-col>
            <b-col lg="6">
              <h5>Protocolo:</h5>
              <p>
                {{
                  process.protocolo ??
                  'Nenhum número de protocolo foi encontrado'
                }}
              </p>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col lg="6">
              <h5>Período de execução:</h5>

              <p class="my-3">
                <b-icon icon="calendar-date" class="mr-2" />{{
                  process.execucao_inicio ?? 'Sem data de ínicio'
                }}
              </p>
              <p>
                <b-icon icon="calendar-date" class="mr-2" />{{
                  process.execucao_fim ?? 'Sem data final'
                }}
              </p>
            </b-col>
            <b-col lg="6">
              <h5>Período de vigência:</h5>
              <p class="my-3">
                <b-icon icon="calendar-date" class="mr-2" />{{
                  process.vigencia_inicio ?? 'Sem data de ínicio'
                }}
              </p>
              <p>
                <b-icon icon="calendar-date" class="mr-2" />{{
                  process.vigencia_fim ?? 'Sem data final'
                }}
              </p>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col lg="6">
              <h5>Valor do Termo do Convênio</h5>
              <p>{{ process.valor_convenio ?? 'Nenhum valor encontrado' }}</p>
            </b-col>
            <b-col lg="6">
              <h5>Valor do Processo Licitatório</h5>
              <p>
                {{
                  process.valor_processo_licitatorio ??
                  'Nenhum valor encontrado'
                }}
              </p>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <h3 class="mb-4 col-12">Detalhes</h3>
            <b-col lg="12" v-if="fiscais.length > 0" class="mb-4">
              <b-row>
                <div
                  v-for="fiscal in fiscais"
                  :key="fiscal.title"
                  class="col-4"
                >
                  <h5>{{ fiscal.title }}:</h5>
                  <p>{{ fiscal.name }}</p>
                </div>
              </b-row>
            </b-col>
            <b-col lg="12">
              <p class="font-weight-bolder">
                Descrição:
                <div v-html="format(process.descricao)" class="font-weight-normal text-break text-justify"></div>
              </p>
            </b-col>
            <b-col lg="6">
              <p class="font-weight-bolder">
                Prefeitura:
                <span class="font-weight-normal">{{ process.prefeitura }}</span>
              </p>
            </b-col>
            <b-col lg="6">
              <p class="font-weight-bolder">
                Eixo:
                <span class="font-weight-normal">{{ process.eixo }}</span>
              </p>
            </b-col>
            <b-col lg="6">
              <p class="font-weight-bolder">
                SubEixo:
                <span class="font-weight-normal">{{ process.subeixo }}</span>
              </p>
            </b-col>
          </b-row>
        </div>
      </b-tab>
      <b-tab class="px-2">
        <template #title>
          <b-icon icon="clock-history" class="mr-2" />
          <span>Histórico</span>
        </template>
        <div style="color: #003e5d">
          <Historico :processo="process" />
        </div>
      </b-tab>
      <b-tab class="px-2">
        <template #title>
          <b-icon icon="file-earmark-break" class="mr-2" />
          <span>Paginador</span>
        </template>
        <div style="color: #003e5d">
          <Paginador :processo="process" />
        </div>
      </b-tab>
    </b-tabs>
  </div>
  <!-- </b-modal> -->
</template>

<script>
const Paginador = () => import('@/components/tabs/Paginador.vue');
const Historico = () => import('@/components/tabs/Historico.vue');
export default {
  data() {
    return {
      fiscais: [{ name: '', title: '' }],
      historic: [
        {
          data: '',
          setor: '',
          usuario: '',
          historico: '',
        },
      ],
      isLoading: true,
      process: {},

      modalId: '',
      processId: 0
    };
  },
  /* props: {
    modalId: {
      type: String,
      required: true,
    },
    processId: {
      type: String,
      required: true,
    },
  }, */
  methods: {
    format(text){
      return text.replace(/\n/g, '<br>')
    },
    setFiscais(payload) {
      this.fiscais = payload.map((fiscal) => {
        return {
          name: fiscal.name,
          title: fiscal.relacao,
        };
      });
    },
    setProcess(payload) {
      this.process = {
        ...payload,
        prefeitura: payload.prefeitura.nome,
        subeixo: payload?.subeixo?.descricao,
        eixo: payload.eixo.descricao,
      };
    },
    setHistoric(payload) {
      this.historic = payload.map((historic) => {
        return {
          data: historic.created_at,
          setor: historic.setor.nome,
          usuario: historic.user.name,
          historico: historic.descricao,
        };
      });
    },
    async fetchProcesso(id = null) {
      if (!this.isLoading) {
        this.isLoading = true;
      }

      if(!id){
        return null;
      }

      try {
        const { data } = await this.$http.get(`/processos/${id || this.processId}`);
        this.setProcess(data);
        this.setFiscais(data.fiscais);
        this.setHistoric(data.historico);
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    clear() {
      this.process = {};
      this.$emit('close-readonly');
    },
  },
  components: {
    Paginador,
    Historico,
  },
  mounted(){
    if(this.$route.params.modalId && this.$route.params.processId){
      this.modalId = this.$route.params.modalId
      this.processId = this.$route.params.processId

      localStorage.setItem('ModalReadonlyData', JSON.stringify({
        modalId: this.$route.params.modalId,
        processId: this.$route.params.processId
      }));

      this.fetchProcesso(this.$route.params.processId)
    } else {
      this.modalId = JSON.parse(localStorage.getItem('ModalReadonlyData')).modalId
      this.processId = JSON.parse(localStorage.getItem('ModalReadonlyData')).processId

      this.fetchProcesso(JSON.parse(localStorage.getItem('ModalReadonlyData')).processId)
    }
  }
};
</script>

<style scoped>
h5,
h3 {
  font-weight: bold;
}
</style>
