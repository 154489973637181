<template>
  <section>
    <section style="overflow-x: auto">
      <table class="table table-bordered" style="min-width: 100%">
        <thead>
          <tr style="background-color: #e5e7eb; color: #374151">
            <th scope="col" class="text-center" style="color: #1f2937">
              Índice
            </th>
            <th scope="col" class="text-center" style="color: #1f2937">Nome</th>
            <th scope="col" class="text-center" style="color: #1f2937">Criado em</th>
            <th scope="col" class="text-center" style="color: #1f2937">PDF</th>
            <th scope="col" class="text-center" style="color: #1f2937">
              Situação
            </th>
            <th
              scope="col"
              class="text-center"
              style="color: #1f2937"
              v-if="isSDC"
            >
              Ações
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in processo.planos_de_trabalho" :key="index">
            <td class="text-center align-middle" :style="item.is_aprovado == false && 'text-decoration: line-through;'">{{ index + 1 }}</td>
            <td class="text-center align-middle" :style="item.is_aprovado == false && 'text-decoration: line-through;'">
              {{ `Plano de trabalho ${index + 1}` }}
            </td>
            <td class="text-center align-middle" :style="item.is_aprovado == false && 'text-decoration: line-through;'">
              {{ formatDate(item.created_at) }}
            </td>
            <td
              class="text-center align-middle"
              style="height: 16px; width: 128px"
            >
              <div
                v-if="item.is_aprovado != false"
                v-b-tooltip.hover
                title="Visualizar plano de trabalho">
                <b-icon
                  @click="processo?.plano_trabalho_aprovado ? redirectPDF(processo?.plano_trabalho_aprovado?.caminho) : item?.anexo_temporario?.caminho && redirectPDF(item?.anexo_temporario?.caminho)"
                  icon="file-pdf"
                  style="cursor: pointer; width: 32px; height: 32px"
                ></b-icon>
              </div>

              <div v-else>-</div>
            </td>
            <td class="text-center align-middle" :style="item.is_aprovado == false && 'text-decoration: line-through;'" style="font-weight: 600">{{ item.is_aprovado == true && item.is_assinado_coordenador && item.is_assinado_secretario ? 'Assinado pelos concedentes' : item.is_aprovado == true ? 'Aguardando assinaturas dos concedentes' : item.is_aprovado == false ? 'Recusado' : item.is_assinado_prefeito == null ? 'Aguardando assinatura convenente' : 'Assinado pelo convenente' }}</td>
            <td
              class="text-center align-middle"
              style="width: 95px"
              v-if="isSDC && item.is_aprovado == null && item.is_assinado_prefeito"
            >
              <div
                class="d-flex flex-row items-center justify-content-end"
                style="gap: 1rem"
              >
                <b-button
                  :disabled="loading"
                  @click="validarPlano(item)"
                  type="button"
                  style="background-color: #1b7e9f"
                  >Validar</b-button
                >
                <b-button
                  :disabled="loading"
                  @click="recusarPlano(item)"
                  type="button"
                  style="background-color: #1b7e9f"
                  >Recusar</b-button
                >
              </div>
            </td>
            <td
              v-else-if="isSDC && item.is_aprovado == false"
              class="text-center align-middle">
              -
            </td>
            <td class="text-center align-middle" v-if="isSDC && !isAndamentoAssinaturaSDC(item) && cargos.length > 1 && $helper.verificaPermissao('adicionar_signatario_plano_trabalho') && item.is_aprovado == true && (!item.is_assinado_coordenador || !item.is_assinado_secretario)">
              <b-button
                @click="() => abrirModal(item)"
                type="button"
                style="background-color: #1b7e9f"
                >Adicionar Signatário</b-button
              >
            </td>
            <td v-if="isSDC && item.is_aprovado == null && !item.is_assinado_prefeito" class="text-center align-middle">-</td>
            <td v-if="isSDC && cargos.length > 1 && isAndamentoAssinaturaSDC(item) && !$helper.verificaPermissao('adicionar_signatario_plano_trabalho') && item.is_aprovado == true && !item.is_assinado_coordenador && !item.is_assinado_secretario" class="text-center align-middle">-</td>
            <td v-if="isSDC && isAndamentoAssinaturaSDC(item) && item.is_aprovado == true" class="text-center align-middle">-</td>
            <td v-if="isSDC && item.is_aprovado == true && item.is_assinado_coordenador && item.is_assinado_secretario" class="text-center align-middle">-</td>
            <td v-if="isSDC && cargos.length == 1 && !$helper.verificaPermissao('adicionar_signatario_plano_trabalho') && item.is_aprovado == true && !item.is_assinado_coordenador && !item.is_assinado_secretario" class="text-center align-middle">-</td>
          </tr>
        </tbody>
      </table>

      <b-modal
        id="modal-1"
        :title="'Adicionar Signatário'"
        :hide-footer="true"
        title-class="font-weight-bold"
        size="lg"
      >
        <b-form @submit.prevent="submit">
          <div class="row">
            <div class="col-12">
              <b-form-group
                label="Cargo" label-class="font-weight-bold"
              >
                <b-form-select
                  required
                  v-model="cargo"
                  :options="cargos"
                >
                </b-form-select>
              </b-form-group>
            </div>

            <div class="col-12">
              <b-form-group label="Nome" label-class="font-weight-bold">
                <b-form-input required type="text" v-model="nome" placeholder="Inserir nome do signatário">
                </b-form-input>
              </b-form-group>
            </div>

            <div class="col-12">
              <b-form-group label="E-mail" label-class="font-weight-bold">
                <b-form-input required type="email" v-model="email" placeholder="Inserir e-mail do signatário">
                </b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="d-flex justify-content-center mt-4 mb-4">
            <b-button type="button" style="background-color: #99BBC8; border: none; font-weight: 600; border-radius: 6px; padding: 12px 18px 12px 18px; width: 160px; height: 46px;" @click="cancelar" class="mr-4">Cancelar</b-button>
            <b-button :disabled="loading" type="submit" style="background-color: #1B7E9F; border: none; font-weight: 600; border-radius: 6px; padding: 12px 18px 12px 18px; width: 160px; height: 46px;">Enviar</b-button>
          </div>
        </b-form>
      </b-modal>
    </section>
  </section>
</template>

<script>
import moment from 'moment'
import { watch } from 'vue';

export default {
  components: {},
  data() {
    return {
      cargos: [{ text: 'Selecione', value: null }, 'Coordenador da CAIDL', 'Secretário de Estado do Desenvolvimento de Cidades'],
      cargo: null,
      email: '',
      nome: '',
      plano_trabalho: null,
      loading: false
    }
  },
  props: {
    plano_trabalho: { type: Object },
    processo: { type: Object },
    isSDC: { type: Boolean },
  },
  methods: {
    isAndamentoAssinaturaSDC(item){
      return item.solicitacoes_assinatura.filter(item => item.assinante != 'Prefeito' && item.is_assinado == false).length > 0
    },
    clear(){
      this.cargo = null;
      this.email = '';
      this.nome = '';
    },
    abrirModal(item) {
      this.clear()
      this.plano_trabalho = item
      this.$bvModal.show('modal-1');
    },
    cancelar(){
      this.clear();
      this.plano_trabalho = null
      this.$bvModal.hide('modal-1');
    },
    submit(){
      const formData = new FormData()
      formData.append('plano_de_trabalho_id', this.plano_trabalho.id)
      formData.append('assinante', this.cargo == 'Coordenador da CAIDL' ? 'Coordenador' : 'Secretario')
      formData.append('email', this.email)
      formData.append('nome', this.nome)

      this.loading = true

      this.$http
        .post("/solicitar-assinatura-plano", formData, {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        })
        .then(() => {
          this.cancelar();
          this.refreshProcesso()
          this.$swal({
            title: "Solicitação enviada com sucesso!",
            icon: "success",
          });

          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error);
          this.$swal({
            title:
              error?.response?.data?.message ||
              'Erro ao enviar solicitação de assinatura!',
            icon: 'error',
          });
        });
    },
    formatDate(value){
      return moment(value).format('DD/MM/YYYY HH:mm')
    },
    refreshProcesso() {
      this.$emit('refreshProcesso');
    },
    redirectPDF(url) {
      window.open(url, '_blank');
    },
    validarPlano(item) {
      this.$swal({
        icon: 'warning',
        title: 'Tem certeza que deseja validar este plano?',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#3085d6',
        denyButtonText: 'Cancelar',
      }).then(result => {
        if (result.isConfirmed) {
          this.loading = true;

          this.$http.put(`/planos-de-trabalho/${item.id}`,
              { aprovado: true },
              { headers: { authorization: localStorage.getItem('token') } }
            )
            .then(() => {
              this.$swal({
                title: 'Plano de trabalho validado com sucesso!',
                icon: 'success',
              });

              this.loading = false;
              this.refreshProcesso()
            })
            .catch(error => {
              this.loading = false;
              console.log(error);
              this.$swal({
                title:
                  error?.response?.data?.message ||
                  'Erro ao validar plano de trabalho!',
                icon: 'error',
              });
            })
          }
      });
    },
    recusarPlano(item) {
      this.$swal({
        icon: 'warning',
        title: 'Tem certeza que deseja recusar este plano?',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#3085d6',
        denyButtonText: 'Cancelar',
      }).then(result => {
        if (result.isConfirmed) {
          this.loading = true;

          this.$http.put(`/planos-de-trabalho/${item.id}`,
              { aprovado: false },
              { headers: { authorization: localStorage.getItem('token') } }
            )
            .then(() => {
              this.$swal({
                title: 'Plano de trabalho recusado com sucesso!',
                icon: 'success',
              });

              this.loading = false;
              this.refreshProcesso()
            })
            .catch(error => {
              this.loading = false;
              console.log(error);
              this.$swal({
                title:
                  error?.response?.data?.message ||
                  'Erro ao validar plano de trabalho!',
                icon: 'error',
              });
            })
          }
      });
    },
  },
  mounted() {
    watch(() => this.plano_trabalho, () => {
      if(this.plano_trabalho){
        if(this.plano_trabalho.is_assinado_coordenador && this.plano_trabalho.is_assinado_secretario){
          this.cargos = [{ text: 'Selecione', value: null }]
        } else if(this.plano_trabalho.is_assinado_coordenador && !this.plano_trabalho.is_assinado_secretario){
          this.cargos = [{ text: 'Selecione', value: null }, 'Secretário de Estado do Desenvolvimento de Cidades']
        }else if(!this.plano_trabalho.is_assinado_coordenador && this.plano_trabalho.is_assinado_secretario){
          this.cargos = [{ text: 'Selecione', value: null }, 'Coordenador da CAIDL']
        }
      }
    })

    this.refreshProcesso()
  },
};
</script>
