<template>
  <div>
    <b-dropdown id="dropdown-1" text="COLUNAS" class="mb-3">
      <b-dropdown-form>
        <b-form-checkbox
          v-for="fieldOpt in filterableFields"
          v-model="fieldOpt.visible"
          :key="fieldOpt.key"
          >{{ fieldOpt.label }}</b-form-checkbox
        >
      </b-dropdown-form>
    </b-dropdown>

    <b-table
      responsive
      hover
      :items="filteredData"
      :fields="visibleFields"
      empty-text="Sem dados..."
      show-empty
      class="bootstrap-table"
    >
      <template #head(eixo)>
        <b-dropdown text="Eixos" variant="outline-dark">
          <b-dropdown-form>
            <b-form-checkbox-group
              :options="getUniqueFields('eixo')"
              v-model="selectedEixos"
              @input="filterData"
            />
          </b-dropdown-form>
        </b-dropdown>
      </template>
      <template #head(subeixo)>
        <b-dropdown text="Subeixos" variant="outline-dark">
          <b-dropdown-form>
            <b-form-checkbox-group
              :options="getUniqueFields('subeixo')"
              v-model="selectedSubeixos"
              @input="filterData"
            />
          </b-dropdown-form>
        </b-dropdown>
      </template>
      <template #head(municipio)>
        <b-dropdown text="Município" variant="outline-dark">
          <b-dropdown-form>
            <b-form-checkbox-group
              :options="getUniqueFields('municipio')"
              v-model="selectedMunicipios"
              @input="filterData"
            />
          </b-dropdown-form>
        </b-dropdown>
      </template>
    </b-table>
    <b-row v-if="items.length > 0">
      <b-col lg="12">
        <b-dropdown
          id="download-actions"
          text="Exportar"
          variant="primary"
          class="float-right mb-4"
        >
          <b-dropdown-item @click="exportToPdf">PDF</b-dropdown-item>
          <b-dropdown-item @click="exportToXlsx">EXCEL</b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
export default {
  data() {
    return {
      fields: [
        {
          key: 'protocolo',
          label: 'Protocolo',
          sortDirection: 'asc',
          visible: true,
          filterable: false,
        },
        {
          key: 'eixo',
          label: 'Eixo',
          visible: true,
          filterable: false,
        },
        {
          key: 'subeixo',
          label: 'Subeixo',
          visible: true,
          filterable: false,
        },
        {
          key: 'municipio',
          label: 'Município',
          visible: true,
          filterable: false,
        },
        {
          key: 'dataCriacao',
          label: 'Data de criação',
          visible: true,
          filterable: true,
        },
        {
          key: 'valorConvenio',
          label: 'Valor do Termo de Convênio',
          visible: true,
          filterable: true,
        },
        {
          key: 'valorLicitatorio',
          label: 'Valor do Processo Licitatório',
          visible: true,
          filterable: true,
        },
        {
          key: 'periodoExec',
          label: 'Período de Execução',
          visible: true,
          filterable: true,
        },
        {
          key: 'fiscais',
          label: 'Fiscais',
          visible: true,
          filterable: true,
        },
      ],
      selectedEixos: [],
      selectedSubeixos: [],
      selectedMunicipios: [],
      filteredData: [],
    };
  },
  methods: {
    exportToPdf() {
      const doc = new jsPDF();
      const columns = this.visibleFields.map(elem => {
        return {
          header: elem.label,
          dataKey: elem.key,
        };
      });
      autoTable(doc, {
        columns,
        body: this.filteredData,
      });
      doc.save('relatorio.pdf');
    },
    exportToXlsx() {
      const headers = this.fields.map(elem => elem.label);
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(worksheet, [headers]);
      XLSX.utils.sheet_add_json(worksheet, this.filteredData, {
        origin: 'A2',
        skipHeader: true,
      });
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, 'data.xlsx');
    },
    getUniqueFields(property) {
      const object = this.items.map(elem => elem[property]);
      const uniqueValues = [...new Set(object)];

      return uniqueValues;
    },
    filterData() {
      this.filteredData = this.items.filter(item => {
        return (
          this.selectedEixos.includes(item.eixo) &&
          this.selectedSubeixos.includes(item.subeixo) &&
          this.selectedMunicipios.includes(item.municipio)
        );
      });
    },
  },
  computed: {
    visibleFields() {
      return this.fields.filter(field => field.visible);
    },
    filterableFields() {
      return this.fields.filter(field => field.filterable);
    },
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  //Propriedades precisam de um watcher para saberem quando plotar os dados
  watch: {
    items: {
      handler() {
        this.filteredData = this.items;
        this.selectedEixos = this.getUniqueFields('eixo');
        this.selectedSubeixos = this.getUniqueFields('subeixo');
        this.selectedMunicipios = this.getUniqueFields('municipio');
      },
      deep: true,
    },
  },
  mounted() {
    this.filteredData = this.items;
    this.selectedEixos = this.getUniqueFields('eixo');
    this.selectedSubeixos = this.getUniqueFields('subeixo');
    this.selectedMunicipios = this.getUniqueFields('municipio');
  },
};
</script>
<style>
.bootstrap-table {
  min-height: 400px;
}
</style>
