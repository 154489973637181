<template>
  <b-container fluid style="color: #003e5d">
    <b-tabs pills align="center">
      <b-tab title="Documentações" v-if="$helper.verificaPermissao('visualizar_documentacoes')">
        <b-row class="mt-4">
          <h4 class="col-12 font-weight-bold mb-4">Adicionar documentos</h4>
          <div class="col-12 d-flex">
            <b-form-file
              multiple
              v-model="anexos"
              placeholder="Nenhum documento selecionado"
              browse-text="Procurar"
              ref="anexoInput"
              accept=".pdf"
            />
            <div class="d-flex float-right">
              <b-button
                id="anexarDocumento"
                class="ml-3"
                variant="primary"
                @click="confirmBoxOnSave()"
              >
                Adicionar
              </b-button>
            </div>
          </div>
        </b-row>
        <b-row>
          <div class="col-12">
            <div
              class="mt-3"
              v-for="(anexo, index) in anexos"
              :key="`${index}-${anexo.name}`"
            >
              <div class="d-flex align-items-center">
                <span
                  @click.prevent="showPdfInline(anexo)"
                  style="cursor: pointer"
                  class="text-primary"
                  ><u>{{ anexo.name }}</u></span
                >
                <b-icon
                  icon="trash-fill"
                  style="cursor: pointer"
                  @click.prevent="removeAnexo(anexo.name, index)"
                  class="ml-4"
                />
              </div>
            </div>
          </div>
          <b-embed
            type="iframe"
            :src="pdfData"
            v-if="pdfInView.show"
            class="mt-4 col-12"
          />
        </b-row>
        <b-row>
          <h4 class="col-12 mt-5 mb-3 font-weight-bold">Documentos</h4>
          <div class="col-12 ml-3 mt-1 table-responsive">
            <table class="table table-striped table-hover">
              <tr style="color: #003e5d">
                <th>Tipo</th>
                <th>Documento</th>
              </tr>
              <tr
                v-for="documento in processo.documentos_redigidos"
                :key="documento.id"
              >
                <td>{{ documento.tipo_anexo.descricao }}</td>
                <td>
                  <a :href="documento.caminho" target="_blank">
                    {{ documento.nome_original }}
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </b-row>
      </b-tab>
      <b-tab title="Solicitações" v-if="$helper.verificaPermissao('visualizar_solicitacoes')">
        <b-row class="mt-4">
          <div class="col-12">
            <h4 class="typo__label font-weight-bold mb-4">
              Selecione o Tipo de Solicitação
            </h4>
            <Solicitacao
              :processo="processo"
              :anexos_ids="anexos_ids"
              :prefeitura_id="processo.prefeitura_id"
              :processo_id="processo.id"
              @refreshProcesso="refreshProcesso"
            />
          </div>
          <div class="col-12">
            <div v-for="anexo in processo.documentos_redigidos" :key="anexo.id">
              <div class="mt-3 col-12">
                <b-form-checkbox @change="handleCheckbox($event, anexo)">
                  <p>
                    {{ anexo.tipo_anexo.descricao }} -
                    {{ anexo.nome_original }}
                  </p>
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </b-row>
      </b-tab>
      <b-tab title="Redigir Documentos" v-if="$helper.verificaPermissao('visualizar_redigir_documentos')">
        <b-row class="mt-4">
          <RedigirDocumentos
            :processo="processo"
            @refreshProcesso="refreshProcesso"
          />
        </b-row>
      </b-tab>
      <b-tab
        title="Vincular Fiscais"
        v-if="$helper.verificaPermissao('visualizar_vincular_fiscais')"
      >
        <b-row class="mt-4">
          <b-col lg="12">
            <h4 class="font-weight-bold mb-4">Vincular fiscais ao processo</h4>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col
            lg="12"
            v-for="(fiscal, index) in fiscais"
            :key="index"
            class="my-2"
          >
            <h5>{{ fiscal.title }}</h5>

            <Multiselect
              track-by="value"
              :disabled="isLoading"
              close-on-select
              @remove="deleteFiscal"
              @select="postFiscal($event, fiscal.title)"
              label="name"
              placeholder="Selecione um fiscal para o processo..."
              selectLabel="Pressione enter ou clique para selecionar"
              deselectLabel="Pressione enter ou clique para desselecionar"
              v-model="fiscal.selected"
              :options="fiscal.options"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab
        title="Plano de Trabalho"
        v-if="$helper.verificaPermissao('validar_recusar_plano_trabalho')"
      >
        <b-row class="mt-4">
          <b-col lg="12">
            <h4 class="font-weight-bold mb-4">Planos de Trabalho</h4>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col lg="12">
            <TablePlanoTrabalho :isSDC="true" :processo="processo" @refreshProcesso="refreshProcesso"/>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </b-container>
</template>
<script>
import RedigirDocumentos from '@/components/tabs/redigir-documentos/RedigirDocumentos';
import Solicitacao from '@/components/Solicitacao';
import TablePlanoTrabalho from '@/components/TablePlanoTrabalho';

import Multiselect from 'vue-multiselect';

export default {
  components: {
    RedigirDocumentos,
    Solicitacao,
    Multiselect,
    TablePlanoTrabalho
  },
  props: {
    processo: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      fiscais: [
        {
          title: 'Fiscal Administrativo',
          selected: '',
          options: [],
        },
        {
          title: 'Gestor de Convênio',
          selected: '',
          options: [],
        },
        {
          title: 'Fiscal Técnico',
          selected: '',
          options: [],
        },
      ],
      anexos: [],
      anexos_ids: [],
      pdfData: null,
      pdfInView: {
        show: false,
        name: '',
      },
    };
  },
  emits: ['processo'],
  methods: {
    confirmBoxOnSave() {
      this.$bvModal
        .msgBoxConfirm(
          'Após adição do documento, o mesmo não poderá ser alterado posteriormente. Deseja continuar?',
          {
            title: 'Adicionar documento',
            size: 'md',
            buttonSize: 'md',
            okVariant: 'danger',
            okTitle: 'Sim',
            cancelTitle: 'Não',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(value => {
          if (!value) return;
          this.anexarDocumento();
        });
    },
    handleCheckbox(event, anexo) {
      if (event) {
        this.anexos_ids.push(anexo.id);
      } else {
        this.anexos_ids.splice(this.anexos_ids.indexOf(anexo.id), 1);
      }
    },
    showPdfInline(anexo) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        this.pdfData = reader.result;
      });

      reader.readAsDataURL(anexo);
      if (this.pdfInView.show && this.pdfInView.name === anexo.name) {
        this.pdfInView = { show: false, name: '' };
      } else {
        this.pdfInView = { show: true, name: anexo.name };
      }
    },
    removeAnexo(anexoName, index) {
      this.anexos.splice(index, 1);
      if (this.pdfInView.name === anexoName) {
        this.pdfInView = { show: false, name: '' };
      }
    },
    async postFiscal(event, relationship) {
      this.isLoading = true;
      try {
        const payload = {
          user_id: event.value,
          relacao: relationship,
          processo_id: this.processo.id,
        };
        await this.$http.post('fiscais', payload);
        this.$swal({
          title: `${relationship} vinculado com sucesso!`,
          icon: 'success',
        });
        this.refreshProcesso();
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.$swal({
          title: 'Erro!',
          text: `Fiscal já vinculado ao processo!`,
          icon: 'error',
        });

        const fiscalIndex = this.fiscais.findIndex(
          fiscal => fiscal.title === relationship
        );
        this.fiscais[fiscalIndex].selected = '';

        this.isLoading = false;
      }
    },
    async deleteFiscal(event) {
      this.isLoading = true;
      try {
        await this.$http.delete(
          `fiscais?processo_id=${this.processo.id}&user_id=${event.value}`
        );
        this.$swal({
          title: `${event.name} desvinculado com sucesso!`,
          icon: 'success',
        });
        this.refreshProcesso();
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.$swal({
          title: 'Erro!',
          text: `Erro ao desatribuir fiscal ao processo!`,
          icon: 'error',
        });
        this.isLoading = false;
      }
    },
    async fetchFiscais(param) {
      try {
        const response = await this.$http.get(`fiscais?${param}=true`);
        return response;
      } catch (error) {
        this.$swal({
          title: 'Erro!',
          text: 'Erro ao carregar fiscais! Tente novamente!',
          icon: 'error',
        });
      }
    },
    anexarDocumento() {
      if (this.anexos.length === 0) {
        this.$swal({
          title: 'Erro!',
          text: 'Selecione um arquivo PDF para adicionar',
          icon: 'error',
        });

        return;
      }

      let btn = document.getElementById('anexarDocumento');
      btn.disabled = true;

      this.anexos.forEach(anexo => {
        let formData = new FormData();
        formData.append('processo_id', this.processo.id);
        formData.append('documentos[0]', anexo);

        this.$http
          .post(`anexos`, formData)
          .then(() => {
            this.$swal({
              title: 'Documento adicionado com sucesso!',
              icon: 'success',
            });

            this.$refs['anexoInput'].reset();

            this.refreshProcesso();
          })
          .catch(error => {
            this.$swal({ title: 'Erro!', icon: 'error' });
            console.log(error);
          });
      });

      this.pdfData = null;
      this.showPdf = false;
      if (this.anexos.length > 0) {
        this.anexos = [];
      }
      btn.disabled = false;
    },
    verificarSetoresCompartilhamento() {
      const setoresCompartilhados = this.processo.compartilhamento.map(
        compartilhamento => compartilhamento.setor
      );

      let setorEstaCompartilhado = {};

      this.setoresSemCompartilhamento = this.setores.filter(setor => {
        setorEstaCompartilhado = setoresCompartilhados.find(
          setorCompartilhado => setorCompartilhado.id === setor.id
        );

        return !setorEstaCompartilhado;
      });
    },
    refreshProcesso() {
      this.$emit('processo', this.processo);
    },
  },
  mounted() {
    Promise.all([
      this.fetchFiscais('fiscal_administrativo'),
      this.fetchFiscais('gestor_convenio'),
      this.fetchFiscais('fiscal_tecnico'),
    ])
      .then(res => {
        for (let i = 0; i < 3; i++) {
          this.fiscais[i].options = res[i].data[0].map(fiscais => {
            return {
              name: fiscais.name,
              value: fiscais.id,
            };
          });
        }
      })
      .then(() => {
        this.fiscais = this.fiscais.map(fiscal => {
          const processoFiscal = this.processo.fiscais.find(
            pf => pf.relacao === fiscal.title
          );

          return {
            ...fiscal,
            selected: processoFiscal
              ? { name: processoFiscal.name, value: processoFiscal.id }
              : fiscal.selected,
          };
        });
      });
  },
};
</script>
