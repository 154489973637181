<template>
  <div class="bg-light">
    <Header class="sticky-top" />
    <b-container fluid class="bg-white container-main" style="margin-top: 10px !important;">
      <b-row class="bg-white">
        <b-col lg="2" style="padding-left: 16px !important" class="pr-0">
          <Sidebar />
        </b-col>
        <b-col lg="10" style="padding: 0px 16px !important">
          <slot />
        </b-col>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import Header from '@/layouts/Header';
import Sidebar from '@/layouts/Sidebar';
import Footer from '@/layouts/Footer';
export default {
  name: 'layout-dashboard',
  components: {
    Header,
    Sidebar,
    Footer,
  },
  props: {
    titulo: String,
  },
};
</script>

<style scoped>
.container-main {
  min-height: calc(100vh - 3em);
  padding-top: 86px !important;
  margin-top: 24px !important;
  padding-bottom: 32px !important;
}
</style>
