<template>
  <main>
    <b-container fluid class="p-4 border">
      <b-row class="mb-4">
        <b-col class="md-6">
          <h4>Assinaturas</h4>
        </b-col>
      </b-row>

      <table class="table table-striped table-hover table-sm">
        <thead>
          <tr>
            <th>
              <b-form-input
                v-model="filtros.tipo"
                class="form-rounded"
                placeholder="Tipo de Documento"
                size="sm"
                @input="debouncedPesquisar()"
                style="border-radius: 7px"
              />
            </th>
            <th>
              <b-form-input
                v-model="filtros.protocolo"
                class="form-rounded"
                placeholder="Número Processo"
                size="sm"
                @input="debouncedPesquisar()"
                style="border-radius: 7px"
              />
            </th>
            <th>
              <b-form-input
                v-model="filtros.name"
                class="form-rounded"
                placeholder="Signatários"
                size="sm"
                @input="debouncedPesquisar()"
                style="border-radius: 7px"
              />
            </th>
            <th>
              <b-form-select
                :options="['Resultados', '10', '20', '30', 50]"
                v-model="filtros.limit"
                @input="debouncedPesquisar()"
              ></b-form-select>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="sig in signatarios.data" :key="sig.id">
            <a
              v-b-modal.modal-2
              @click="visualizarDocumento(sig)"
              v-b-tooltip.hover
              title="Visualizar Documento"
              class="ml-3"
              >{{ sig.anexo.nome_original }}</a
            >
            <td>
              <a v-b-modal.modal-paginador>{{
                sig.anexo.processo.protocolo
              }}</a>
            </td>
            <div v-for="s in sig.anexo.signatarios" :key="s.id">
              <td>{{ s.name }}</td>
            </div>
            <td row="col">
              <button
                v-b-tooltip.hover
                class="btn btn-success btn-sm"
                title="Assinar Documento"
                @click="assinarDocumento(sig.anexo.id)"
              >
                <b-icon icon="pen"></b-icon>
              </button>
              <b-button
                v-b-tooltip.hover
                v-b-modal.modal-recusa
                class="btn btn-danger btn-sm ml-2"
                @click="recusaDocumento(sig.anexo.id, sig)"
              >
                <b-icon icon="x-circle"></b-icon>
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
      <footer class="d-flex justify-content-end align-items-center">
        <b-pagination
          v-if="signatarios.total"
          v-model="filtros.page"
          :total-rows="signatarios.total"
          :per-page="signatarios.per_page"
          first-number
          last-number
          @input="(filtros.page = $event), getSignatarios()"
          class="mx-auto"
        ></b-pagination>
      </footer>
      <div>
        <b-modal
          id="modal-recusa"
          v-if="signatario.processo"
          title="Despacho"
          size="lg"
          :hide-footer="true"
          title-class="font-weight-bold"
        >
          <b-form>
            <b-form-group
              label="Selecione o(s) Servidor(es):"
              required
              label-class="font-weight-bolder"
            >
              <multiselect
                v-model="destinatarios"
                :close-on-select="true"
                :multiple="true"
                :options="servidores"
                :searchable="true"
                :show-labels="false"
                placeholder="Selecione..."
              ></multiselect>
            </b-form-group>
            <b-form-group
              id="input-group-1"
              label="Processo:"
              label-for="referente"
              label-class="font-weight-bolder"
            >
              <b-form-input
                id="referente"
                disabled
                type="text"
                v-bind:value="signatario.processo.protocolo"
              ></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-1" label-class="font-weight-bolder">
              <vue-editor
                v-model="mensagem"
                :editorToolbar="editorToolbar"
              ></vue-editor>
            </b-form-group>
            <div class="float-right">
              <b-button variant="primary" @click="redigirDocumento()"
                >Salvar
              </b-button>
            </div>
          </b-form>
        </b-modal>
      </div>
      <div>
        <b-modal
          id="modal-2"
          size="lg"
          title="Documento de Processo"
          :hide-footer="true"
        >
          <embed
            :src="documento"
            height="800"
            type="application/pdf"
            width="700px"
          />
        </b-modal>
      </div>
      <div>
        <b-modal
          id="modal-paginador"
          title="Documentos do Processo"
          size="xl"
          v-for="sig in signatarios.data"
          :key="sig.id"
        >
          <div class="p-4 border">
            <div>
              <a
                v-for="s in sig.anexo.processo.checklist"
                :key="s.id"
                :href="s.caminho"
                target="_blank"
                class="d-flex"
                >{{ s.item_check_list.descricao }}</a
              >
            </div>
            <div>
              <a
                v-for="s in sig.anexo.processo.documentos_redigidos"
                :key="s.id"
                :href="s.caminho"
                target="_blank"
                class="mt-2 d-flex"
                >{{ s.nome_original }}</a
              >
            </div>
          </div>
        </b-modal>
      </div>
    </b-container>
  </main>
</template>

<script>
import Multiselect from 'vue-multiselect';
var _ = require('lodash');

export default {
  components: {
    Multiselect,
  },
  props: {
    search: String,
  },
  data() {
    return {
      editorToolbar: [
        ['bold', 'italic', 'underline'],
        [{ color: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: 'center' }, { align: 'justify' }, { align: 'right' }],
      ],
      mensagem: '',
      signatario: {},
      signatarios: {
        total: 0,
      },
      servidores: [],
      destinatarios: [],
      documento: '',
      filtros: {
        tipo: '',
        protocolo: '',
        name: '',
        limit: 10,
        page: 1,
      },
      arquivoPaginador: '',
    };
  },
  methods: {
    getSignatarios() {
      this.signatarios = {};

      this.$http
        .get('signatarios', { params: this.filtros })
        .then(res => {
          this.signatarios = res.data;
          //  let tipo = signatarios.nome_original;
          //  tipo = tipo.substr(0, tipo.indexOf("-"));
          //  signatarios.tipo = tipo;
        })
        .catch(error => {
          console.log(error.response);
        });
    },
    getCleanSignatarios() {
      this.$http
        .get('signatarios', { params: this.filtros })
        .then(res => {
          this.signatarios = res.data;
          //  let tipo = signatarios.nome_original;
          //  tipo = tipo.substr(0, tipo.indexOf("-"));
          //  signatarios.tipo = tipo;
        })
        .catch(error => {
          console.log(error.response);
        });
    },
    assinarDocumento(anexo_id) {
      this.$swal({
        icon: 'warning',
        title:
          'Deseja realmente assinar esse documento?',
        // text: 'Esta ação não poderá ser desfeita!',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#3085d6',
        denyButtonText: 'Cancelar',
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$http
              .post('user-assina/' + anexo_id, {})
              .then(() => {
                this.filtros.user_id = localStorage.getItem('user_id');
                this.getCleanSignatarios(this.filtros);
              })
              .catch(error => {
                console.log(error.response);
              });
            this.$swal({
              title: 'Documento assinado\ncom sucesso!',
              icon: 'success',
            });
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    recusaDocumento(anexo_id, signatario) {
      this.$swal({
        icon: 'warning',
        title:
          'Deseja realmente recusar a assinatura desse documento?',
        // text: 'Esta ação não poderá ser desfeita!',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#3085d6',
        denyButtonText: 'Cancelar',
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.signatario = signatario;
            this.$http.post('user-nega/' + anexo_id, {}).then(() => {
                this.filtros.user_id = localStorage.getItem('user_id');
                this.getCleanSignatarios(this.filtros);
                // this.getServidoresSDC();
                // this.debouncedPesquisar = _.debounce(this.getSignatarios, 300);
              }).catch(error => {
                console.log(error.response);
              }
            );
            this.$swal({
              title: 'Documento recusado com sucesso!',
              icon: 'success',
            });
            // this.$swal({title: 'Documento Rejeitado', icon:"success"});
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    getServidoresSDC() {
      this.$http
        .get('servidores-sdc')
        .then(res => {
          res.data.forEach(servidor => {
            this.servidores.push(`${servidor.name} - ${servidor.setor.nome}`);
          });
        })
        .catch(error => {
          console.log(error.response);
        });
    },
    redigirDocumento() {
      let data = {
        mensagem: this.mensagem,
        tipo_documentos_id: 1, //Despacho
        processo: this.signatario.processo,
        numero_processo: this.signatario.processo.protocolo,
        destinatarios: this.destinatarios,
        processo_id: this.signatario.processo.id,
        assinatura_automatica: true,
      };

      this.$http.post('documentos-redigidos', data).catch(error => {
        console.log(error.response);
      });

      this.$swal({ title: 'Documento redigido com sucesso!', icon: 'success' });
      this.limpar();
    },
    limpar() {
      this.mensagem = '';
      this.destinatarios = [];
    },
    visualizarDocumento(anexo) {
      this.documento = `${anexo.anexo.caminho}#toolbar=1`;
    },
  },
  created() {
    this.filtros.user_id = localStorage.getItem('user_id');
    this.getSignatarios(this.filtros);
    this.getServidoresSDC();
    this.debouncedPesquisar = _.debounce(this.getSignatarios, 300);
  },
};
</script>

<style scoped>
td,
th {
  vertical-align: middle !important;
  padding: 10px 15px !important;
}
</style>
