<template>
  <main>
    <b-container
      fluid
      class="p-4 border mt-4"
      v-if="$helper.verificaPermissao('visualizar_submenu_prefeituras')"
    >
      <b-row class="mb-4">
        <b-col class="md-6">
          <h4 class="mb-0">Prefeituras</h4>
        </b-col>
      </b-row>
      <b-overlay :show="isLoading" rounded="sm">
        <div class="table-responsive">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Prefeituras</th>
                <th scope="col">Sigla</th>
                <th scope="col" class="col-2">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="prefeitura in prefeituras" :key="prefeitura.id">
                <td>{{ prefeitura.id }}</td>
                <td>{{ prefeitura.nome }}</td>
                <td>{{ prefeitura.sigla }}</td>
                <td>
                  <div class="d-flex align-items-center">
                    <button
                      @click="showEditar(prefeitura)"
                      v-b-modal.modal-1
                      class="btn btn-primary btn-sm"
                      v-b-tooltip.hover
                      title="Editar"
                    >
                      <b-icon icon="pencil"></b-icon>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>

      <b-modal
        id="modal-1"
        :title="titulo"
        :hide-footer="true"
        title-class="font-weight-bold"
      >
        <b-form>
          <div class="row">
            <div class="col-lg-6">
              <b-form-group
                label="Prefeitura*:"
                label-class="font-weight-bolder"
              >
                <b-form-input
                  v-model="nome"
                  required
                  placeholder="Digite o nome..."
                />
              </b-form-group>
            </div>
            <div class="col-sm-6">
              <b-form-group label="Sigla*:" label-class="font-weight-bolder">
                <b-form-input
                  v-model="sigla"
                  required
                  placeholder="Digite a sigla..."
                />
              </b-form-group>
            </div>
            <div class="col-sm-12">
              <b-form-group label="Nome Completo do Prefeito*:" label-class="font-weight-bolder">
                <b-form-input
                  v-model="prefeito"
                  required
                  placeholder="Digite nome completo do prefeito..."
                />
              </b-form-group>
            </div>
            <div class="col-sm-12">
              <b-form-group label="Nome Completo do Vice Prefeito*:" label-class="font-weight-bolder">
                <b-form-input
                  v-model="vice"
                  required
                  placeholder="Digite nome completo do vice prefeito..."
                />
              </b-form-group>
            </div>
            <div class="col-sm-12">
              <b-form-group label="Endereço*:" label-class="font-weight-bolder">
                <b-form-input
                  v-model="endereco"
                  required
                  placeholder="Digite o endereço..."
                />
              </b-form-group>
            </div>
            <div class="col-sm-6">
              <b-form-group label="Número*:" label-class="font-weight-bolder">
                <b-form-input
                  v-model="numero"
                  required
                  placeholder="Digite o número..."
                />
              </b-form-group>
            </div>
            <div class="col-sm-6">
              <b-form-group label="Bairro*:" label-class="font-weight-bolder">
                <b-form-input
                  v-model="bairro"
                  required
                  placeholder="Digite o bairro..."
                />
              </b-form-group>
            </div>
            <div class="col-sm-6">
              <b-form-group label="CEP*:" label-class="font-weight-bolder">
                <b-form-input
                  v-mask="'#####-###'"
                  maxlength="9"
                  v-model="cep"
                  required
                  placeholder="Digite o cep..."
                />
              </b-form-group>
            </div>
            <div class="col-sm-6">
              <b-form-group label="Telefone*:" label-class="font-weight-bolder">
                <b-form-input
                  v-mask="'(##) # ####-####'"
                  type="tel"
                  maxlength="16"
                  v-model="telefone"
                  required
                  placeholder="Digite o telefone..."
                />
              </b-form-group>
            </div>
            <div class="col-sm-6">
              <b-form-group label="E-mail*:" label-class="font-weight-bolder">
                <b-form-input
                  v-model="email"
                  type="email"
                  required
                  placeholder="Digite o e-mail..."
                />
              </b-form-group>
            </div>
            <div class="col-sm-6">
              <b-form-group label="Confirmar e-mail*:" label-class="font-weight-bolder">
                <b-form-input
                  v-model="confirmaEmail"
                  type="email"
                  required
                  placeholder="Confirmar o e-mail..."
                />
              </b-form-group>
            </div>
            <div class="col-12 mt-4">
              <b-button
                @click="editar ? editarPrefeitura() : addPrefeitura()"
                variant="primary"
                class="float-right"
                :disabled="isLoading"
                >{{ editar ? 'Editar' : 'Salvar' }}</b-button
              >
            </div>
          </div>
        </b-form>
      </b-modal>
    </b-container>
    <h1 v-else>
      Seu perfil não tem as devidas permissões para acessar este conteúdo
    </h1>
  </main>
</template>

<script>
export default {
  data() {
    return {
      prefeitura: '',
      isLoading: false,
      editar: false,
      titulo: '',
      nome: '',
      sigla: '',
      endereco: '',
      numero: '',
      bairro: '',
      cep: '',
      telefone: '',
      email: '',
      confirmaEmail: '',
      prefeito: '',
      vice: '',
      prefeituras: [],
    };
  },
  methods: {
    addPrefeitura() {
      this.editar = false;
      if(!this.isEmailEqual()) return;

      const data = {
        nome: this.nome,
        sigla: this.sigla,
        endereco: this.endereco,
        numero: this.numero,
        bairro: this.bairro,
        cep: this.cep,
        telefone: this.telefone,
        email: this.email,
        prefeito: this.prefeito,
        vice: this.vice
      };

      this.isLoading = true;
      this.$http
        .post('prefeituras', data)
        .then(res => {
          this.$swal({
            title: 'Prefeitura cadastrada com sucesso!',
            icon: 'success',
          });

          this.prefeituras.push(res.data);
          this.isLoading = false;
          this.$bvModal.hide('modal-1');
          this.clear();
        })
        .catch(error => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
    getPrefeituras() {
      this.isLoading = true;
      this.$http
        .get('prefeituras', {})
        .then(res => {
          this.prefeituras = res.data;
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
    showNovo() {
      this.titulo = 'Nova Prefeitura';
      this.editar = false;

      this.nome = '';
      this.sigla = '';
      this.endereco = '';
      this.numero = '';
      this.bairro = '';
      this.cep = '';
      this.telefone = '';
      this.email = '';
      this.confirmaEmail = '';
      this.prefeito = '';
      this.vice = '';
    },
    showEditar(prefeitura) {
      this.titulo = 'Editar Prefeitura';
      this.editar = true;

      this.prefeitura = prefeitura;
      this.nome = prefeitura.nome;
      this.sigla = prefeitura.sigla ?? '';
      this.endereco = prefeitura.endereco ?? '';
      this.numero = prefeitura.numero ?? '';
      this.bairro = prefeitura.bairro ?? '';
      this.cep = prefeitura.cep ?? '';
      this.telefone = prefeitura.telefone ?? '';
      this.email = prefeitura.email ?? '';
      this.confirmaEmail = '';
      this.prefeito = prefeitura.prefeito ?? '';
      this.vice = prefeitura.vice ?? '';
    },
    editarPrefeitura() {
      if(!this.isEmailEqual()) return;

      const data = {
        nome: this.nome,
        sigla: this.sigla,
        endereco: this.endereco,
        numero: this.numero,
        bairro: this.bairro,
        cep: this.cep,
        telefone: this.telefone,
        email: this.email,
        prefeito: this.prefeito,
        vice: this.vice
      };
      this.isLoading = true;
      this.$http
        .put(`prefeituras/${this.prefeitura.id}`, data, {})
        .then(res => {
          this.$swal({
            title: 'Prefeitura editada com sucesso!',
            icon: 'success',
          });
          this.prefeituras = this.prefeituras.map(elem =>
            elem.id === res.data.id ? res.data : elem
          );
          this.isLoading = false;
          this.clear();
          this.$bvModal.hide('modal-1');
        })
        .catch(error => {
          this.isLoading = false;
          const errors = error.response.data;
          let errorMessage = '';
          for (const key in errors) {
            errorMessage += `${errors[key][0]} \n`
            if (errorMessage.includes("uma string.")) {
                errorMessage = errorMessage.replace("uma string.", "preenchido.");
            }
          }
          this.$swal({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            title: errorMessage,
            icon: 'error',
          });
        });
    },
    excluirPrefeitura(prefeitura) {
      this.$swal({
        icon: 'warning',
        title: 'Tem certeza que deseja excluir esta prefeitura?',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#3085d6',
        denyButtonText: 'Cancelar',
      })
        .then(result => {
          this.isLoading = true;
          if (result.isConfirmed) {
            this.$http
              .delete(`prefeituras/${prefeitura.id}`, {})
              .then(() => {
                this.$swal('Prefeitura removida com sucesso!', '', 'success');
                this.prefeituras = this.prefeituras.filter(
                  elem => elem.id !== prefeitura.id
                );
                this.isLoading = false;
              })
              .catch(() => (this.isLoading = false));
          } else if (result.isDenied) {
            this.$swal('Uffa! Foi por pouco.', '', 'warning');
          }
        })
        .catch(error => {
          console.log(error.response);
        });
    },
    isEmailEqual () {
      if (this.confirmaEmail !== this.email) {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          title: 'Os e-mail são diferentes!',
          icon: 'error',
        });
        return false;
      };
      return true;
    },
    clear() {
      this.prefeitura = {};
      this.nome = '';
      this.sigla = '';
      this.endereco = '',
      this.numero = '',
      this.bairro = '',
      this.cep = '',
      this.telefone = '',
      this.email = '',
      this.confirmaEmail = '',
      this.prefeito = '',
      this.vice = ''
    },
  },
  created() {
    this.getPrefeituras();
  },
  beforeCreate() {
    //Check if profile is SDC, if not, redirect to home.
    if (JSON.parse(localStorage.getItem('user')).perfil.grupo_perfil_id != '1') {
      this.$router.push('/processos/minha-unidade');
    }
  },
};
</script>

<style scoped>
td,
th {
  vertical-align: middle !important;
}
</style>
