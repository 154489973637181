<template>
  <b-container fluid>
    <b-row class="mt-4">
      <b-col lg="12">
        <b-button variant="primary" @click="exportToPdf">Exportar PDF</b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="6">
        <apexchart
          height="400"
          ref="eixosChart"
          class="mt-5"
          :options="eixoOptions"
          :series="eixo.series"
        />
      </b-col>
      <b-col lg="6">
        <apexchart
          height="400"
          ref="prefeiturasChart"
          class="mt-5"
          :options="prefeituraOptions"
          :series="prefeitura.series"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6">
        <apexchart
          height="400"
          ref="licitatorioChart"
          class="mt-5"
          :options="licitatorioOptions"
          :series="licitatorio.series"
        />
      </b-col>
    </b-row>
    <b-row class="my-5">
      <b-col lg="12">
        <apexchart
          height="500"
          ref="subeixoChart"
          :options="subeixoOptions"
          :series="subeixo.series"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import jsPDF from 'jspdf';
export default {
  data() {
    return {
      pieOptions: {
        chart: {
          type: 'donut',
        },

        legend: {
          show: true,
          position: 'bottom',
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                },
              },
            },
          },
        },
      },
      eixo: {
        series: [],
        labels: [],
      },
      subeixo: {
        labels: [],
      },
      prefeitura: {
        series: [],
        labels: [],
      },
      licitatorio: {
        series: [],
        labels: [],
      },
    };
  },
  computed: {
    subeixoOptions() {
      return {
        subtitle: {
          text: 'Os eixos correspodentes ao subeixo estão separados por cores na legenda',
        },
        legend: {
          show: true,
          position: 'bottom',
        },
        dataLabels: {
          enabled: true,
          formatter: function (text, op) {
            return [text, op.value];
          },
        },
        title: {
          text: 'Subeixos',
        },
        chart: {
          type: 'treemap',
          toolbar: {
            show: false,
          },
        },
        responsive: [
          {
            breakpoint: 760,
            options: {
              legend: {
                show: true,
                offsetY: 0,
                position: 'top',
              },
            },
          },
        ],
      };
    },
    eixoOptions() {
      return {
        ...this.pieOptions,
        title: {
          text: 'Eixos',
        },

        labels: this.eixo.labels,
      };
    },
    licitatorioOptions() {
      return {
        chart: {
          type: 'donut',
        },
        title: {
          text: 'Termo Licitatório',
        },
        legend: {
          position: 'bottom',
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return value.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              });
            },
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true,
                  formatter: function (w) {
                    const sum = w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                    return sum.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    });
                  },
                },
              },
            },
          },
        },

        labels: this.licitatorio.labels,
      };
    },
    prefeituraOptions() {
      return {
        ...this.pieOptions,
        title: {
          text: 'Convênios por Município',
        },

        labels: this.prefeitura.labels,
      };
    },
  },
  props: {
    charts: {
      type: Array,
      required: true,
    },
  },
  //Propriedades precisam de um watcher para saberem quando plotar os dados
  watch: {
    charts: {
      handler() {
        this.plotAll();
      },
      deep: true,
    },
  },
  mounted() {
    this.plotAll();
  },
  methods: {
    async exportToPdf() {
      const pdf = new jsPDF('p', 'px', 'a4');

      const firstChart = this.$refs.subeixoChart;
      const secondChart = this.$refs.eixosChart;
      const thirdChart = this.$refs.licitatorioChart;
      const fourthChart = this.$refs.prefeiturasChart;

      const [
        { imgURI: imgURI1 },
        { imgURI: imgURI2 },
        { imgURI: imgURI3 },
        { imgURI: imgURI4 },
      ] = await Promise.all([
        firstChart.dataURI(),
        secondChart.dataURI(),
        thirdChart.dataURI(),
        fourthChart.dataURI(),
      ]);

      const pageWidth = pdf.internal.pageSize.getWidth();

      //Treemap
      pdf.addImage(imgURI1, 'PNG', 20, 20, pageWidth - 50, 200);

      //Pie Charts
      pdf.addImage(imgURI2, 'PNG', 10, 250, pageWidth / 3, 120);
      pdf.addImage(
        imgURI3,
        'PNG',
        pageWidth / 2 - pageWidth / 3 / 2,
        250,
        pageWidth / 3,
        120
      );
      pdf.addImage(
        imgURI4,
        'PNG',
        pageWidth - pageWidth / 3,
        250,
        pageWidth / 3,
        120
      );

      pdf.save('relatorio-graficos.pdf');
    },
    plotAll() {
      this.plotSubeixoChart(this.charts[2].data_sub_eixos);
      this.plotEixoChart({
        series: this.charts[2].data_eixos.eixos.data,
        labels: this.charts[2].data_eixos.eixos.labels,
      });
      this.plotLicitatorioChart({
        series: this.charts[0].data_processo_licitatorio.data,
        labels: this.charts[0].data_processo_licitatorio.labels,
      });
      this.plotPrefeituraChart({
        series: this.charts[1].data_prefeituras_processos.data,
        labels: this.charts[1].data_prefeituras_processos.labels,
      });
    },
    plotPrefeituraChart({ series, labels }) {
      this.prefeitura.series = series;
      this.prefeitura.labels = labels;
    },
    plotLicitatorioChart({ series, labels }) {
      this.licitatorio.series = series.map(Number);
      this.licitatorio.labels = labels;
    },
    plotEixoChart({ series, labels }) {
      this.eixo.series = series;
      this.eixo.labels = labels;
    },
    plotSubeixoChart(data) {
      this.subeixo.series = data;
    },
  },
};
</script>
