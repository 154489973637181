<template>
  <div class="vh-100 vw-100">
    <b-row class="justify-content-center h-100">
      <div class="col-12 col-lg-6 d-flex position-relative">
        <form class="m-auto text-center w-75">
          <div class="font-weight-bolder text-left mt-5">
            <b-form-group id="input-group-1" label="Código" label-for="codigo">
              <b-form-input
                id="codigo"
                placeholder="Digite o código do documento"
                v-model="codigo"
                required
                type="text"
                v-on:keydown.enter.prevent="verificarAssinatura"
              ></b-form-input>
            </b-form-group>
          </div>

          <p
            class="text-left text-primary"
            style="text-decoration: underline; cursor: pointer"
          >
            <router-link to="/login">Voltar</router-link>
          </p>

          <button
            class="btn w-100 mt-5 bg-info text-white font-weight-bold"
            type="button"
            @click="verificarAssinatura"
          >
            VALIDAR
          </button>
        </form>
        <figure
          class="position-absolute d-flex align-items-center"
          style="left: 2em; top: 1em"
        >
          <img
            src="@/assets/logo_no_bg.png"
            alt="Logo SDC"
            style="height: 52px"
          />
          <figcaption class="ml-3">
            <h4 class="font-weight-bolder">SDC</h4>
            <span>Sistema de Gestão de Convênios</span>
          </figcaption>
        </figure>
      </div>
      <div class="col-6 d-none d-lg-flex position-relative bg-info">
        <img src="@/assets/logo_no_bg.png" alt="Logo SDC" class="h-50 m-auto" />
        <div class="position-absolute" style="right: 2em; top: 1em">
          <img src="@/assets/brazao 1.svg" alt="Brazão Amapá" height="52px" />
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'VerificarAssinatura',
  data() {
    return {
      codigo: '',
    };
  },
  methods: {
    async verificarAssinatura() {
      if (this.codigo != '') {
        try {
          const { data } = await this.$http.get('verificar-assinatura', {
            params: { codigo: this.codigo },
          });

          this.$swal({
            title: 'Código válido!',
            text: data.message,
            icon: 'success',
          });
        } catch (error) {
          this.$swal({
            title: 'Código inválido!',
            text: 'Verifique o código informado',
            icon: 'error',
          });
          console.log(error.response);
        }
      } else {
        this.$swal({
          title: 'Código inválido!',
          text: 'Verifique o código informado',
          icon: 'error',
        });
      }
    },
  },
  async mounted() {
    if (this.$route.query.codigo) {
      this.codigo = this.$route.query.codigo;

      await this.verificarAssinatura();
    }

    this.codigo = '';
  },
};
</script>
