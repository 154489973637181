<template>
  <div class="mt-2">
    <p class="error mb-1" v-for="(e, key) in error" v-bind:key="key">{{ e }}</p>
  </div>
</template>

<script>
export default {
  props: {
    error: Array,
  },
};
</script>

<style scoped>
.error {
  color: red;
  font-size: 0.75rem; /* 12px */
  line-height: 1rem; /* 16px */
}
</style>
