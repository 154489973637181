<template>
  <div>
    <b-form v-if="tipo === 1" @submit.prevent="handleFormSubmitDiligencia">
      <b-form-textarea
        id="textarea-diligencia"
        v-model="form.textoSolicitacao"
        max-rows="6"
        placeholder="Solicitar diligência..."
        rows="3"
      ></b-form-textarea>
      <form-error
        v-if="validationErrors.descricao"
        :error="['Este campo é obrigatório']"
      />
      <br />
      <b-button type="submit" variant="primary">Solicitar Diligência</b-button>
    </b-form>
    <b-form v-if="tipo === 2" @submit.prevent="handleFormSubmitDocumentos">
      <b-form-textarea
        id="textarea-documentos"
        v-model="form.textoSolicitacao"
        max-rows="6"
        placeholder="Solicitar documento pendente..."
        rows="3"
      ></b-form-textarea>
      <form-error
        v-if="validationErrors.descricao"
        :error="['Este campo é obrigatório']"
      />
      <br />
      <b-button type="submit" variant="primary"
        >Solicitar Documentação Pendente
      </b-button>
    </b-form>
    <b-form v-if="tipo === 3" @submit.prevent="handleFormSubmitLicitatorios">
      <b-form-textarea
        id="textarea-documentos"
        v-model="form.textoSolicitacao"
        max-rows="6"
        placeholder="Solicitar Documentação do Processo Licitatório..."
        rows="3"
      ></b-form-textarea>
      <form-error
        v-if="validationErrors.descricao"
        :error="['Este campo é obrigatório']"
      />
      <br />
      <b-button type="submit" variant="primary" :disabled="loading">Solicitar Documentação do Processo Licitatório
      </b-button>
    </b-form>
  </div>

  <!-- <textarea
    name=""
    id=""
    cols="70"
    rows="5"
    v-model="textoSolicitacao"
  ></textarea> -->
</template>

<script>
import FormError from "@/components/FormError";

export default {
  components: { FormError },
  data() {
    return {
      form: {
        textoSolicitacao: "",
      },
      solicitacao: "",
      loading: false,
      prefeitura: localStorage.getItem("prefeitura_id"),
      validationErrors: {
        descricao: null,
      },
    };
  },
  methods: {
    handleFormSubmitDiligencia() {
      let data = {
        descricao: this.form.textoSolicitacao,
        prefeitura_id: this.prefeitura_id,
        processo_id: this.processo_id,
        tipo_solicitacao_id: 1,
        anexos: this.anexos_ids,
        respondida: 0,
      };

      var self = this;

      this.$http
        .post("solicitacoes", data)
        .then(() => {
          this.$swal({
            title: "Solicitação de diligência enviada com sucesso!",
            icon: "success",
          });

          self.$emit("refreshProcesso");
          this.form.textoSolicitacao = "";
          this.validationErrors.descricao = null;
        })
        .catch((error) => {
          this.validationErrors.descricao = error.response.data.descricao
            ? error.response.data.descricao
            : null;
        });
    },
    handleFormSubmitDocumentos() {
      let data = {
        descricao: this.form.textoSolicitacao,
        prefeitura_id: this.prefeitura_id,
        processo_id: this.processo_id,
        tipo_solicitacao_id: 2,
        anexos: this.anexos_ids,
      };

      var self = this;

      this.$http
        .post("solicitacoes", data)
        .then(() => {
          this.$swal({
            title: "Solicitação de documentação pendente enviada com sucesso!",
            icon: "success",
          });

          self.$emit("refreshProcesso");
          this.form.textoSolicitacao = "";
          this.validationErrors.descricao = null;
        })
        .catch((error) => {
          this.validationErrors.descricao = error.response.data.descricao
            ? error.response.data.descricao
            : null;
        });
    },
    handleFormSubmitLicitatorios() {
      this.loading = true;
      let data = {
        descricao: this.form.textoSolicitacao,
        prefeitura_id: this.prefeitura_id,
        processo_id: this.processo_id,
        tipo_solicitacao_id: 3,
        anexos: this.anexos_ids,
      };

      var self = this;

      this.$http
        .post("solicitacoes", data)
        .then(() => {
          this.loading = false;
          this.$swal({
            title:
              "Solicitação de documentação do processo licitatório enviada com sucesso!",
            icon: "success",
          });

          self.$emit("refreshProcesso");
          this.form.textoSolicitacao = "";
          this.validationErrors.descricao = null;
        })
        .catch((error) => {
          this.loading = false;
          this.validationErrors.descricao = error.response.data.descricao
            ? error.response.data.descricao
            : null;
        });
    },
  },
  props: {
    tipo: {
      type: Number,
    },
    prefeitura_id: {
      type: Number,
    },
    processo_id: {
      type: Number,
    },
    anexos_ids: {
      type: Array,
    },
    processo: {
      type: Object
    }
  },
  watch: {
    tipo: function () {
      this.form.textoSolicitacao = "";
    },
  },
};
</script>
