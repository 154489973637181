<template>
  <div class="form-group col-12">
    <b-form>
      <b-form-group
        label="Tipo de Documento:"
        class="font-weight-bolder"
        required
      >
        <multiselect
          v-model="tipoDocumento"
          :close-on-select="true"
          :options="tipos"
          open-direction="above"
          :searchable="false"
          :show-labels="false"
          placeholder="Selecione..."
          class="font-weight-normal"
          @input="onChange"
        ></multiselect>
      </b-form-group>

      <div v-if="tipoDocumento === 'Despacho'">
        <Despacho
          :mensagem="mensagem"
          :processo="processo"
          :tipo_documentos_id="tipo_documentos_id"
          @refreshProcesso="refreshProcesso"
        />
      </div>
      <div v-if="tipoDocumento === 'Notificação'">
        <Notificacao
          :mensagem="mensagem"
          :processo="processo"
          :tipo_documentos_id="tipo_documentos_id"
          @refreshProcesso="refreshProcesso"
        />
      </div>
      <div v-if="tipoDocumento === 'Memorando'">
        <Memorando
          :mensagem="mensagem"
          :processo="processo"
          :tipo_documentos_id="tipo_documentos_id"
          @refreshProcesso="refreshProcesso"
        />
      </div>
      <div v-if="tipoDocumento === 'Ofício'">
        <Oficio
          :mensagem="mensagem"
          :processo="processo"
          :tipo_documentos_id="tipo_documentos_id"
          @refreshProcesso="refreshProcesso"
        />
      </div>
    </b-form>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Despacho from '@/components/tabs/redigir-documentos/tipo-documento/Despacho';
import Notificacao from '@/components/tabs/redigir-documentos/tipo-documento/Notificacao';
import Memorando from '@/components/tabs/redigir-documentos/tipo-documento/Memorando';
import Oficio from '@/components/tabs/redigir-documentos/tipo-documento/Oficio';

export default {
  components: {
    Oficio,
    Memorando,
    Notificacao,
    Despacho,
    Multiselect,
  },
  data() {
    return {
      tipo_documentos_id: '',
      tipos: [],
      tipoDocumento: 'Selecione',
      mensagem: '',
    };
  },
  methods: {
    getTiposDocumento() {
      this.$http
        .get('tipos-documento', {})
        .then(res => {
          res.data.forEach(tipoDocumento => {
            this.tipos.push(tipoDocumento.descricao);
          });
        })
        .catch(error => {
          console.log(error.response);
        });
    },
    onChange() {
      if (this.tipoDocumento === 'Despacho') {
        this.tipo_documentos_id = 1;
      }
      if (this.tipoDocumento === 'Notificação') {
        this.tipo_documentos_id = 2;
      }
      if (this.tipoDocumento === 'Memorando') {
        this.tipo_documentos_id = 3;
      }
      if (this.tipoDocumento === 'Ofício') {
        this.tipo_documentos_id = 4;
      }
    },
    refreshProcesso() {
      this.$emit('refreshProcesso');
    },
  },
  created() {
    this.getTiposDocumento();
  },
  props: {
    processo: {
      type: Object,
    },
  },
};
</script>
