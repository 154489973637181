class Helper {
  verificaPermissao(permissao) {
    const user = JSON.parse(localStorage.getItem('user'));
    const permissoes = Array.isArray(user?.permissoes) ? user.permissoes : [];

    return permissoes.includes(permissao);
  }

  converterTextPermissao(descricao, permissao) {
    const keyMapping = {
      visualizar_busca_geral: "Busca Geral de Processos",
      visualizar_processos_setor_vinculado: "Minha Unidade",
      visualizar_processos_prefeitura_vinculada: "Lista de Processos da Prefeitura",
      visualizar_processos_inativos: "Processos Inativos",
      visualizar_arquivados: "Arquivados",
      desarquivar_processo: "Desarquivar Processo",
      visualizar_dados_processo: "Dados do Processo",
      arquivar_processo: "Arquivar Processo",
      editar_dados_processo: "Permitir Alterar Dados do Processo",
      editar_periodos_processo: "Permitir Alterar Períodos do Processo",
      editar_valores_processo: "Permitir Alterar Valores do Processo",
      adicionar_capa_processo: "Permitir Adicionar Capa",
      solicitar_documentacao_convenio: "Visualizar Solicitações",
      visualizar_compartilhamento: "Compartilhamento",
      remover_compartilhamento: "Permitir Remover Compartilhamento de Outras Unidades",
      visualizar_historico: "Histórico",
      visualizar_tarefas: "Tarefas",
      visualizar_documentacoes: "Documentações",
      visualizar_solicitacoes: "Solicitações",
      visualizar_redigir_documentos: "Redigir Documentos",
      visualizar_vincular_fiscais: "Vincular Fiscais",
      validar_recusar_plano_trabalho: "Gerenciar Plano de Trabalho",
      adicionar_signatario_plano_trabalho: "Adicionar Signatário em Plano de Trabalho",
      visualizar_paginador: "Paginador",
      visualizar_menu_de_servidores: "Servidores",
      visualizar_menu_fiscais: "Fiscais",
      visualizar_menu_relatorios: "Relatórios",
      visualizar_menu_assinaturas: "Assinaturas",
      visualizar_submenu_setor: "Setor",
      visualizar_submenu_eixos_subeixos: "Eixos",
      visualizar_submenu_perfis: "Perfis e Permissões",
      visualizar_submenu_prefeituras: "Prefeituras"
    };

    return keyMapping[permissao] || descricao;
  }

  only_price_to_number(v) {
    if (!v) { return 0; }
    v = v.split('.').join('');
    v = v.split(',').join('.');

    return v
  }

  money_mask(v) {
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{15})$/, '$1.$2');
    v = v.replace(/(\d{1})(\d{11})$/, '$1.$2');
    v = v.replace(/(\d{1})(\d{8})$/, '$1.$2');
    v = v.replace(/(\d{1})(\d{5})$/, '$1.$2');
    v = v.replace(/(\d{1})(\d{1,2})$/, '$1,$2');
    return `R$ ${v}`;
  }

  getValorTotalItens(data){
    const atual = data.map(item => Number(this.only_price_to_number(String(item.total).split('R$')[1].trim())))

    const valor = atual.reduce(function (acumulador, valorAtual, indice, array) {
      return acumulador + valorAtual;
    }, 0)

    return this.money_mask(Number(String(Number(valor).toFixed(2))).toFixed(2))
  }

  getValorTotalBDI(data){
    const atual = data.map(item => Number(this.only_price_to_number(String(item.total).split('R$')[1].trim())))

    const valor = atual.reduce(function (acumulador, valorAtual, indice, array) {
      return acumulador + valorAtual;
    }, 0)

    const bdi = valor * 0.25;

    return this.money_mask(Number(String(Number(bdi).toFixed(2))).toFixed(2))
  }

  getValorTotalItensComBDI(data){
    const atual = data.map(item => Number(this.only_price_to_number(String(item.total).split('R$')[1].trim())))

    const valor = atual.reduce(function (acumulador, valorAtual, indice, array) {
      return acumulador + valorAtual;
    }, 0)

    const bdi = valor * 0.25;

    const total = valor + bdi;

    return this.money_mask(Number(String(Number(total).toFixed(2))).toFixed(2))
  }

  totalGeral(data) {
    let total = 0;
    data.forEach(item => {
      const valorConcedente = parseFloat((item.concedente || '0').replace(/[^\d.,]/g, '').replace(/\./g, '').replace(',', '.')) || 0;
      const valorConvenente = parseFloat((item.convenente || '0').replace(/[^\d.,]/g, '').replace(/\./g, '').replace(',', '.')) || 0;

      total += valorConcedente + valorConvenente;
    });

    const formattedTotal = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total);
    return formattedTotal;
  }

  totalConcedente(data) {
    let total = 0;
    data.forEach(item => {
      const valorConcedente = parseFloat((item.concedente || '0').replace(/[^\d.,]/g, '').replace(/\./g, '').replace(',', '.')) || 0;
      total += valorConcedente;
    });

    const formattedTotal = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total);
    return formattedTotal;
  }

  totalConvenente(data) {
    let total = 0;
    data.forEach(item => {
      const valorConvenente = parseFloat((item.convenente || '0').replace(/[^\d.,]/g, '').replace(/\./g, '').replace(',', '.')) || 0;
      total += valorConvenente;
    });

    const formattedTotal = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total);
    return formattedTotal;
  }
}

export default new Helper();
