<template>
  <div>
    <multiselect
      v-model="solicitacao"
      :close-on-select="true"
      :options="solicitacoes"
      :searchable="false"
      open-direction="top"
      :show-labels="false"
      placeholder="Selecione..."
      @input="onChange"
      class="mb-4"
    ></multiselect>
    <FormSolicitacao
      :processo="processo"
      :anexos_ids="anexos_ids"
      :prefeitura_id="prefeitura_id"
      :processo_id="processo_id"
      :tipo="tipo"
      @refreshProcesso="refreshProcesso"
    />
  </div>
</template>
<script>
import { watch } from 'vue';
import FormSolicitacao from './FormSolicitacao.vue';
import Multiselect from 'vue-multiselect';

export default {
  components: { Multiselect, FormSolicitacao },
  props: {
    prefeitura_id: {
      type: Number,
    },
    processo_id: {
      type: Number,
    },
    anexos_ids: {
      type: Array,
    },
    processo: {
      type: Object
    }
  },
  data() {
    return {
      solicitacao: '',
      solicitacoes: [
        'Solicitar Diligência',
        'Solicitar Documento Pendente',
        'Solicitar Documentação do Processo Licitatório',
      ],
      tipo: null,
    };
  },
  methods: {
    onChange() {
      if (this.solicitacao === 'Solicitar Diligência') {
        this.tipo = 1;
      }
      if (this.solicitacao === 'Solicitar Documento Pendente') {
        this.tipo = 2;
      }
      if (
        this.solicitacao === 'Solicitar Documentação do Processo Licitatório'
      ) {
        this.tipo = 3;
      }
    },
    refreshProcesso() {
      this.$emit('refreshProcesso');
    },
  },
  mounted(){
    watch(() => this.processo, async (newProcesso, oldProcesso) => {
      if(this.processo.planos_de_trabalho.length == 0 && this.processo.solicitacoes_pendentes.filter(item => item.tipo_solicitacao_id == 3).length == 0){
        this.solicitacoes = [
          'Solicitar Diligência',
          'Solicitar Documento Pendente',
          'Solicitar Documentação do Processo Licitatório',
        ]
      } else {
        this.solicitacao = ''
        this.tipo = null
        this.solicitacoes = [
          'Solicitar Diligência',
          'Solicitar Documento Pendente',
        ]
      }
    })

    if(this.processo.planos_de_trabalho.length == 0 && this.processo.solicitacoes_pendentes.filter(item => item.tipo_solicitacao_id == 3).length == 0){
      this.solicitacoes = [
        'Solicitar Diligência',
        'Solicitar Documento Pendente',
        'Solicitar Documentação do Processo Licitatório',
      ]
    }else{
      this.solicitacoes = [
        'Solicitar Diligência',
        'Solicitar Documento Pendente',
      ]
    }
  }
};
</script>
