import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueMask from 'v-mask';
import httpClient from './plugins/axios';
import VueSweetalert2 from 'vue-sweetalert2';
import VueEditor from 'vue2-editor';
import Helper from './helpers.js';
import VueApexCharts from 'vue-apexcharts';
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'sweetalert2/dist/sweetalert2.min.css';

import './styles.css'

//Axios instance
Vue.prototype.$http = httpClient;

Vue.prototype.$helper = Helper;

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(VueMask);

Vue.use(VueSweetalert2);

Vue.use(VueEditor);

Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
