<template>
  <div style="color: #003e5d">
    <template>
      <b-row class="mt-4">
        <div class="col-12">
          <h4 class="font-weight-bold">Informações</h4>
        </div>
      </b-row>
      <b-row>
        <div class="col-12 mt-3">
          <label class="typo__label font-weight-bolder">Título</label>
          <b-form-input
            :disabled="!$helper.verificaPermissao('editar_dados_processo')"
            placeholder="Atribua um título..."
            v-model="titulo"
            required
          ></b-form-input>
        </div>
      </b-row>
      <b-row class="mt-4">
        <div class="col-6">
          <label class="typo__label font-weight-bolder">Status:</label>

          <Multiselect
            v-model="status"
            :options="todosStatus"
            :show-labels="false"
            placeholder="Selecione"
            class="font-weight-bolder border rounded"
            :disabled="!$helper.verificaPermissao('editar_dados_processo')"
          >
          </Multiselect>

          <form-error
            v-if="validationErrors.status"
            :error="validationErrors.status"
          />
        </div>
        <div class="col-6">
          <label class="typo__label font-weight-bolder">Protocolo</label>
          <b-form-input
            v-model="protocolo"
            :disabled="!$helper.verificaPermissao('editar_dados_processo')"
            class="form-control"
            placeholder="Atribua um número de protocolo..."
            required
          ></b-form-input>
          <form-error
            v-if="validationErrors.protocolo"
            :error="validationErrors.protocolo"
          />
        </div>
      </b-row>
    </template>
    <b-row class="mt-4">
      <div class="col-md-6">
        <label class="font-weight-bolder">Período de Execução</label>
        <b-form-datepicker
          class="mb-2 border-none"
          placeholder="Data Inicial"
          v-model="execucao_inicio"
          :disabled="!$helper.verificaPermissao('editar_periodos_processo')"
        ></b-form-datepicker>
        <b-form-datepicker
          class="mb-2"
          placeholder="Data Final"
          v-model="execucao_fim"
          :disabled="!$helper.verificaPermissao('editar_periodos_processo')"
        ></b-form-datepicker>
      </div>
      <div class="col-md-6">
        <label class="font-weight-bolder">Período de Vigência</label>
        <b-form-datepicker
          class="mb-2"
          placeholder="Data Inicial"
          v-model="vigencia_inicio"
          :disabled="!$helper.verificaPermissao('editar_periodos_processo')"
        ></b-form-datepicker>
        <b-form-datepicker
          class="mb-2"
          placeholder="Data Final"
          v-model="vigencia_fim"
          :disabled="!$helper.verificaPermissao('editar_periodos_processo')"
        ></b-form-datepicker>
      </div>
    </b-row>
    <b-row class="mt-4">
      <div class="col-md-6">
        <label class="font-weight-bolder">Valor do Termo do Convênio</label>
        <b-form-input
          type="text"
          v-mask="currencyMask"
          v-model="valor_convenio"
          placeholder="Atribua um valor..."
          :disabled="!$helper.verificaPermissao('editar_valores_processo')"
        />
      </div>
      <div class="col-md-6">
        <label class="font-weight-bolder">Valor do Processo Licitatório</label>
        <b-form-input
          type="text"
          v-mask="currencyMask"
          placeholder="Atribua um valor..."
          v-model="valor_processo_licitatorio"
          :disabled="!$helper.verificaPermissao('editar_valores_processo')"
        />
      </div>
    </b-row>
    <b-row class="mt-5">
      <div class="col-12">
        <h5 class="font-weight-bold">Detalhes</h5>
      </div>
    </b-row>
    <b-row class="my-5">
      <div class="col-md-4" v-for="fiscal in fiscais" :key="fiscal">
        <p class="font-weight-bolder">
          {{ fiscal }}:
          <span class="font-weight-normal">{{
            fiscaisOnProcess(fiscal) ?? `Nenhum ${fiscal} vinculado`
          }}</span>
        </p>
      </div>
    </b-row>
    <b-row class="mt-3">
      <div class="col-md-12">
        <p class="font-weight-bolder">
          Descrição:
          <div v-html="format(processo.descricao)" class="font-weight-normal text-break text-justify"></div>
        </p>
      </div>
      <div class="col-md-6">
        <p class="font-weight-bolder">
          Prefeitura:
          <span class="font-weight-normal">{{ processo.prefeitura.nome }}</span>
        </p>
      </div>
      <div class="col-md-6">
        <p class="font-weight-bolder">
          Eixo:
          <span class="font-weight-normal">{{ processo.eixo.descricao }}</span>
        </p>
      </div>
      <div class="col-md-6">
        <p class="font-weight-bolder">
          SubEixo:
          <span class="font-weight-normal">{{
            processo.subeixo.descricao
          }}</span>
        </p>
      </div>
    </b-row>
    <b-row>
      <div
        class="col-12 mt-4"
        v-if="$helper.verificaPermissao('adicionar_capa_processo')"
      >
        <div v-if="sdc && !processo.tem_capa">
          <label
            for="file"
            class="border px-4 py-3 bg-primary rounded text-white font-weight-bolder"
            style="cursor: pointer"
            ><b-icon icon="folder" class="mr-2" /> Selecionar capa</label
          >
          <input
            ref="capa"
            accept=".pdf"
            id="file"
            class="d-none"
            type="file"
            @change="handleCapaProcessoUpload($event)"
          />

          <form-error
            v-if="validationErrors.capa_processo"
            :error="validationErrors.capa_processo"
            class="ml-3"
          />
        </div>
      </div>
    </b-row>
    <b-row>
      <!-- TABELA CAPA DO PROCESSO  !-->
      <div v-if="processo.tem_capa || atualizaCapa" class="col-md-12 mt-3">
        <b-card class="table-responsive">
          <table class="table-sm">
            <tbody>
              <tr>
                <td>
                  <b>Capa do Processo:</b>
                </td>
                <td v-if="atualizaCapa">
                  <a :href="pdfData" @click.prevent="showPdf = !showPdf">
                    {{ pdfNome }}
                  </a>
                  <iframe
                    v-if="showPdf"
                    :src="pdfData"
                    style="display: block; width: 100%; height: 500px"
                  ></iframe>
                </td>
                <td v-else>
                  <a :href="processo.capa.caminho" target="_blank">
                    {{ processo.capa.nome_original }}
                  </a>
                </td>
                <td>
                  <div v-if="sdc">
                    <b-button
                      v-if="atualizaCapa"
                      class="ml-2"
                      size="sm"
                      variant="danger"
                      @click="excluirCapa()"
                    >
                      <b-icon icon="trash-fill"></b-icon>
                    </b-button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </div>
    </b-row>
    <!-- TABELA CAPA DO PROCESSO  !-->
    <div v-if="sdc" class="col-12 mt-5 text-right border-top pt-3">
      <b-button
          class="ml-2 text-right border-top pt-2"
          variant="danger"
          @click="arquivar()"
          v-if="$helper.verificaPermissao('arquivar_processo')"
        >
        Arquivar Processo
      </b-button>
      <b-button
        class="ml-2 px-5 py-2"
        variant="success"
        @click="confirmBoxOnSave()"
      >
        Salvar
      </b-button>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import FormError from '@/components/FormError';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const currencyMask = createNumberMask({
  prefix: 'R$',
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  thousandsSeparatorSymbol: '.',
  decimalSymbol: ',',
});

export default {
  components: {
    Multiselect,
    FormError,
  },
  props: {
    sdc: {
      type: Boolean,
    },
    prefeitura: {
      type: Boolean,
    },
    processo: {
      type: Object,
    },
  },
  data() {
    return {
      todosStatus: ['Encaminhado', 'Recebido', 'Em Análise', 'Concluído'],
      status: this.processo.status ? this.processo.status : '',
      protocolo: this.processo.protocolo ? this.processo.protocolo : '',
      valor_convenio: this.processo.valor_convenio
        ? this.processo.valor_convenio.replace('.', ',')
        : '',
      valor_processo_licitatorio: this.processo.valor_processo_licitatorio
        ? this.processo.valor_processo_licitatorio.replace('.', ',')
        : '',
      vigencia_inicio: this.processo.vigencia_inicio
        ? this.processo.vigencia_inicio
        : '',
      vigencia_fim: this.processo.vigencia_fim
        ? this.processo.vigencia_fim
        : '',
      execucao_inicio: this.processo.execucao_inicio
        ? this.processo.execucao_inicio
        : '',
      execucao_fim: this.processo.execucao_fim
        ? this.processo.execucao_fim
        : '',
      titulo: this.processo.titulo ? this.processo.titulo : '',
      atualizaCapa: 0,
      capa_processo: null,
      pdfData: null,
      showPdf: false,
      pdfNome: '',
      validationErrors: {
        protocolo: null,
        status: null,
        tem_capa: null,
        capa_processo: null,
      },
      currencyMask,
      fiscais: [
        'Fiscal Administrativo',
        'Gestor de Convênio',
        'Fiscal Técnico',
      ],
    };
  },
  emits: ['fechaModal', 'processo', 'refresh'],
  methods: {
    format(text){
      return text.replace(/\n/g, '<br>')
    },
    confirmBoxOnSave() {
      this.$bvModal
        .msgBoxConfirm(
          'Algumas informações como: Capa do Processo, não podem ser alteradas após o processo ser salvo. Deseja continuar?',
          {
            title: 'Salvar Processo',
            size: 'md',
            buttonSize: 'md',
            okVariant: 'danger',
            okTitle: 'Sim',
            cancelTitle: 'Não',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(value => {
          if (!value) return;
          this.salvar();
        });
    },
    fiscaisOnProcess(relationship) {
      const find = this.processo.fiscais.find(
        fiscal => fiscal.relacao === relationship
      );

      if (find) return find.name;

      return null;
    },
    handleCapaProcessoUpload(event) {
      this.capa_processo = event.target.files[0];
      this.atualizaCapa = 1;
      const pdfFile = this.$refs.capa.files[0];

      if (pdfFile.type === 'application/pdf') {
        this.pdfNome = pdfFile.name;
        const reader = new FileReader();

        reader.addEventListener('load', () => {
          this.pdfData = reader.result;
        });

        reader.readAsDataURL(pdfFile);
      }
    },
    excluirCapa() {
      this.$swal({
        icon: 'warning',
        title: 'Deseja realmente excluir a capa deste processo?',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#3085d6',
        denyButtonText: 'Cancelar',
      }).then(result => {
        if (result.isConfirmed) {
          this.capa_processo = null;
          this.atualizaCapa = 0;
          this.$refs.capa.value = null;
          this.showPdf = false;
        }
      });
    },
    salvar() {
      if (this.sdc) {
        let formData = new FormData();
        if (this.status) {
          formData.append('status', this.status);
        }
        if (this.protocolo) {
          formData.append('protocolo', this.protocolo);
        }
        if (this.valor_convenio) {
          const regexMonetario = /[R$r.]/g;
          const valorConvenio = parseFloat(
            this.valor_convenio.replace(regexMonetario, '').replace(',', '.')
          );
          formData.append('valor_convenio', valorConvenio);
        }
        if (this.valor_processo_licitatorio) {
          const regexMonetario = /[R$r.]/g;
          const valorLicitatorio = parseFloat(
            this.valor_processo_licitatorio
              .replace(regexMonetario, '')
              .replace(',', '.')
          );
          formData.append('valor_processo_licitatorio', valorLicitatorio);
        }
        if (this.vigencia_inicio) {
          formData.append('vigencia_inicio', this.vigencia_inicio);
        }
        if (this.vigencia_fim) {
          formData.append('vigencia_fim', this.vigencia_fim);
        }
        if (this.execucao_inicio) {
          formData.append('execucao_inicio', this.execucao_inicio);
        }
        if (this.execucao_fim) {
          formData.append('execucao_fim', this.execucao_fim);
        }
        if (this.titulo) {
          formData.append('titulo', this.titulo);
        }
        if (this.capa_processo?.type === 'application/pdf') {
          formData.append('capa_processo[]', this.capa_processo);
          formData.append('tem_capa', '1');
        }
        formData.append('_method', 'PUT');
        this.$http
          .post(`processos/${this.processo.id}`, formData)
          .then(() => {
            this.capa_processo = null;
            this.atualizaCapa = 0;
            if (this.$refs.capa?.value) {
              this.$refs.capa.value = null;
            }
            this.showPdf = false;
            this.validationErrors = {
              protocolo: null,
              status: null,
              tem_capa: null,
              capa_processo: null,
            };
            this.$emit('processo', this.processo);
            this.$swal({
              title: 'Processo atualizado com sucesso!',
              icon: 'success',
            });
          })
          .catch(error => {
            this.$swal({ title: 'Erro!', icon: 'error' });
            console.log(error.response.data);
            this.validationErrors = error.response.data
              ? error.response.data
              : null;
            console.log(error);
          });
      }
    },
    arquivar() {
      this.$swal({
        icon: 'warning',
        title: 'Deseja realmente arquivar este processo?',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#3085d6',
        denyButtonText: 'Cancelar',
      }).then(result => {
        if (result.isConfirmed) {
          this.$http
            .put(`processos/${this.processo.id}/arquivar`)
            .then(() => {
              this.$emit('processo', this.processo);
              this.$swal({
                title: 'Processo arquivado com sucesso!',
                icon: 'success',
              });
              this.$emit('refresh');
              this.$emit('fechaModal');
            })
            .catch(error => {
              this.$swal({ title: 'Erro!', icon: 'error' });
              console.log(error.response.data);
              this.validationErrors = error.response.data
                ? error.response.data
                : null;
              console.log(error);
            });
        }
      });
    },
    atualizarProcessoArquivado() {
      this.$emit('processo', this.processo);
    },
  },
};
</script>
