<template>
  <main>
    <b-container fluid class="mt-4 p-4 border">
      <b-row class="mb-4">
        <b-col class="md-6">
          <h4 class="mb-0">Setores</h4>
        </b-col>
        <b-col class="md-6">
          <b-button
            @click="showNovo"
            v-b-modal.modal-1
            variant="outline-primary"
            class="ml-1 float-right"
            :disabled="isLoading"
            >Cadastrar
          </b-button>
        </b-col>
      </b-row>

      <b-overlay :show="isLoading" rounded="sm">
        <div class="table-responsive">
          <table class="table table-sm">
            <thead>
              <tr>
                <th></th>
                <th>#</th>
                <th>Nome</th>
                <th>Ações</th>
              </tr>
            </thead>

            <tbody>
              <template v-for="(setor, index) in setores">
                <tr :class="{ opened: setor.open }">
                  <td style="width: 5%;"><button data-testid="button-visualizar-setores-dropdown" class="btn btn-default btn-xs" @click="setor.open = !setor.open" v-b-tooltip.hover :title="setor.open ? 'Ocultar subsetores': 'Exibir subsetores'" v-if="setor.hasOwnProperty('subsetores')"><b-icon :style="setor.open ? 'color: #fff;' : 'color: #1b7e9f;'" :icon="!setor.open ? 'arrow-right-circle-fill' : 'arrow-down-circle-fill'"></b-icon></button></td>
                  <td style="width: 5%;">{{ Number(index) + 1 }}</td>
                  <td style="width: 80%;">{{ setor.nome }}</td>
                  <td style="width: 10%;">
                    <div class="d-flex align-items-center">
                      <button
                        @click="showEditar(setor)"
                        v-b-modal.modal-1
                        class="btn btn-primary btn-sm"
                        v-b-tooltip.hover
                        title="Editar"
                      >
                        <b-icon icon="pencil"></b-icon>
                      </button>
                      <button
                        @click="excluirSetor(setor)"
                        class="btn btn-danger btn-sm ml-2"
                      >
                        <b-icon icon="trash"></b-icon>
                      </button>
                    </div>
                  </td>
                </tr>

                <tr v-if="setor.open" :class="{ 'opened-inside': setor.open }">
                  <td colspan="5" class="hiddenRow">
                    <table class="table table-sm">
                      <tbody>
                        <template v-for="subsetor in setor.subsetores">
                          <tr>
                            <td style="width: 5%;"><button class="btn btn-default btn-xs" @click="subsetor.open = !subsetor.open" v-b-tooltip.hover :title="subsetor.open ? 'Ocultar subsetores': 'Exibir subsetores'" v-if="subsetor.hasOwnProperty('subsetores') && subsetor.subsetores.length > 0"><b-icon style="color: #1b7e9f;" :icon="!subsetor.open ? 'arrow-right-circle-fill' : 'arrow-down-circle-fill'"></b-icon></button></td>
                            <td style="width: 5%;">{{ Number(index) + 1 }}.{{ Number(subsetor.index) + 1 }}</td>
                            <td style="width: 80%;">{{ subsetor.nome }}</td>
                            <td style="width: 10%;">
                              <div class="d-flex align-items-center">
                                <button
                                  @click="showEditar(subsetor)"
                                  v-b-modal.modal-1
                                  class="btn btn-primary btn-sm"
                                  v-b-tooltip.hover
                                  title="Editar"
                                >
                                  <b-icon icon="pencil"></b-icon>
                                </button>
                                <button
                                  @click="excluirSetor(subsetor)"
                                  class="btn btn-danger btn-sm ml-2"
                                >
                                  <b-icon icon="trash"></b-icon>
                                </button>
                              </div>
                            </td>
                          </tr>

                          <template v-if="subsetor.open" v-for="subsubsetor in subsetor.subsetores" >
                            <tr>
                              <td style="width: 5%;"><button class="btn btn-default btn-xs" @click="subsubsetor.open = !subsubsetor.open" v-b-tooltip.hover :title="subsubsetor.open ? 'Ocultar subsetores': 'Exibir subsetores'" v-if="subsubsetor.hasOwnProperty('subsetores') && subsubsetor.subsetores.length > 0"><b-icon style="color: #1b7e9f;" :icon="!subsubsetor.open ? 'arrow-right-circle-fill' : 'arrow-down-circle-fill'"></b-icon></button></td>
                              <td style="width: 5%;">{{ Number(index) + 1 }}.{{ Number(subsetor.index) + 1 }}.{{ Number(subsubsetor.index) + 1 }}</td>
                              <td style="width: 80%;">{{ subsubsetor.nome }}</td>
                              <td style="width: 10%;">
                                <div class="d-flex align-items-center">
                                  <button
                                    @click="showEditar(subsubsetor)"
                                    v-b-modal.modal-1
                                    class="btn btn-primary btn-sm"
                                    v-b-tooltip.hover
                                    title="Editar"
                                  >
                                    <b-icon icon="pencil"></b-icon>
                                  </button>
                                  <button
                                    @click="excluirSetor(subsubsetor)"
                                    class="btn btn-danger btn-sm ml-2"
                                  >
                                    <b-icon icon="trash"></b-icon>
                                  </button>
                                </div>
                              </td>
                            </tr>

                            <tr v-if="subsubsetor.open" v-for="subsubsubsetor in subsubsetor.subsetores" :key="subsubsubsetor.id">
                              <td style="width: 5%;"></td>
                              <td style="width: 5%;">{{ Number(index) + 1 }}.{{ Number(subsetor.index) + 1 }}.{{ Number(subsubsetor.index) + 1 }}.{{ Number(subsubsubsetor.index) + 1 }}</td>
                              <td style="width: 80%;">{{ subsubsubsetor.nome }}</td>
                              <td style="width: 10%;">
                                <div class="d-flex align-items-center">
                                  <button
                                    @click="showEditar(subsubsubsetor)"
                                    v-b-modal.modal-1
                                    class="btn btn-primary btn-sm"
                                    v-b-tooltip.hover
                                    title="Editar"
                                  >
                                    <b-icon icon="pencil"></b-icon>
                                  </button>
                                  <button
                                    @click="excluirSetor(subsubsubsetor)"
                                    class="btn btn-danger btn-sm ml-2"
                                  >
                                    <b-icon icon="trash"></b-icon>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </template>
                        </template>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </b-overlay>

      <b-modal
        id="modal-1"
        :title="titulo"
        :hide-footer="true"
        title-class="font-weight-bold"
      >
        <b-form>
          <div class="row">
            <div class="col-6">
              <b-form-group label="Sigla: *" label-class="font-weight-bolder">
                <b-form-input
                  v-model="sigla"
                  required
                  placeholder="Digite uma sigla..."
                ></b-form-input>
                <form-error
                  v-if="validationErrors.sigla"
                  :error="['O campo Sigla é obrigatório']"
                />
              </b-form-group>
            </div>
            <div class="col-6">
              <b-form-group label="Nome: *" label-class="font-weight-bolder">
                <b-form-input
                  v-model="nome"
                  required
                  placeholder="Digite um nome..."
                ></b-form-input>
                <form-error
                  v-if="validationErrors.nome"
                  :error="['O campo Nome é obrigatório']"
                />
              </b-form-group>
            </div>
            <div class="col-12">
              <b-form-group
                label="Setor Superior:"
                required
                label-class="font-weight-bolder"
              >
                <b-form-select
                  v-model="setor_superior_id"
                  :options="setores_superiores"
                >
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-12 mt-4">
              <b-button
                @click="editar ? editarSetor() : addSetor()"
                variant="primary"
                class="float-right"
                :disabled="isLoading"
                >{{ editar ? 'Editar' : 'Salvar' }}</b-button
              >
            </div>
          </div>
        </b-form>
      </b-modal>
    </b-container>
  </main>
</template>

<script>
import FormError from '@/components/FormError';
export default {
  components: { FormError },
  data() {
    return {
      setor: '',
      isLoading: false,
      titulo: '',
      editar: false,
      nome: '',
      sigla: '',
      setor_superior_id: '',
      setores: [],
      setores_superiores: [],
      validationErrors: {
        nome: null,
        sigla: null,
      },
    };
  },
  methods: {
    reloadSetores() {
      this.$http
        .get('setores', {})
        .then(res => {
          /* res.data.forEach(setor => {
            this.setores_superiores.push({ value: setor.id, text: setor.nome });
          }); */

          this.setores_superiores = [{ value: '', text: 'Nenhum' }, ...res.data.map(setor => ({ value: setor.id, text: setor.nome }))]

          const todos_setores = res.data
          const superiores = []
          const superiores_finais = []

          for(let i=0;i<todos_setores.length;i++){
            superiores.push(...todos_setores.filter(item => item.id == todos_setores[i].setor_superior_id))
          }

          const superiores_unicos = superiores.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)

          for(let j=0;j<superiores_unicos.length;j++){
            superiores_finais.push({
              ...superiores_unicos[j],
              subsetores: todos_setores.filter(item => item.setor_superior_id == superiores_unicos[j].id).map((subitem, index) => ({ index, ...subitem }))
            })
          }

          const final = [...superiores_finais.sort(function(a, b){return a.id - b.id}), ...todos_setores.filter(item => item.setor_superior_id == null)].filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)

          const aux = final.map(item => ({ ...item, open: false })).sort(function(a, b){return a.id - b.id});

          this.setores = aux

          const aux2 = this.createNewSubtasks(todos_setores, aux)
          this.setores = aux2
          const oldArray = aux.map(supersetor => supersetor.hasOwnProperty('subsetores') ? supersetor.subsetores.map(subsetor => subsetor.id) : [])
          var newArray = Array.prototype.concat.apply([], oldArray);
          const aux3 = aux2.filter(setor => !newArray.includes(setor.id))

          this.setores = aux3

          const aux4 = this.createNewSubtasksFromSubtasks(todos_setores, aux3)
          this.setores = aux4
          const oldArray2 = aux.map(supersetor => supersetor.hasOwnProperty('subsetores') ? supersetor.subsetores.map(subsetor => subsetor.id) : [])
          var newArray2 = Array.prototype.concat.apply([], oldArray2);
          const aux5 = aux4.filter(setor => !newArray2.includes(setor.id))

          this.setores = aux5
        })
        .catch(error => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
    addSetor() {
      this.editar = false;

      let data = {
        nome: this.nome,
        sigla: this.sigla,
        setor_superior_id: this.setor_superior_id,
      };
      this.isLoading = true;
      this.$http
        .post('setores', data, {})
        .then(res => {
          this.$swal({
            title: 'Setor cadastrado com sucesso!',
            icon: 'success',
          });
          this.setores.push(res.data);

          this.clear();
          this.isLoading = false;
          this.$bvModal.hide('modal-1');

          this.reloadSetores();
        })
        .catch(error => {
          this.validationErrors.nome = error.response.data.nome
            ? error.response.data.nome
            : null;
          this.validationErrors.sigla = error.response.data.sigla
            ? error.response.data.sigla
            : null;
          console.log(error.response.data);
          this.isLoading = false;
        });
    },
    getSetores() {
      this.isLoading = true;
      this.$http
        .get('setores', {})
        .then(res => {
          /* res.data.forEach(setor => {
            this.setores_superiores.push({ value: setor.id, text: setor.nome });
          }); */

          this.setores_superiores = [{ value: '', text: 'Nenhum' }, ...res.data.map(setor => ({ value: setor.id, text: setor.nome }))]

          const todos_setores = res.data
          const superiores = []
          const superiores_finais = []

          for(let i=0;i<todos_setores.length;i++){
            superiores.push(...todos_setores.filter(item => item.id == todos_setores[i].setor_superior_id))
          }

          const superiores_unicos = superiores.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)

          for(let j=0;j<superiores_unicos.length;j++){
            superiores_finais.push({
              ...superiores_unicos[j],
              subsetores: todos_setores.filter(item => item.setor_superior_id == superiores_unicos[j].id).map((subitem, index) => ({ index, ...subitem }))
            })
          }

          const final = [...superiores_finais.sort(function(a, b){return a.id - b.id}), ...todos_setores.filter(item => item.setor_superior_id == null)].filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)

          const aux = final.map(item => ({ ...item, open: false })).sort(function(a, b){return a.id - b.id});

          this.setores = aux

          const aux2 = this.createNewSubtasks(todos_setores, aux)
          this.setores = aux2
          const oldArray = aux.map(supersetor => supersetor.hasOwnProperty('subsetores') ? supersetor.subsetores.map(subsetor => subsetor.id) : [])
          var newArray = Array.prototype.concat.apply([], oldArray);
          const aux3 = aux2.filter(setor => !newArray.includes(setor.id))

          this.setores = aux3

          const aux4 = this.createNewSubtasksFromSubtasks(todos_setores, aux3)
          this.setores = aux4
          const oldArray2 = aux.map(supersetor => supersetor.hasOwnProperty('subsetores') ? supersetor.subsetores.map(subsetor => subsetor.id) : [])
          var newArray2 = Array.prototype.concat.apply([], oldArray2);
          const aux5 = aux4.filter(setor => !newArray2.includes(setor.id))

          this.setores = aux5

          this.isLoading = false;
        })
        .catch(error => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
    createNewSubtasks(todos_setores, setores){
      const aux = setores.map(setor => !setor.hasOwnProperty('subsetores') ? setor :
        ({
          ...setor,
          subsetores: setor.subsetores.map(subsetor => ({
            ...subsetor,
            open: false,
            subsetores: todos_setores.filter(item => item.setor_superior_id == subsetor.id).map((subitem, index) => ({ index, ...subitem }))
          }))
        })
      )

      return aux
    },
    createNewSubtasksFromSubtasks(todos_setores, setores){
      const aux = setores.map(setor => !setor.hasOwnProperty('subsetores') ? setor :
        ({
          ...setor,
          subsetores: setor.subsetores.map(subsetor => ({
            ...subsetor,
            open: false,
            subsetores: subsetor.subsetores.map(subsubsetor => ({
              ...subsubsetor,
              open: false,
              subsetores: todos_setores.filter(item => item.setor_superior_id == subsubsetor.id).map((subitem, index) => ({ index, ...subitem }))
            }))
          }))
        })
      )

      return aux
    },
    showNovo() {
      this.titulo = 'Novo Setor';
      this.editar = false;

      this.nome = '';
      this.sigla = '';
      this.password = '';
      this.perfil = '';
      this.setor_superior_id = ''
    },
    showEditar(setor) {
      console.log(setor)
      this.titulo = 'Editar Setor';
      this.editar = true;

      this.setor = setor;
      this.nome = setor.nome;
      this.sigla = setor.sigla ?? '';
      this.setor_superior_id = setor.setor_superior_id
        ? setor.setor_superior_id
        : '';
    },
    editarSetor() {
      let data = {
        nome: this.nome,
        sigla: this.sigla,
        setor_superior_id: this.setor_superior_id,
      };
      this.isLoading = true;
      this.$http
        .put(`setores/${this.setor.id}`, data, {})
        .then(res => {
          this.$swal({ title: 'Setor editado com sucesso!', icon: 'success' });
          this.setores = this.setores.map(elem =>
            elem.id === res.data.id ? res.data : elem
          );
          this.clear();
          this.isLoading = false;
          this.$bvModal.hide('modal-1');

          this.reloadSetores();
        })
        .catch(error => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
    excluirSetor(setor) {
      this.$swal({
        icon: 'warning',
        title: 'Tem certeza que deseja excluir esse setor?',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#3085d6',
        denyButtonText: 'Cancelar',
      })
        .then(result => {
          this.isLoading = true;
          if (result.isConfirmed) {
            this.$http.delete(`setores/${setor.id}`, {}).then(res => {
              this.$swal('Setor removido com sucesso!', '', 'success');
              this.setores = this.setores.filter(elem => elem.id !== setor.id);

              this.reloadSetores();
            });
          } else if (result.isDenied) {
            this.$swal('Uffa! Foi por pouco.', '', 'warning');
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
    clear() {
      this.setor = {};
      this.nome = '';
      this.sigla = '';
      this.password = '';
      this.perfil = '';
      this.validationErrors.nome = null;
      this.validationErrors.sigla = null;
    },
  },
  created() {
    this.getSetores();
  },
  beforeCreate() {
    //Check if profile is SDC, if not, redirect to home.
    if (JSON.parse(localStorage.getItem('user')).perfil.grupo_perfil_id != '1') {
      this.$router.push('/processos/minha-unidade');
    }
  },
};
</script>

<style scoped>
td,
th {
  vertical-align: middle !important;
}
</style>
