<template>
  <main>
    <b-container
      fluid
      class="mt-4 p-4 border"
      v-if="$helper.verificaPermissao('visualizar_submenu_perfis')"
    >
      <b-row class="mb-4">
        <b-col class="md-6">
          <h4 class="mb-0">Perfis</h4>
        </b-col>
        <b-col class="md-6">
          <b-button
            @click="showNovo"
            v-b-modal.modal-1
            variant="outline-primary"
            class="ml-1 float-right"
            :disabled="isLoading"
            >Cadastrar
          </b-button>
        </b-col>
      </b-row>

      <b-overlay :show="isLoading" rounded="sm">
        <div class="table-responsive">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nome</th>
                <th scope="col" class="col-2">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="perfil in perfis" :key="perfil.id">
                <td>{{ perfil.id }}</td>
                <td>{{ perfil.nome }}</td>
                <td>
                  <div class="d-flex align-items-center">
                    <button
                      @click="showEditar(perfil)"
                      v-b-modal.modal-1
                      class="btn btn-primary btn-sm"
                      v-b-tooltip.hover
                      title="Editar"
                    >
                      <b-icon icon="pencil" />
                    </button>
                    <button
                      @click="setPerfil(perfil)"
                      v-b-modal.modal-permissoes
                      v-b-tooltip.hover
                      title="Adicionar permissões"
                      class="btn btn-success btn-sm ml-2"
                    >
                      <b-icon icon="shield-plus" />
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>
      <b-modal id="modal-permissoes" title="Atualize as Permissões" size="lg">
        <b-overlay :show="isLoading" rounded="sm">
          <div
            v-for="grupo in grupos"
            :key="grupo.id"
            :value="grupo"
            class="accordion"
            role="tablist"
          >
            <b-button
              block
              v-b-toggle="'accordion-productdetails' + grupo.id"
              variant="info"
              class="mt-2 mb-2"
              >{{ grupo.nome }}</b-button
            >
            <b-collapse
              :id="'accordion-productdetails' + grupo.id"
              isible
              :accordion="'productdetails-accordion' + grupo.id"
              role="tabpanel"
            >
              <div class="container-fluid">
                <div class="row">
                  <div class="col-sm-12">
                    <div>
                      <div v-for="permissao in grupo.permissoes" :key="permissao.id">
                        <!-- <span v-if="permissao.id == 7">DADOS DO PROCESSO</span> -->
                        <!-- <span v-if="permissao.id == 14">COMPARTILHAMENTO</span> -->
                        <!-- <span v-if="permissao.id == 17">TAREFAS</span> -->
                        <hr v-if="permissao.id == 1" />
                        <hr v-if="permissao.id == 3" />
                        <hr v-if="permissao.id == 5" />
                        <hr v-if="permissao.id == 7" />
                        <hr v-if="permissao.id == 13" />
                        <hr v-if="permissao.id == 14" />
                        <hr v-if="permissao.id == 16" />
                        <hr v-if="permissao.id == 17" />
                        <hr v-if="permissao.id == 22" />

                        <hr v-if="permissao.id == 23" />
                        <hr v-if="permissao.id == 24" />
                        <hr v-if="permissao.id == 25" />
                        <hr v-if="permissao.id == 26" />

                        <hr v-if="permissao.id == 27" />
                        <hr v-if="permissao.id == 28" />
                        <hr v-if="permissao.id == 29" />
                        <hr v-if="permissao.id == 30" />

                        <b-checkbox
                          :value="permissao.id"
                          v-model="permissoesSelecionadas"
                        >
                          <b> {{ permissao.descricao }} </b></b-checkbox
                        >
                        <hr v-if="permissao.id == 5 && perfil.grupo_perfil_id == 2" />

                        <hr v-if="permissao.id == 6" />
                        <hr v-if="permissao.id == 22" />

                        <hr v-if="permissao.id == 26" />

                        <hr v-if="permissao.id == 32" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
          <br />
        </b-overlay>

        <template #modal-footer>
            <b-btn
              variant="secondary"
              @click="$bvModal.hide('modal-permissoes')"
              size="sm"
              :disabled="isLoading"
              >Cancelar
            </b-btn>
            <b-button
              variant="primary"
              size="sm"
              @click="enviarPermissoes()"
              :disabled="isLoading"
              >Salvar</b-button
            >
          </template>
      </b-modal>

      <b-modal
        id="modal-1"
        :title="editar ? 'Editar Perfil' : 'Novo Perfil'"
        :hide-footer="true"
        @hide="clear()"
        title-class="font-weight-bold"
      >
        <b-form>
          <div class="row">
            <div class="col-6">
              <b-form-group label="Nome:" label-class="font-weight-bolder">
                <b-form-input
                  v-model="nome"
                  required
                  placeholder="Digite um nome..."
                ></b-form-input>
                <form-error
                  v-if="validationErrors.nome"
                  :error="['O campo nome é obrigatório']"
                />
              </b-form-group>
            </div>
            <div class="col-12">
              <b-form-group
                label="Grupo Perfil:"
                required
                label-class="font-weight-bolder"
              >
                <b-form-select v-model="grupoPerfil" :options="grupoPerfis">
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-12 mt-4">
              <b-button
                @click="editar ? editarPerfil() : addPerfil()"
                variant="primary"
                :disabled="isLoading || !grupoPerfil"
                class="float-right"
                >{{ editar ? 'Editar' : 'Salvar' }}</b-button
              >
            </div>
          </div>
        </b-form>
      </b-modal>
    </b-container>
    <h1 v-else>Seu perfil não tem as devidas permissões</h1>
  </main>
</template>

<script>
import { watch } from 'vue'

import FormError from '@/components/FormError';
export default {
  components: { FormError },
  data() {
    return {
      titulo: '',
      editar: false,
      perfil: {},
      nome: '',
      isLoading: false,
      perfis: [],
      permissoes: [],
      listagem_permissoes: [],
      permissao: [],
      perfis_permissoes: [],
      permissoesSelecionadas: [],
      validationErrors: {
        nome: null,
      },
      grupoPerfis: [],
      grupoPerfil: null,
      grupos: [],
    };
  },
  methods: {
    addPerfil() {
      this.editar = false;

      const data = {
        nome: this.nome,
        grupo_perfil_id: this.grupoPerfil,
      };

      this.isLoading = true;

      this.$http
        .post('perfis', data, {})
        .then(res => {
          this.$swal({
            title: 'Perfil cadastrado com sucesso!',
            icon: 'success',
          });
          this.perfis.push({ ...res.data, permissoes: [] });
          this.$bvModal.hide('modal-1');
          this.isLoading = false;
        })
        .catch(error => {
          this.validationErrors.nome = error.response.data.nome
            ? error.response.data.nome
            : null;
          this.isLoading = false;
          console.log(error.response.data);
        });
    },
    getPerfis() {
      this.isLoading = true;
      this.$http
        .get('perfis', {})
        .then(res => {
          this.perfis = res.data;
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
    getPermissoes() {
      this.isLoading = true;
      this.$http
        .get('permissoes', {})
        .then(res => {
          this.permissoes = res.data;
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
    showNovo() {
      this.titulo = 'Novo Perfil';
      this.editar = false;

      this.nome = '';
    },
    setPerfil(perfil) {
      this.permissoesSelecionadas = [];
      this.perfil = perfil;
      this.perfil.permissoes.forEach(item => {
        this.permissoesSelecionadas.push(item.id);
      });
    },
    showEditar(perfil) {
      this.titulo = 'Editar Perfil';
      this.editar = true;

      this.perfil = perfil;
      this.nome = perfil.nome;
      this.grupoPerfil = perfil.grupo_perfil_id;
    },
    editarPerfil() {
      const data = {
        nome: this.nome,
        grupo_perfil_id: this.grupoPerfil,
      };
      this.isLoading = true;
      this.$http
        .put(`perfis/${this.perfil.id}`, data, {})
        .then(res => {
          this.$swal({ title: 'Perfil editado com sucesso!', icon: 'success' });
          this.perfis = this.perfis.map(elem => {
            if (elem.id === res.data.id) {
              return {
                ...res.data,
                permissoes: elem.permissoes,
              };
            }
            return elem;
          });
          this.$bvModal.hide('modal-1');
          this.clear();
          this.me();
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
    clear() {
      this.perfil = {};
      this.nome = '';
      this.validationErrors.nome = null;
      this.grupoPerfil = null;
      this.grupo_perfil_id = '';
    },
    handleCheckbox(event, grupos) {
      this.listagem_permissoes.push(grupos.id);
    },
    enviarPermissoes() {
      let data = {
        permissoes: this.permissoesSelecionadas,
        perfil_id: this.perfil.id,
      };

      this.isLoading = true;
      this.$http
        .post('perfil-permissoes', data, {})
        .then(() => {
          this.$swal({
            title: 'Permissões Salvas Com Sucesso!',
            icon: 'success',
          });
          this.listagem_permissoes = [];
          this.perfis = [];
          this.getPerfis();
          // this.perfil.id = [];
          this.$bvModal.hide('modal-permissoes');
          this.isLoading = false;
          this.me();
        })
        .catch(error => {
          this.$swal({
            title: `${error.response}`,
            icon: 'error',
          });
          this.isLoading = false;
        });
    },
    async me() {
      try {
        const response = await this.$http.post('auth/me', {});
        localStorage.setItem('user', JSON.stringify(response.data));
        localStorage.setItem('user_name', response.data.name ?? '');
      } catch (error) {
        console.log(error.response);
      }
    },
    getGrupoPerfis() {
      this.isLoading = true;
      this.$http
        .get('grupo-perfis', {})
        .then(res => {
          this.grupoPerfis.push({ value: null, text: 'Selecione...' });
          res.data.forEach(grupoPerfil => {
            this.grupoPerfis.push({
              value: grupoPerfil.id,
              text: grupoPerfil.descricao,
            });
          });
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
    getGruposPermissoes(grupo_perfil_id = 1) {
      this.isLoading = true;

      let available = []
      if(grupo_perfil_id == 1) {
        available = [1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32]
      } else {
        available = [3, 5, 7, 13, 16, 22]
      }

      this.$http
        .get('grupos-permissao')
        .then(res => {
          this.grupos = res.data.grupo_permissao
            .map(item => ({...item, nome: item.nome == 'PROCESSO' ? 'PROCESSOS' : item.nome, permissoes: item.permissoes.filter(permissao => available.includes(Number(permissao.ordem || permissao.id))).map(oldPermission => ({...oldPermission, descricao: this.$helper.converterTextPermissao(oldPermission.descricao, oldPermission.permissao)})).sort(function(a, b){return (a.ordem || a.id) - (b.ordem || b.id)})}))
            .filter(grupo => grupo_perfil_id == 1 ? true : (grupo.id == 1 || grupo.id == 2)).sort(function(a, b){return a.id - b.id});
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getPerfis();
    // this.getPermissoes();
    this.getGrupoPerfis();
    this.getGruposPermissoes();

    watch(
      () => this.perfil,
      () => {
        this.getGruposPermissoes(this.perfil.grupo_perfil_id)
      }
    )
  },
};
</script>

<style scoped>
td,
th {
  vertical-align: middle !important;
}
</style>
