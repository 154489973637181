<template>
  <div>
    <h3 class="font-weight-bold mb-2 mt-2">Plano de Trabalho</h3>
      <Tab :tab="tab" />

      <div>
        <b-form @submit="onSubmit" v-if="show">
          <b-card v-if="tab == 1" class="mt-3" style="padding: 15px; margin-bottom: 10px;" :header="tab == 1 ? '1. Dados Cadastrais/Convenente' : tab == 2 ? '2. Dados Bancários' : tab == 3 ? '3. Dados Cadastrais/Concedente' : tab == 4 ? '4. Descrição do Projeto' : tab == 5 ? '5. Itens' : tab == 6 ? '6. Plano de Aplicação' : tab == 7 ? '7. Cronograma de Desembolso' : 'Assinatura'">
            <div style="margin-top: 20px;"></div>

            <b-card class="mt-3" style="padding: 15px; margin-bottom: 10px;" header="Dados do Órgão">
              <div style="margin-top: 20px;"></div>

              <div style="display: flex; flex-direction: row;">
                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-1" label="Órgão / Entidade Convenente*:" label-for="input-1">
                  <b-form-input
                    id="input-1"
                    v-model="form.cadastrais_proponente.orgao"
                    placeholder="Insira o nome da entidade"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group :state="form.cadastrais_proponente.cnpj != '' && form.cadastrais_proponente.cnpj.length == 18 && stateCNPJ" :invalid-feedback="form.cadastrais_proponente.cnpj != '' && form.cadastrais_proponente.cnpj.length == 18 && invalidFeedbackCNPJ" style="width: 90%;" id="input-group-2" label="CNPJ*:" label-for="input-cnpj-2">
                  <b-form-input
                    :state="form.cadastrais_proponente.cnpj != '' && form.cadastrais_proponente.cnpj.length == 18 ? stateCNPJ : null" trim
                    id="input-cnpj-2"
                    v-mask="'##.###.###/####-##'"
                    v-model="form.cadastrais_proponente.cnpj"
                    minlength="18"
                    maxlength="18"
                    placeholder="Insira o número do CNPJ"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div style="display: flex; flex-direction: row;">
                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-3" label="Endereço*:" label-for="input-3">
                  <b-form-input
                    id="input-3"
                    v-model="form.cadastrais_proponente.endereco"
                    placeholder="Insira o nome do endereço"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-3" label="Bairro*:" label-for="input-3">
                  <b-form-input
                    id="input-3"
                    v-model="form.cadastrais_proponente.bairro"
                    placeholder="Insira o bairro"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%;" id="input-group-3" label="Número*:" label-for="input-3">
                  <b-input
                    id="input-3"
                    type="number"
                    v-model="form.cadastrais_proponente.numero"
                    placeholder="Insira o número do endereço"
                    required
                  ></b-input>
                </b-form-group>
              </div>

              <div style="display: flex; flex-direction: row;">
                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-4" label="Cidade*:" label-for="input-4">
                  <b-form-input
                    id="input-4"
                    v-model="form.cadastrais_proponente.cidade"
                    placeholder="Insira o nome da cidade"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-5" label="UF*:" label-for="input-5">
                  <b-form-input
                    id="input-5"
                    v-model="form.cadastrais_proponente.estado"
                    placeholder="Insira o UF"
                    minlength="2"
                    maxlength="2"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-5" label="CEP*:" label-for="input-5">
                  <b-form-input
                    id="input-5"
                    v-model="form.cadastrais_proponente.cep"
                    v-mask="'#####-###'"
                    placeholder="Insira o CEP"
                    minlength="9"
                    maxlength="9"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%;" id="input-group-3" label="Esfera Administrativa*:" label-for="input-3">
                  <b-form-select
                    id="input-3"
                    v-model="form.cadastrais_proponente.esfera"
                    :options="esferas"
                    required
                  ></b-form-select>
                </b-form-group>
              </div>

              <div style="display: flex; flex-direction: row;">
                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-1" label="Telefone*:" label-for="input-1">
                  <b-form-input
                    id="input-1"
                    v-mask="'(##) # ####-####'"
                    v-model="form.cadastrais_proponente.telefone"
                    placeholder="Insira o número de telefone"
                    minlength="15"
                    maxlength="16"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%;" id="input-group-2" label="E-mail*:" label-for="input-2">
                  <b-form-input
                    id="input-2"
                    v-model="form.cadastrais_proponente.email"
                    type="email"
                    placeholder="Insira o E-mail"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
            </b-card>


            <b-card class="mt-3" style="padding: 15px; margin-bottom: 10px;" header="Dados do Responsável">
              <div style="margin-top: 20px;"></div>

              <div style="display: flex; flex-direction: row;">
                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-1" label="Nome Completo*:" label-for="input-1">
                  <b-form-input
                    id="input-1"
                    v-model="form.cadastrais_proponente.nome_completo_titular_responsavel"
                    placeholder="Insira o nome do responsável"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group :state="form.cadastrais_proponente.cpf != '' && form.cadastrais_proponente.cpf.length == 14 && stateCPF" :invalid-feedback="form.cadastrais_proponente.cpf != '' && form.cadastrais_proponente.cpf.length == 14 && invalidFeedbackCPF" style="width: 90%; margin-right: 10px;" id="input-group-2" label="CPF*:" label-for="input-cpf-2">
                  <b-form-input
                    :state="form.cadastrais_proponente.cpf != '' && form.cadastrais_proponente.cpf.length == 14 ? stateCPF : null" trim
                    id="input-cpf-2"
                    v-mask="'###.###.###-##'"
                    v-model="form.cadastrais_proponente.cpf"
                    placeholder="Insira o número do CPF"
                    minlength="14"
                    maxlength="14"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%;" id="input-group-2" label="RG*:" label-for="input-2">
                  <b-form-input
                    id="input-2"
                    type="text"
                    v-mask="'NNNNNNNNNNN'"
                    v-model="form.cadastrais_proponente.rg"
                    placeholder="Insira o número do RG"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div style="display: flex; flex-direction: row;">
                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-1" label="Cargo*:" label-for="input-1">
                  <b-form-input
                    id="input-1"
                    v-model="form.cadastrais_proponente.cargo"
                    placeholder="Insira o nome do cargo"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-2" label="Decreto*:" label-for="input-2">
                  <b-form-input
                    id="input-2"
                    v-model="form.cadastrais_proponente.decreto"
                    placeholder="Insira o nome do decreto"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-2" label="E-mail Institucional*:" label-for="input-2">
                  <b-form-input
                    id="input-2"
                    type="email"
                    v-model="form.cadastrais_proponente.email_institucional"
                    placeholder="Insira o E-mail"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div style="display: flex; flex-direction: row;">
                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-3" label="Endereço*:" label-for="input-3">
                  <b-form-input
                    id="input-3"
                    v-model="form.cadastrais_proponente.endereco_responsavel"
                    placeholder="Insira o nome do endereço"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-3" label="Bairro*:" label-for="input-3">
                  <b-form-input
                    id="input-3"
                    v-model="form.cadastrais_proponente.bairro_responsavel"
                    placeholder="Insira o bairro"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%;" id="input-group-3" label="Número*:" label-for="input-3">
                  <b-form-input
                    id="input-3"
                    type="number"
                    v-model="form.cadastrais_proponente.numero_responsavel"
                    placeholder="Insira o número do endereço"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div style="display: flex; flex-direction: row;">
                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-4" label="Cidade*:" label-for="input-4">
                  <b-form-input
                    id="input-4"
                    v-model="form.cadastrais_proponente.cidade_responsavel"
                    placeholder="Insira o nome da cidade"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-5" label="UF*:" label-for="input-5">
                  <b-form-input
                    id="input-5"
                    v-model="form.cadastrais_proponente.estado_responsavel"
                    placeholder="Insira o UF"
                    minlength="2"
                    maxlength="2"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-5" label="CEP*:" label-for="input-5">
                  <b-form-input
                    id="input-5"
                    v-model="form.cadastrais_proponente.cep_responsavel"
                    placeholder="Insira o CEP"
                    minlength="9"
                    maxlength="9"
                    v-mask="'#####-###'"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
            </b-card>
          </b-card>

          <b-card v-if="tab == 2" class="mt-3" style="padding: 15px; margin-bottom: 10px;" :header="tab == 1 ? '1. Dados Cadastrais/Convenente' : tab == 2 ? '2. Dados Bancários' : tab == 3 ? '3. Dados Cadastrais/Concedente' : tab == 4 ? '4. Descrição do Projeto' : tab == 5 ? '5. Itens' : tab == 6 ? '6. Plano de Aplicação' : tab == 7 ? '7. Cronograma de Desembolso' : 'Assinatura'">
            <div style="margin-top: 20px;"></div>

            <div style="display: flex; flex-direction: row;">
              <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-1" label="Banco*:" label-for="input-1">
                <b-form-input
                  id="input-1"
                  v-model="form.bancarios.banco"
                  placeholder="Insira o nome do banco"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-1" label="Agência*:" label-for="input-1">
                <b-form-input
                  id="input-1"
                  type="number"
                  v-model="form.bancarios.agencia"
                  placeholder="Insira o número da agência"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-1" label="Conta Corrente*:" label-for="input-1">
                <b-form-input
                  id="input-1"
                  v-model="form.bancarios.conta_corrente"
                  type="number"
                  placeholder="Insira o número da conta corrente"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-1" label="Praça de Pagamento*:" label-for="input-1">
                <b-form-input
                  id="input-1"
                  v-model="form.bancarios.praca_pagamento"
                  placeholder="Insira o nome da praça de pagamento"
                  required
                ></b-form-input>
              </b-form-group>
            </div>
          </b-card>

          <b-card v-if="tab == 3" class="mt-3" style="padding: 15px; margin-bottom: 10px;" :header="tab == 1 ? '1. Dados Cadastrais/Convenente' : tab == 2 ? '2. Dados Bancários' : tab == 3 ? '3. Dados Cadastrais/Concedente' : tab == 4 ? '4. Descrição do Projeto' : tab == 5 ? '5. Itens' : tab == 6 ? '6. Plano de Aplicação' : tab == 7 ? '7. Cronograma de Desembolso' : 'Assinatura'">
            <div style="margin-top: 20px;"></div>

            <b-card class="mt-3" style="padding: 15px; margin-bottom: 10px;" header="Dados do Órgão">
              <div style="margin-top: 20px;"></div>

              <div style="display: flex; flex-direction: row;">
                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-1" label="Órgão / Entidade Convenente*:" label-for="input-1">
                  <b-form-input
                    id="input-1"
                    v-model="form.cadastrais_concedente.orgao"
                    placeholder="Insira o nome da entidade"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group :state="form.cadastrais_concedente.cnpj != '' && form.cadastrais_concedente.cnpj.length == 18 && stateCNPJ2" :invalid-feedback="form.cadastrais_concedente.cnpj != '' && form.cadastrais_concedente.cnpj.length == 18 && invalidFeedbackCNPJ2" style="width: 90%;" id="input-group-2" label="CNPJ*:" label-for="input-cnpj-3">
                  <b-form-input
                    :state="form.cadastrais_concedente.cnpj != '' && form.cadastrais_concedente.cnpj.length == 18 ? stateCNPJ2 : null" trim
                    id="input-cnpj-3"
                    v-mask="'##.###.###/####-##'"
                    v-model="form.cadastrais_concedente.cnpj"
                    minlength="18"
                    maxlength="18"
                    placeholder="Insira o número do CNPJ"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div style="display: flex; flex-direction: row;">
                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-3" label="Endereço*:" label-for="input-3">
                  <b-form-input
                    id="input-3"
                    v-model="form.cadastrais_concedente.endereco"
                    placeholder="Insira o nome do endereço"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-3" label="Bairro*:" label-for="input-3">
                  <b-form-input
                    id="input-3"
                    v-model="form.cadastrais_concedente.bairro"
                    placeholder="Insira o bairro"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%;" id="input-group-3" label="Número*:" label-for="input-3">
                  <b-form-input
                    id="input-3"
                    type="number"
                    v-model="form.cadastrais_concedente.numero"
                    placeholder="Insira o número do endereço"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div style="display: flex; flex-direction: row;">
                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-4" label="Cidade*:" label-for="input-4">
                  <b-form-input
                    id="input-4"
                    v-model="form.cadastrais_concedente.cidade"
                    placeholder="Insira o nome da cidade"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-5" label="UF*:" label-for="input-5">
                  <b-form-input
                    id="input-5"
                    v-model="form.cadastrais_concedente.estado"
                    placeholder="Insira o UF"
                    minlength="2"
                    maxlength="2"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-5" label="CEP*:" label-for="input-5">
                  <b-form-input
                    id="input-5"
                    v-model="form.cadastrais_concedente.cep"
                    v-mask="'#####-###'"
                    placeholder="Insira o CEP"
                    minlength="9"
                    maxlength="9"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%;" id="input-group-3" label="Esfera Administrativa*:" label-for="input-3">
                  <b-form-select
                    id="input-3"
                    v-model="form.cadastrais_concedente.esfera"
                    :options="esferas"
                    required
                  ></b-form-select>
                </b-form-group>
              </div>

              <div style="display: flex; flex-direction: row;">
                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-1" label="Telefone*:" label-for="input-1">
                  <b-form-input
                    id="input-1"
                    v-mask="'(##) # ####-####'"
                    v-model="form.cadastrais_concedente.telefone"
                    placeholder="Insira o número de telefone"
                    minlength="15"
                    maxlength="16"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%;" id="input-group-2" label="E-mail*:" label-for="input-2">
                  <b-form-input
                    id="input-2"
                    v-model="form.cadastrais_concedente.email"
                    type="email"
                    placeholder="Insira o E-mail"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
            </b-card>


            <b-card class="mt-3" style="padding: 15px; margin-bottom: 10px;" header="Dados do Responsável">
              <div style="margin-top: 20px;"></div>

              <div style="display: flex; flex-direction: row;">
                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-1" label="Nome Completo*:" label-for="input-1">
                  <b-form-input
                    id="input-1"
                    v-model="form.cadastrais_concedente.nome_completo_titular_responsavel"
                    placeholder="Insira o nome do responsável"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group :state="form.cadastrais_concedente.cpf != '' && form.cadastrais_concedente.cpf.length == 14 && stateCPF2" :invalid-feedback="form.cadastrais_concedente.cpf != '' && form.cadastrais_concedente.cpf.length == 14 && invalidFeedbackCPF2" style="width: 90%; margin-right: 10px;" id="input-group-2" label="CPF*:" label-for="input-cpf-3">
                  <b-form-input
                    :state="form.cadastrais_concedente.cpf != '' && form.cadastrais_concedente.cpf.length == 14 ? stateCPF2 : null" trim
                    id="input-cpf-3"
                    v-mask="'###.###.###-##'"
                    v-model="form.cadastrais_concedente.cpf"
                    placeholder="Insira o número do CPF"
                    minlength="14"
                    maxlength="14"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%;" id="input-group-2" label="RG*:" label-for="input-2">
                  <b-form-input
                    id="input-2"
                    type="text"
                    v-mask="'NNNNNNNNNNN'"
                    v-model="form.cadastrais_concedente.rg"
                    placeholder="Insira o número do RG"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div style="display: flex; flex-direction: row;">
                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-1" label="Cargo*:" label-for="input-1">
                  <b-form-input
                    id="input-1"
                    v-model="form.cadastrais_concedente.cargo"
                    placeholder="Insira o nome do cargo"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-2" label="Decreto*:" label-for="input-2">
                  <b-form-input
                    id="input-2"
                    v-model="form.cadastrais_concedente.decreto"
                    placeholder="Insira o nome do decreto"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-2" label="E-mail Institucional*:" label-for="input-2">
                  <b-form-input
                    id="input-2"
                    type="email"
                    v-model="form.cadastrais_concedente.email_institucional"
                    placeholder="Insira o E-mail"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div style="display: flex; flex-direction: row;">
                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-3" label="Endereço*:" label-for="input-3">
                  <b-form-input
                    id="input-3"
                    v-model="form.cadastrais_concedente.endereco_responsavel"
                    placeholder="Insira o nome do endereço"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-3" label="Bairro*:" label-for="input-3">
                  <b-form-input
                    id="input-3"
                    v-model="form.cadastrais_concedente.bairro_responsavel"
                    placeholder="Insira o bairro"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%;" id="input-group-3" label="Número*:" label-for="input-3">
                  <b-form-input
                    id="input-3"
                    type="number"
                    v-model="form.cadastrais_concedente.numero_responsavel"
                    placeholder="Insira o número do endereço"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div style="display: flex; flex-direction: row;">
                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-4" label="Cidade*:" label-for="input-4">
                  <b-form-input
                    id="input-4"
                    v-model="form.cadastrais_concedente.cidade_responsavel"
                    placeholder="Insira o nome da cidade"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-5" label="UF*:" label-for="input-5">
                  <b-form-input
                    id="input-5"
                    v-model="form.cadastrais_concedente.estado_responsavel"
                    placeholder="Insira o UF"
                    minlength="2"
                    maxlength="2"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-5" label="CEP*:" label-for="input-5">
                  <b-form-input
                    id="input-5"
                    v-model="form.cadastrais_concedente.cep_responsavel"
                    placeholder="Insira o CEP"
                    minlength="9"
                    maxlength="9"
                    v-mask="'#####-###'"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
            </b-card>
          </b-card>

          <b-card v-if="tab == 4" class="mt-3" style="padding: 15px; margin-bottom: 10px;" :header="tab == 1 ? '1. Dados Cadastrais/Convenente' : tab == 2 ? '2. Dados Bancários' : tab == 3 ? '3. Dados Cadastrais/Concedente' : tab == 4 ? '4. Descrição do Projeto' : tab == 5 ? '5. Itens' : tab == 6 ? '6. Plano de Aplicação' : tab == 7 ? '7. Cronograma de Desembolso' : 'Assinatura'">
            <div style="margin-top: 20px;"></div>

            <div style="display: flex; flex-direction: row;">
              <b-form-group style="width: 100%;" id="input-group-20" label="Objeto do Convênio*:" label-for="input-2">
                <b-form-textarea
                  id="input-20"
                  required
                  v-model="form.projeto.objeto_convenio"
                  max-rows="6"
                  placeholder="Objeto do Convênio"
                  rows="3"
                ></b-form-textarea>
              </b-form-group>
            </div>

            <div style="display: flex; flex-direction: row;">
              <b-form-group style="width: 100%;" id="input-group-20" label="Identificação do Objeto*:" label-for="input-2">
                <b-form-textarea
                  id="input-20"
                  required
                  v-model="form.projeto.identificao_objeto"
                  max-rows="6"
                  placeholder="Identificação do Objeto"
                  rows="3"
                ></b-form-textarea>
              </b-form-group>
            </div>

            <div style="display: flex; flex-direction: row;">
              <b-form-group style="width: 90%; margin-right: 10px;" id="input-group-1" label="Período de Execução (início)*:" label-for="input-1">
                <b-form-input
                  id="input-1"
                  v-model="form.projeto.periodo_inicial"
                  placeholder="Período de Execução (início)"
                  type="date"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group style="width: 90%;" id="input-group-1" label="Período de Execução (término)*:" label-for="input-1">
                <b-form-input
                  id="input-1"
                  v-model="form.projeto.periodo_final"
                  placeholder="Período de Execução (término)"
                  type="date"
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <div style="display: flex; flex-direction: row;">
              <b-form-group style="width: 100%;" id="input-group-20" label="Justificativa do Projeto*:" label-for="input-2">
                <b-form-textarea
                  id="input-20"
                  required
                  v-model="form.projeto.justificativa"
                  max-rows="6"
                  placeholder="Justificativa do Projeto"
                  rows="3"
                ></b-form-textarea>
              </b-form-group>
            </div>
          </b-card>

          <b-card v-if="tab == 5" class="mt-3" style="padding: 15px; margin-bottom: 10px;" :header="tab == 1 ? '1. Dados Cadastrais/Convenente' : tab == 2 ? '2. Dados Bancários' : tab == 3 ? '3. Dados Cadastrais/Concedente' : tab == 4 ? '4. Descrição do Projeto' : tab == 5 ? '5. Itens' : tab == 6 ? '6. Plano de Aplicação' : tab == 7 ? '7. Cronograma de Desembolso' : 'Assinatura'">
            <div style="margin-top: 20px;"></div>

            <Itens :form="form" :setForm="setForm" :tipo_tabela="tipo_tabela_itens" :set_tipo_tabela="set_tipo_tabela" />
          </b-card>

          <b-card v-if="tab == 6" class="mt-3" style="padding: 15px; margin-bottom: 10px;" :header="tab == 1 ? '1. Dados Cadastrais/Convenente' : tab == 2 ? '2. Dados Bancários' : tab == 3 ? '3. Dados Cadastrais/Concedente' : tab == 4 ? '4. Descrição do Projeto' : tab == 5 ? '5. Itens' : tab == 6 ? '6. Plano de Aplicação' : tab == 7 ? '7. Cronograma de Desembolso' : 'Assinatura'">
            <div style="margin-top: 20px;"></div>

            <PlanoDeAplicacao :form="form" :setForm="setForm" />
          </b-card>

          <b-card v-if="tab == 7" class="mt-3" style="padding: 15px; margin-bottom: 10px;" :header="tab == 1 ? '1. Dados Cadastrais/Convenente' : tab == 2 ? '2. Dados Bancários' : tab == 3 ? '3. Dados Cadastrais/Concedente' : tab == 4 ? '4. Descrição do Projeto' : tab == 5 ? '5. Itens' : tab == 6 ? '6. Plano de Aplicação' : tab == 7 ? '7. Cronograma de Desembolso' : 'Assinatura'">
            <div style="margin-top: 20px;"></div>

            <Cronograma :form="form" :setForm="setForm" />
          </b-card>

          <b-card v-if="tab == 8" class="mt-3" style="padding: 15px; margin-bottom: 10px;" :header="tab == 1 ? '1. Dados Cadastrais/Convenente' : tab == 2 ? '2. Dados Bancários' : tab == 3 ? '3. Dados Cadastrais/Concedente' : tab == 4 ? '4. Descrição do Projeto' : tab == 5 ? '5. Itens' : tab == 6 ? '6. Plano de Aplicação' : tab == 7 ? '7. Cronograma de Desembolso' : 'Assinatura'">
            <Assinatura :form="form" :setForm="setForm" :processo="processo" />
          </b-card>

          <div class="d-flex flex-row items-center justify-content-end mb-5" style="margin-top: 40px;">
            <b-button v-if="tab > 1 && tab <= 8" class="mr-2" type="button" variant="outline-secondary" @click="backTab">Voltar</b-button>
            <b-button v-if="tab == 1" class="mr-2" variant="outline-secondary" type="button" @click="togglePlanoTrabalho">Voltar</b-button>

            <b-button v-if="tab != 8" type="submit" variant="info">Prosseguir</b-button>
            <b-button :disabled="loading" v-if="tab == 8" type="submit" variant="info">Enviar</b-button>
          </div>
        </b-form>
      </div>
  </div>
</template>

<script>
import { cnpj, cpf } from 'cpf-cnpj-validator';
import moment from 'moment'

import Itens from '@/components/PlanoTrabalho/components/Itens.vue';
import PlanoDeAplicacao from '@/components/PlanoTrabalho/components/PlanoDeAplicacao.vue';
import Cronograma from '@/components/PlanoTrabalho/components/Cronograma.vue';
import Assinatura from '@/components/PlanoTrabalho/components/Assinatura.vue';
import Tab from '@/components/PlanoTrabalho/components/Tab.vue';

export default {
  components: { PlanoDeAplicacao, Itens, Cronograma, Assinatura, Tab },
  computed: {
    stateCNPJ() {
      return cnpj.isValid(String(this.form.cadastrais_proponente.cnpj).replace(/\D/g, ""))
    },
    invalidFeedbackCNPJ() {
      if (!cnpj.isValid(String(this.form.cadastrais_proponente.cnpj).replace(/\D/g, ""))) {
        return 'Insira um CNPJ válido.'
      }
      return 'Por favor, insira algo.'
    },
    stateCPF() {
      return cpf.isValid(String(this.form.cadastrais_proponente.cpf).replace(/\D/g, ""))
    },
    invalidFeedbackCPF() {
      if (!cpf.isValid(String(this.form.cadastrais_proponente.cpf).replace(/\D/g, ""))) {
        return 'Insira um CPF válido.'
      }
      return 'Por favor, insira algo.'
    },

    stateCNPJ2() {
      return cnpj.isValid(String(this.form.cadastrais_concedente.cnpj).replace(/\D/g, ""))
    },
    invalidFeedbackCNPJ2() {
      if (!cnpj.isValid(String(this.form.cadastrais_concedente.cnpj).replace(/\D/g, ""))) {
        return 'Insira um CNPJ válido.'
      }
      return 'Por favor, insira algo.'
    },
    stateCPF2() {
      return cpf.isValid(String(this.form.cadastrais_concedente.cpf).replace(/\D/g, ""))
    },
    invalidFeedbackCPF2() {
      if (!cpf.isValid(String(this.form.cadastrais_concedente.cpf).replace(/\D/g, ""))) {
        return 'Insira um CPF válido.'
      }
      return 'Por favor, insira algo.'
    },
  },
  data() {
    return {
      form: process.env.VUE_APP_DEV ? {
        cadastrais_proponente: {
          orgao: 'PREFEITURA MUNICIPAL DE LARANJAL DO JARI',
          esfera: 'Municipal' || null,
          cnpj: '23.066.905/0001-60',
          cep: '68920-000',
          bairro: 'AGRESTE',
          endereco: 'AVENIDA TANCREDO NEVES',
          numero: '999',
          cidade: 'LARANJAL DO JARI',
          estado: 'AP',
          telefone: '(99) 9 9999-9999',
          email: 'prefeituralaranjaljari@gmail.com',
          nome_completo_titular_responsavel: 'SDC',
          cpf: '043.045.000-15',
          rg: '84162',
          cargo: 'PREFEITO',
          decreto: 'DECRETO MUNICIPAL',
          cep_responsavel: '68977-888',
          bairro_responsavel: 'CENTRO',
          endereco_responsavel: 'RUA ELIZER LEVY',
          numero_responsavel: '2353',
          cidade_responsavel: 'MACAPÁ',
          estado_responsavel: 'AP',
          email_institucional: 'sdc@gmail.com'
        },
        bancarios: {
          banco: 'BRADESCO',
          agencia: '9858',
          conta_corrente: '34085',
          praca_pagamento: 'MACAPÁ'
        },
        cadastrais_concedente: {
          orgao: 'PREFEITURA MUNICIPAL DE LARANJAL DO JARI',
          esfera: 'Municipal' || null,
          cnpj: '23.066.905/0001-60',
          cep: '68920-000',
          bairro: 'AGRESTE',
          endereco: 'AVENIDA TANCREDO NEVES',
          numero: '999',
          cidade: 'LARANJAL DO JARI',
          estado: 'AP',
          telefone: '(99) 9 9999-9999',
          email: 'prefeituralaranjaljari@gmail.com',
          nome_completo_titular_responsavel: 'SDC',
          cpf: '043.045.000-15',
          rg: '84162',
          cargo: 'PREFEITO',
          decreto: 'DECRETO MUNICIPAL',
          cep_responsavel: '68977-888',
          bairro_responsavel: 'CENTRO',
          endereco_responsavel: 'RUA ELIZER LEVY',
          numero_responsavel: '2353',
          cidade_responsavel: 'MACAPÁ',
          estado_responsavel: 'AP',
          email_institucional: 'sdc@gmail.com'
        },
        projeto: {
          objeto_convenio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et augue mollis, varius nibh a, vulputate nisi. Nullam viverra est ligula, a rhoncus mi interdum id. Pellentesque sodales turpis nec nibh iaculis, et fermentum velit hendrerit. Sed nec mattis enim, sed lacinia urna. Vestibulum libero arcu, interdum a dictum vel, vulputate at lectus.',
          identificao_objeto: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et augue mollis, varius nibh a, vulputate nisi. Nullam viverra est ligula, a rhoncus mi interdum id. Pellentesque sodales turpis nec nibh iaculis, et fermentum velit hendrerit. Sed nec mattis enim, sed lacinia urna. Vestibulum libero arcu, interdum a dictum vel, vulputate at lectus.',
          periodo_inicial: '2024-06-01',
          periodo_final: '2024-12-31',
          justificativa: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et augue mollis, varius nibh a, vulputate nisi. Nullam viverra est ligula, a rhoncus mi interdum id. Pellentesque sodales turpis nec nibh iaculis, et fermentum velit hendrerit. Sed nec mattis enim, sed lacinia urna. Vestibulum libero arcu, interdum a dictum vel, vulputate at lectus.'
        },
        itens: [{
          id: '1711131974634',
          item: 'SERVIÇOS PRELIMINARES',
          total: 'R$ 26.324,34',
          periodo_inicial: '2024-06-01',
          periodo_final: '2024-12-31',
          open: false,
          editar: false,
          subitens: [
            {
              index: 0,
              id: '1711131991671',
              sinap: '74209/001',
              descricao: 'PLACA DE OBRA EM CHAPA DE AÇO GALVANIZADO',
              unidade: 'Área',
              quantidade: 18,
              valor_unitario: 'R$ 366,33',
              total: 'R$ 6.593,94',
              editar: false,
              item: {
                id: '1711131974634',
                item: 'SERVIÇOS PRELIMINARES',
                total: 'R$ 26.324,34',
                periodo_inicial: '2024-06-01',
                periodo_final: '2024-12-31',
                open: false,
                editar: false,
              }
            }
          ]
        }],
        planoDeAplicacao: [{
          especificacao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vehicula est vel urna vestibulum lobortis. Pellentesque id nisi sodales, rutrum lorem a, pretium nisi. Pellentesque tincidunt risus et hendrerit hendrerit. Nunc rutrum hendrerit eros non sagittis. Integer ultricies sodales magna, ut pretium nunc feugiat non.',
          concedente: 'R$ 500,00',
          convenente: 'R$ 500,00'
        }],
        cronograma: [{
          id: "1712767499500",
          open: false,
          editar: false,
          concedente: "R$ 1.000",
          convenente: "R$ 1.000",
          periodo_inicial: "2024-01-01",
          periodo_final: "2025-01-01",
          itens: []
        }]
      } : {
        cadastrais_proponente: {
          orgao: '',
          esfera: null,
          cnpj: '',
          cep: '',
          bairro: '',
          endereco: '',
          numero: '',
          cidade: '',
          estado: '',
          telefone: '',
          email: '',
          nome_completo_titular_responsavel: '',
          cpf: '',
          rg: '',
          cargo: '',
          decreto: '',
          cep_responsavel: '',
          bairro_responsavel: '',
          endereco_responsavel: '',
          numero_responsavel: '',
          cidade_responsavel: '',
          estado_responsavel: '',
          email_institucional: ''
        },
        bancarios: {
          banco: '',
          agencia: '',
          conta_corrente: '',
          praca_pagamento: ''
        },
        cadastrais_concedente: {
          orgao: '',
          esfera: null,
          cnpj: '',
          cep: '',
          bairro: '',
          endereco: '',
          numero: '',
          cidade: '',
          estado: '',
          telefone: '',
          email: '',
          nome_completo_titular_responsavel: '',
          cpf: '',
          rg: '',
          cargo: '',
          decreto: '',
          cep_responsavel: '',
          bairro_responsavel: '',
          endereco_responsavel: '',
          numero_responsavel: '',
          cidade_responsavel: '',
          estado_responsavel: '',
          email_institucional: ''
        },
        projeto: {
          objeto_convenio: '',
          identificao_objeto: '',
          periodo_inicial: '',
          periodo_final: '',
          justificativa: ''
        },
        itens: [],
        planoDeAplicacao: [],
        cronograma: []
      },

      esferas: [{ text: 'Selecione', value: null }, 'Municipal', 'Estadual'],
      tipo_tabela_itens: process.env.VUE_APP_DEV ? 'Serviço' : null,

      show: true,
      tab: 1
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()

      if(this.tab == 1){
        if (!cnpj.isValid(String(this.form.cadastrais_proponente.cnpj).replace(/\D/g, ""))) {
          document.getElementById("input-cnpj-2").focus();
          return 'Insira um CNPJ válido.'
        }

        if (!cpf.isValid(String(this.form.cadastrais_proponente.cpf).replace(/\D/g, ""))) {
          document.getElementById("input-cpf-2").focus();
          return 'Insira um CPF válido.'
        }
      }

      if(this.tab == 3){
        if (!cnpj.isValid(String(this.form.cadastrais_concedente.cnpj).replace(/\D/g, ""))) {
          document.getElementById("input-cnpj-3").focus();
          return 'Insira um CNPJ válido.'
        }

        if (!cpf.isValid(String(this.form.cadastrais_concedente.cpf).replace(/\D/g, ""))) {
          document.getElementById("input-cpf-3").focus();
          return 'Insira um CPF válido.'
        }
      }

      if(this.tab == 8){
       this.handleSubmit(this.form)
      } else if(this.tab == 4){
        if(moment(this.form.projeto.periodo_inicial).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          }).isBefore(moment().set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
          }))){
          this.$swal({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            title: 'Campos obrigatórios',
            text: 'Por favor, insira corretamente o período inicial',
            icon: 'error'
          });

          return
        }

        if(moment(this.form.projeto.periodo_final).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }).isBefore(moment(this.form.projeto.periodo_inicial).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
        }))){
          this.$swal({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            title: 'Campos obrigatórios',
            text: 'Por favor, insira corretamente os períodos',
            icon: 'error'
          });

          return
        }

        this.addTab()
      } else if(this.tab == 5){
        if(this.form.itens.length == 0){
          this.$swal({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            title: 'Campos obrigatórios',
            text: 'Por favor, insira pelo menos 1 item',
            icon: 'error'
          });
        }else{
          this.addTab()
        }
      }else if(this.tab == 6){
        if(this.form.planoDeAplicacao.length == 0){
          this.$swal({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            title: 'Campos obrigatórios',
            text: 'Por favor, insira pelo menos 1 plano de aplicação',
            icon: 'error'
          });
        }else{
          this.addTab()
        }
      }else if(this.tab == 7){
        if(this.form.cronograma.length == 0){
          this.$swal({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            title: 'Campos obrigatórios',
            text: 'Por favor, insira pelo menos 1 parcela',
            icon: 'error'
          });
        }else{
          this.addTab()
        }
      } else {
        this.addTab()
      }

      window.scrollTo(0,0)
    },
    backTab() {
      this.tab = Number(this.tab) - 1;
      window.scrollTo(0,0)
    },
    addTab() {
      this.tab = Number(this.tab) + 1;
      window.scrollTo(0,0)
    },
    setForm(form) {
      this.form = form
    },
    set_tipo_tabela(tipo){
      this.tipo_tabela_itens = tipo
    }
  },
  props: {
    plano_trabalho: {
      type: Object,
    },
    submitForm: {
      type: Function
    },
    togglePlanoTrabalho: {
      type: Function
    },
    handleSubmit: {
      type: Function
    },
    processo: {
      type: Object
    },
    loading: {
      type: Boolean
    }
  },
  mounted(){
    try {
      const last_plano = this.processo.planos_de_trabalho.sort((a,b) => Date.parse(a.created_at) - Date.parse(b.created_at)).slice(-1)[0]

      if(last_plano){
        this.form = {
          cadastrais_proponente: {
            orgao: last_plano.orgao_convenente,
            esfera: last_plano.esfera_administrativa_convenente,
            cnpj: last_plano.cnpj_convenente,
            cep: last_plano.cep_convenente,
            bairro: last_plano.bairro_convenente,
            endereco: last_plano.rua_av_convenente,
            numero: last_plano.numero_convenente,
            cidade: last_plano.cidade_convenente,
            estado: last_plano.estado_convenente,
            telefone: last_plano.telefone_convenente,
            email: last_plano.email_convenente,
            nome_completo_titular_responsavel: last_plano.titular_convenente,
            cpf: last_plano.cpf_convenente,
            rg: last_plano.rg_convenente,
            cargo: last_plano.cargo_convenente,
            decreto: last_plano.decreto_convenente,
            cep_responsavel: last_plano.cep_titular_convenente,
            bairro_responsavel: last_plano.bairro_titular_convenente,
            endereco_responsavel: last_plano.rua_av_titular_convenente,
            numero_responsavel: last_plano.numero_titular_convenente,
            cidade_responsavel: last_plano.cidade_titular_convenente,
            estado_responsavel: last_plano.estado_titular_convenente,
            email_institucional: last_plano.email_titular_convenente
          },
          bancarios: {
            banco: last_plano.banco,
            agencia: last_plano.agencia,
            conta_corrente: last_plano.conta_corrente,
            praca_pagamento: last_plano.praca_pagamento
          },
          cadastrais_concedente: {
            orgao: last_plano.orgao_concedente,
            esfera: last_plano.esfera_administrativa_concedente,
            cnpj: last_plano.cnpj_concedente,
            cep: last_plano.cep_concedente,
            bairro: last_plano.bairro_concedente,
            endereco: last_plano.rua_av_concedente,
            numero: last_plano.numero_concedente,
            cidade: last_plano.cidade_concedente,
            estado: last_plano.estado_concedente,
            telefone: last_plano.telefone_concedente,
            email: last_plano.email_concedente,
            nome_completo_titular_responsavel: last_plano.titular_concedente,
            cpf: last_plano.cpf_concedente,
            rg: last_plano.rg_concedente,
            cargo: last_plano.cargo_concedente,
            decreto: last_plano.decreto_concedente,
            cep_responsavel: last_plano.cep_titular_concedente,
            bairro_responsavel: last_plano.bairro_titular_concedente,
            endereco_responsavel: last_plano.rua_av_titular_concedente,
            numero_responsavel: last_plano.numero_titular_concedente,
            cidade_responsavel: last_plano.cidade_titular_concedente,
            estado_responsavel: last_plano.estado_titular_concedente,
            email_institucional: last_plano.email_titular_concedente
          },
          projeto: {
            objeto_convenio: last_plano.objeto_convenio,
            identificao_objeto: last_plano.identificacao_objeto,
            periodo_inicial: last_plano.execucao_inicio,
            periodo_final: last_plano.execucao_termino,
            justificativa: last_plano.justificativa
          },

          itens: last_plano.metas_etapas.itens,
          planoDeAplicacao: last_plano.plano_aplicacao.itens,
          cronograma: last_plano.cronograma_desembolso.itens,
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
};
</script>
