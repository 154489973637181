import Vue from 'vue';
import VueRouter from 'vue-router';
import httpClient from '@/plugins/axios';
import jwtDecode from 'jwt-decode';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import Dashboard from '@/views/Dashboard.vue';
import Usuario from '@/views/Usuario.vue';
import Perfil from '@/views/config/Perfil.vue';
import Eixos from '@/views/config/Eixos.vue';
import SubEixo from '@/views/config/SubEixo.vue';
import Setor from '@/views/config/Setor.vue';
import CheckList from '@/views/config/CheckList.vue';
import Prefeituras from '@/views/config/Prefeituras.vue';
import Administrativo from '@/views/fiscais/Administrativo.vue';
import GestorConvenio from '@/views/fiscais/GestorConvenio.vue';
import Tecnico from '@/views/fiscais/Tecnico.vue';
import ProcessosForm from '@/views/ProcessosForm.vue';
import Processos from '@/views/Processos.vue';

import ModalProcesso from '@/components/ModalProcesso';
import ModalReadonly from '@/components/readonly/ModalReadonly';

import Assinaturas from '@/views/Assinaturas.vue';
import SolicitarAssinaturaPlano from '@/components/PlanoTrabalho/SolicitarAssinaturaPlano.vue';
import VerificarAssinatura from '@/views/VerificarAssinatura.vue';
import Arquivados from '@/components/Arquivados.vue';
import ProcessosSearch from '@/views/ProcessosSearch.vue';
import ProcessosInativos from '@/views/ProcessosInativos.vue';
import Relatorios from '@/views/Relatorios.vue';
import Topics from '../views/config/Topics.vue';
import EsqueciSenha from '../views/EsqueciSenha.vue';
import RecuperarSenha from '../views/RecuperarSenha.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/usuario',
    name: 'Usuario',
    component: Usuario,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/perfil',
    name: 'Perfil',
    component: Perfil,
  },
  {
    path: '/eixos',
    name: 'Eixos',
    component: Eixos,
  },
  {
    path: '/eixos/:id',
    name: 'SubEixo',
    component: SubEixo,
  },
  {
    path: '/eixos/:id/:subid/checklist',
    name: 'CheckList',
    component: CheckList,
  },
  {
    path: '/eixos/:id/:subid/checklist/:checkid',
    name: 'Topics',
    component: Topics,
  },
  {
    path: '/prefeituras',
    name: 'Prefeituras',
    component: Prefeituras,
  },
  {
    path: '/setor',
    name: 'Setor',
    component: Setor,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/processo',
    name: 'Processo',
    component: ProcessosForm,
  },
  {
    path: '/processos/minha-unidade',
    name: 'Processos',
    component: Processos,
  },
  {
    path: '/processos/minha-unidade/detalhes',
    name: 'ModalProcesso',
    component: ModalProcesso,
    props: true,
  },
  {
    path: '/processos/detalhes',
    name: 'ModalReadonly',
    component: ModalReadonly,
    props: true,
  },
  {
    path: '/processos/busca',
    name: 'BuscaProcesso',
    component: ProcessosSearch,
  },
  {
    path: '/processos/inativos',
    name: 'ProcessosInativos',
    component: ProcessosInativos,
  },
  {
    path: '/assinaturas',
    name: 'Assinaturas',
    component: Assinaturas,
  },
  {
    path: '/verificar-assinatura',
    name: 'VerificarAssinatura',
    component: VerificarAssinatura,
    meta: { ignoreGuard: true },
  },
  {
    path: '/assinar-plano',
    name: 'SolicitarAssinaturaPlano',
    component: SolicitarAssinaturaPlano,
    meta: { ignoreGuard: true },
  },
  {
    path: '/esqueci-senha',
    name: 'EsqueciSenha',
    component: EsqueciSenha,
    meta: { ignoreGuard: true },
  },
  {
    path: '/resetar-senha/:token/:email',
    name: 'RecuperarSenha',
    component: RecuperarSenha,
    meta: { ignoreGuard: true },
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/fiscais-administrativos',
    name: 'FiscaisAdministrativo',
    component: Administrativo,
  },
  {
    path: '/gestores-convenio',
    name: 'GestorConvenio',
    component: GestorConvenio,
  },
  {
    path: '/fiscais-tecnicos',
    name: 'FiscaisTecnico',
    component: Tecnico,
  },
  {
    path: '/arquivados',
    name: 'Arquivados',
    component: Arquivados,
  },
  {
    path: '/relatorios',
    name: 'Relatorios',
    component: Relatorios,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta?.ignoreGuard) {
    next();
  } else {
    const token = localStorage.getItem('token');
    const auth = !!localStorage.getItem('user_id');
    const refreshTime = 30;

    if (!token || jwtDecode(token)?.exp < Date.now() / 1000) {
      localStorage.clear();
    } else if (jwtDecode(token)?.exp < Date.now() / 1000 + refreshTime * 60) {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      httpClient
        .post('auth/refresh', { headers })
        .then(({ data }) => {
          localStorage.setItem('token', data.access_token);
          next();
        })
        .catch(error => {
          console.error(error.response);
          next('/login');
        });
      return; // Return here to avoid proceeding to the next navigation step
    }

    if (to.path !== '/login' && to.path !== '/' && !auth) {
      next('/login');
    } else if ((to.path === '/login' || to.path === '/') && auth) {
      next('/processos/minha-unidade');
    } else {
      next();
    }
  }
});

export default router;
