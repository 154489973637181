<template>
  <div>
    <b-form>
      <b-form-group id="input-group-1" label="Número:" label-for="numero">
        <b-form-input
          id="numero"
          v-model="memorando.numero"
          placeholder="Mem. nº XXX/XXXX – GESTÃO DE CONVÊNIOS/SDC"
          required
          type="text"
        ></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-1" label="Assunto:" label-for="assunto">
        <b-form-input v-model="memorando.assunto" id="assunto" required type="text"></b-form-input>
      </b-form-group>
      <b-form-group label="Selecione o(s) Servidor(es):" required>
        <multiselect
          v-model="destinatarios"
          :close-on-select="true"
          :multiple="true"
          :options="servidores"
          :searchable="true"
          :show-labels="false"
          placeholder="Selecione..."
        ></multiselect>
      </b-form-group>
      <b-form-group label="Signatários:" required>
        <multiselect
          v-model="signatarios"
          :close-on-select="true"
          :multiple="true"
          :options="usuarios"
          :searchable="true"
          :show-labels="false"
          :taggable="true"
          placeholder="Selecione..."
        ></multiselect>
      </b-form-group>
      <b-form-group id="input-group-1">
        <vue-editor v-model="mensagem"></vue-editor>
      </b-form-group>

      <b-button variant="primary" @click="redigirDocumento()" class="px-5 py-2"
        >Salvar
      </b-button>
    </b-form>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },
  name: 'Memorando',
  data() {
    return {
      mensagem: '',
      memorando: {},
      destinatarios: [],
      servidores: [],
      editorToolbar: [
        ['bold', 'italic', 'underline'],
        [{ color: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: 'center' }, { align: 'justify' }, { align: 'right' }],
      ],
      usuarios: [],
      signatarios: [],
      usuariosIds: [],
    };
  },
  methods: {
    redigirDocumento() {
      let indexes = this.usuarios.map((value, index) => {
        if (this.signatarios.includes(value)) {
          return index;
        }
      });
      let signatariosIds = this.usuariosIds.filter((value, index) => {
        if (indexes.includes(index)) {
          return value;
        }
      });

      if(!this.mensagem || !this.memorando.numero || !this.memorando.assunto || this.destinatarios.length == 0 || signatariosIds.length == 0){
        this.$swal({
          title: 'Preencha todos os campos!',
          icon: 'warning',
        });

        return null
      }

      let data = {
        mensagem: this.mensagem,
        numero: this.memorando.numero,
        assunto: this.memorando.assunto,
        tipo_documentos_id: this.tipo_documentos_id,
        processo: this.processo,
        processo_id: this.processo.id,
        destinatarios: this.destinatarios,
        signatarios: signatariosIds,
      };

      this.$http.post('documentos-redigidos', data).catch(error => {
        console.log(error.response);
      });

      this.$swal({ title: 'Documento redigido com sucesso!', icon: 'success' });
      this.$emit('refreshProcesso');
      this.limparDados();
    },
    getServidoresSDC() {
      this.$http
        .get('servidores-sdc')
        .then(res => {
          res.data.forEach(servidor => {
            this.servidores.push(`${servidor.name} - ${servidor.setor.nome}`);
          });
        })
        .catch(error => {
          console.log(error.response);
        });
    },
    getUsuarios() {
      this.$http
        .get('servidores-sdc')
        .then(res => {
          res.data.forEach(user => {
            this.usuarios.push(user.name);
            this.usuariosIds.push(user.id);
          });
        })
        .catch(error => {
          console.log(error.response);
        });
    },
    limparDados() {
      this.mensagem = '';
      this.destinatarios = [];
      this.signatarios = [];
      this.assunto = [];
      this.numero = [];
    },
  },
  created() {
    this.getServidoresSDC();
    this.getUsuarios();
  },
  props: {
    tipo_documentos_id: {
      type: Number,
    },
    processo: {
      type: Object,
    },
  },
};
</script>
