<template>
  <b-container fluid style="color: #003e5d">
    <b-row class="text-center justify-content-center">
      <div class="col-12 mt-3 table-responsive">
        <table class="table table-striped table-hover">
          <tr style="color: #003e5d">
            <th>Data</th>
            <th>Setor</th>
            <th>Usuário</th>
            <th>Histórico</th>
          </tr>
          <tr v-for="historico in processo.historico" :key="historico.id">
            <td>{{ historico.created_at }}</td>
            <td>
              {{
                historico.setor !== null ? historico.setor.nome : 'Prefeitura'
              }}
            </td>
            <td>{{ historico.user.name }}</td>
            <td>
              {{ historico.descricao }}
              <template v-if="historico.descricao_detalhe">
                <label
                  v-b-tooltip.hover
                  :title="historico.descricao_detalhe"
                  class="btn btn-default"
                >
                  <b-icon
                    icon="chat-right-dots-fill"
                    variant="primary"
                  ></b-icon>
                </label>
              </template>
            </td>
          </tr>
        </table>
      </div>
    </b-row>
  </b-container>
</template>
<script>
export default {
  props: {
    processo: {
      type: Object,
    },
  },
};
</script>
