<template>
  <!-- TABELA CHECKLIST-->
  <tabela-processos :search="search">
    <template #header>
      <router-link
        v-slot="{ navigate }"
        custom
        to="/processo"
        class="w-auto"
        v-if="prefeitura()"
      >
        <b-button variant="info" @click="navigate">
          <b-icon icon="plus"></b-icon>
          Novo Processo
        </b-button>
      </router-link>
    </template>
  </tabela-processos>
  <!--  FIM TABELA CHECKLIST-->
</template>

<script>
import TabelaProcessos from '@/components/TabelaProcessos';
export default {
  data() {
    return {
      search: '',
    };
  },
  components: {
    TabelaProcessos,
  },
  methods: {
    prefeitura() {
      return JSON.parse(localStorage.getItem('user')).perfil.grupo_perfil_id == '2';
    },
    sdc() {
      return JSON.parse(localStorage.getItem('user')).perfil.grupo_perfil_id == '1';
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
