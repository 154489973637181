<template>
  <header class="position-fixed w-100">
    <b-navbar style="background-color: #1b7e9f" toggleable="lg">
      <b-navbar-brand href="#">
        <img
          alt="Amapá Brazão"
          class="mx-3"
          height="52px"
          src="@/assets/brazao 1.svg"
        />
      </b-navbar-brand>

      <span
        style="font-size: calc(0.5rem + 1vw); color: #f8f8f8"
        class="font-weight-bold"
      >
        Sistema de Gestão de Convênios
      </span>
      <b-navbar-toggle target="nav-collapse" class="bg-white"></b-navbar-toggle>
      <b-collapse id="nav-collapse" class="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="col-12 justify-content-end">
          <b-dropdown
            text="outline"
            variant="outline"
            toggle-class="text-white"
            class="d-none d-lg-block"
            right
          >
            <template #button-content class="ml-2">
              <b-icon
                class="mr-2"
                icon="person-circle"
                style="color: #ffff"
              ></b-icon
              ><a class="text-white mr-2">{{ nome }}</a>
            </template>
            <b-dropdown-item v-b-modal.alterar-senha
              >Alterar Senha</b-dropdown-item
            >
            <b-dropdown-item @click="logout()">Sair</b-dropdown-item>
          </b-dropdown>
          <!-- Mobile -->
          <div class="d-lg-none">
            <b-nav-item class="my-3">
              <b-row class="ml-1">
                <b-icon
                  class="mr-2"
                  icon="person-circle"
                  style="color: #ffff"
                ></b-icon>
                <p class="text-white mr-2">{{ nome }}</p>
              </b-row>
            </b-nav-item>
            <b-nav-item to="/dashboard">
              <b-row class="ml-1">
                <b-icon icon="bar-chart-fill" class="text-white"></b-icon>
                <p class="text-white ml-2">Dashboard</p>
              </b-row>
            </b-nav-item>
            <b-nav-item to="/processos/minha-unidade">
              <b-row class="ml-1">
                <b-icon
                  icon="file-earmark-text-fill"
                  class="text-white"
                ></b-icon>
                <p class="text-white ml-2">Processos</p>
              </b-row>
            </b-nav-item>
            <b-nav-item
              to="/usuario"
              v-if="$helper.verificaPermissao('visualizar_servidores')"
            >
              <b-row class="ml-1">
                <b-icon icon="people-fill" class="text-white"></b-icon>
                <p class="text-white ml-2">Servidores</p>
              </b-row>
            </b-nav-item>
            <b-nav-item to="/relatorios">
              <b-row class="ml-1">
                <b-icon
                  icon="file-earmark-medical-fill"
                  class="text-white"
                ></b-icon>
                <p class="text-white ml-2">Relatórios</p>
              </b-row>
            </b-nav-item>
            <b-nav-item to="/assinaturas">
              <b-row class="ml-1">
                <b-icon icon="pen-fill" class="text-white"></b-icon>
                <p class="text-white ml-2">Assinaturas</p>
                <span
                  v-if="signatarios.length > 0"
                  class="ml-1 mt-5"
                  v-b-tooltip.hover
                  :title="
                    signatarios.length > 1
                      ? signatarios.length + ' Assinaturas Pendentes'
                      : signatarios.length + ' Assinatura Pendente'
                  "
                >
                  <b-icon
                    icon="exclamation-circle-fill"
                    variant="danger"
                  ></b-icon>
                </span>
              </b-row>
            </b-nav-item>
            <b-nav-item class="mt-4">
              <b-row class="ml-1">
                <b-button
                  v-b-modal.alterar-senha
                  style="background-color: #1b7e9f; border: none; padding: 0"
                  variant="light"
                >
                  <div class="d-flex justify-between align-items-center">
                    <b-icon icon="pencil-fill" class="text-white" />
                    <span class="text-white ml-2">Alterar senha</span>
                  </div>
                </b-button>
              </b-row>
            </b-nav-item>
            <b-nav-item>
              <b-row class="ml-1">
                <b-button
                  style="background-color: #1b7e9f; border: none; padding: 0"
                  variant="light"
                >
                  <div class="d-flex justify-between align-items-center">
                    <b-icon icon="box-arrow-left" class="text-white" />
                    <span class="text-white ml-2" @click="logout()"
                      >Logout</span
                    >
                  </div>
                </b-button>
              </b-row>
            </b-nav-item>
          </div>
        </b-navbar-nav>
      </b-collapse>
      <!-- </div> -->
    </b-navbar>

    <!-- Modal -->
    <template>
      <b-modal
        id="alterar-senha"
        size="md"
        title="Alterar Senha"
        title-class="font-weight-bolder"
        @hide="limparAlterarSenha"
        @ok="alterarSenha"
      >
        <b-form>
          <div class="row">
            <div class="col-12">
              <b-form-group label="Senha Atual:">
                <b-input-group class="mb-2">
                  <b-form-input
                    v-model="password"
                    required
                    :type="inputType"
                  ></b-form-input>
                  <b-input-group-prepend is-text>
                    <div @click="verSenha()">
                      <div style="width: 15px">
                        <span v-if="isPassword">
                          <b-icon icon="eye"></b-icon>
                        </span>
                        <span v-else>
                          <b-icon icon="eye-slash"></b-icon>
                        </span>
                      </div>
                    </div>
                  </b-input-group-prepend>
                </b-input-group>
              </b-form-group>

              <!-- NOVA SENHA !-->
              <b-form-group label="Nova Senha:">
                <b-input-group class="mb-2">
                  <b-form-input
                    id="novasenha"
                    v-model="novaSenha"
                    required
                    :type="inputType2"
                  ></b-form-input>
                  <b-input-group-prepend is-text>
                    <div @click="verNovaSenha()">
                      <div style="width: 15px">
                        <span v-if="isNewPassword">
                          <b-icon icon="eye"></b-icon>
                        </span>
                        <span v-else>
                          <b-icon icon="eye-slash"></b-icon>
                        </span>
                      </div>
                    </div>
                  </b-input-group-prepend>
                </b-input-group>
              </b-form-group>

              <!-- Confirmar Senha !-->
              <b-form-group label="Confirmar Senha:">
                <b-input-group class="mb-2">
                  <b-form-input
                    id="novasenha2"
                    v-model="confirmaSenha"
                    required
                    :type="inputType3"
                  ></b-form-input>
                  <b-input-group-prepend is-text>
                    <div @click="confirmarSenha()">
                      <div style="width: 15px">
                        <span v-if="confirmarPassword">
                          <b-icon icon="eye"></b-icon>
                        </span>
                        <span v-else>
                          <b-icon icon="eye-slash"></b-icon>
                        </span>
                      </div>
                    </div>
                  </b-input-group-prepend>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
        </b-form>
        <template v-slot:modal-footer="{ hide, ok }">
          <b-button size="sm" variant="outline-danger" @click="hide()">
            Fechar
          </b-button>
          <b-button size="sm" variant="outline-primary" @click="ok()">
            Atualizar
          </b-button>
        </template>
      </b-modal>
    </template>
  </header>
</template>

<script>
export default {
  name: 'header-layout',
  data() {
    return {
      perfil: '',
      actualProfile: '',
      prefeitura: '',
      novaSenha: '',
      confirmaSenha: '',
      password: '',
      nome: '',
      signatarios: [],
      inputType: 'password',
      inputType2: 'password',
      inputType3: 'password',
    };
  },
  computed: {
    isPassword() {
      return this.inputType === 'password';
    },
    isNewPassword() {
      return this.inputType2 === 'password';
    },
    confirmarPassword() {
      return this.inputType3 === 'password';
    },
  },

  methods: {
    setNomePerfil() {
      let prefeitura = localStorage.getItem('prefeitura_id');
      this.perfil = localStorage.getItem('nome_perfil');
      this.nome = localStorage.getItem('user_name');

      if (prefeitura == 1) {
        this.prefeitura = 'Macapá';
      } else if (prefeitura == 2) {
        this.prefeitura = 'Santana';
      }
    },
    limparAlterarSenha() {
      this.password = '';
      this.novaSenha = '';
      this.confirmaSenha = '';
    },
    alterarSenha() {
      if (this.confereSenha()) {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          title: 'As senhas são diferentes !',
          icon: 'error',
        });
        return;
      }
      const data = {
        password: this.password,
        nova_senha: this.novaSenha,
      };

      const user_id = localStorage.getItem('user_id');

      this.$http
        .put(`usuarios/update-password/${user_id}`, data)
        .then(() => {
          this.$swal({
            title: 'Senha atualizada com sucesso!',
            text: 'Realize o login com a sua nova senha',
            icon: 'success',
          });
        })
        .catch(error => {
          this.$swal({ title: 'Erro ao atualizar senha!', icon: 'error' });
          console.log(error.response);
        });

      this.limparAlterarSenha();
      this.logout();
    },
    logout() {
      localStorage.removeItem('user_id');
      localStorage.removeItem('user_name');
      localStorage.removeItem('perfil_id');
      localStorage.removeItem('nome_perfil');
      localStorage.removeItem('prefeitura_id');
      localStorage.removeItem('setor_id');

      this.$store.commit('clean', [])

      this.$router.push('/login');
      this.$http
        .post('auth/logout', null)
        .then(() => localStorage.removeItem('token'))
        .catch(error => {
          console.log(error.response);
        });
    },
    verSenha() {
      this.inputType = this.isPassword ? 'text' : 'password';
    },
    verNovaSenha() {
      this.inputType2 = this.isNewPassword ? 'text' : 'password';
    },
    confirmarSenha() {
      this.inputType3 = this.confirmarPassword ? 'text' : 'password';
    },
    confereSenha() {
      return this.confirmaSenha != this.novaSenha;
    },
    async getSignatarios() {
      let user_id = localStorage.getItem('user_id');
      try {
        const response = await this.$http.get('user-signatario/' + user_id, {});
        response.data.forEach(signatarios => {
          if (signatarios.pivot.status === null) {
            let tipo = signatarios.nome_original;
            tipo = tipo.substr(0, tipo.indexOf('-'));
            signatarios.tipo = tipo;
            this.signatarios.push(signatarios);
          }
        });
      } catch (error) {
        console.log(error.response);
      }
    },
    getPerfil() {
      JSON.parse(localStorage.getItem('user')).perfil.grupo_perfil_id == '1'
        ? (this.actualProfile = 'sdc')
        : (this.actualProfile = 'prefeitura');
    },
  },
  mounted() {
    this.setNomePerfil();
    this.getPerfil();
    this.getSignatarios();
  },
};
</script>
