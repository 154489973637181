<template>
  <div>
    <b-form>
      <b-form-group
        label="Selecione o(s) Destinatário(s) da Prefeitura:"
        required
      >
        <multiselect
          v-model="destinatarios"
          :close-on-select="true"
          :multiple="true"
          :options="servidores"
          :searchable="true"
          :show-labels="false"
          placeholder="Selecione..."
        ></multiselect>
      </b-form-group>
      <b-form-group label="Signatários:" required>
        <multiselect
          v-model="signatarios"
          :close-on-select="true"
          :multiple="true"
          :options="usuarios"
          :searchable="true"
          :show-labels="false"
          :taggable="true"
          placeholder="Selecione..."
        ></multiselect>
      </b-form-group>
      <b-form-group
        id="input-group-1"
        label="Objeto de Convênio:"
        label-for="convenio"
      >
        <b-form-input
          id="referente"
          disabled
          type="text"
          v-bind:value="processo.titulo"
        ></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-1" label="Processo:" label-for="referente">
        <b-form-input
          id="referente"
          disabled
          type="text"
          v-bind:value="processo.protocolo"
        ></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-1" label="Número:" label-for="referente">
        <b-form-input
          id="referente"
          v-model="notificacao.numero"
          required
          type="text"
        ></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-1">
        <div class="form-group">
          <vue-editor
            v-model="mensagem"
            :editorToolbar="editorToolbar"
          ></vue-editor>
        </div>
      </b-form-group>

      <b-button variant="primary" @click="redigirDocumento()" class="px-5 py-2"
        >Salvar
      </b-button>
    </b-form>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },
  name: 'Despacho',
  data() {
    return {
      editorToolbar: [
        ['bold', 'italic', 'underline'],
        [{ color: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: 'center' }, { align: 'justify' }, { align: 'right' }],
      ],
      mensagem: '',
      notificacao: {},
      destinatarios: [],
      servidores: [],
      usuarios: [],
      signatarios: [],
      usuariosIds: [],
    };
  },
  methods: {
    getServidoresPrefeitura() {
      this.$http
        .get('servidores-prefeituras')
        .then(res => {
          res.data.forEach(servidor => {
            this.servidores.push(
              `${servidor.name} - ${servidor.prefeitura.sigla}`
            );
          });
        })
        .catch(error => {
          console.log(error.response);
        });
    },
    getUsuarios() {
      this.$http
        .get('servidores-sdc')
        .then(res => {
          res.data.forEach(user => {
            this.usuarios.push(user.name);
            this.usuariosIds.push(user.id);
          });
        })
        .catch(error => {
          console.log(error.response);
        });
    },
    redigirDocumento() {
      let indexes = this.usuarios.map((value, index) => {
        if (this.signatarios.includes(value)) {
          return index;
        }
      });
      let signatariosIds = this.usuariosIds.filter((value, index) => {
        if (indexes.includes(index)) {
          return value;
        }
      });

      if(!this.mensagem || !this.notificacao.numero || this.destinatarios.length == 0 || signatariosIds.length == 0){
        this.$swal({
          title: 'Preencha todos os campos!',
          icon: 'warning',
        });

        return null
      }

      let data = {
        mensagem: this.mensagem,
        convenio: this.processo.titulo,
        numero: this.notificacao.numero,
        tipo_documentos_id: this.tipo_documentos_id,
        processo: this.processo,
        processo_id: this.processo.id,
        destinatarios: this.destinatarios,
        signatarios: signatariosIds,
      };
      this.$http.post('documentos-redigidos', data).catch(error => {
        console.log(error.response);
      });

      this.$swal({ title: 'Documento redigido com sucesso!', icon: 'success' });
      this.$emit('refreshProcesso');
      this.limparDados();
    },
    limparDados() {
      this.mensagem = '';
      this.destinatarios = [];
      this.signatarios = [];
      this.numero = [];
      this.notificacao.numero = ''
    },
  },
  created() {
    this.getServidoresPrefeitura();
    this.getUsuarios();
  },
  props: {
    processo: {
      type: Object,
    },
    tipo_documentos_id: {
      type: Number,
    },
  },
};
</script>
