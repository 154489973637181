<template>
  <b-container fluid class="p-4 border h-100">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h4>Relatórios:</h4>
    </div>
    <b-row
      class="mb-5"
      :class="
        isInitialDateValid && isFinalDateValid
          ? 'align-items-end'
          : 'align-items-center'
      "
    >
      <b-col lg="5">
        <h5>Data de ínicio</h5>
        <b-form-datepicker
          v-model="initialDate"
          :state="isInitialDateValid"
          placeholder="Escolha uma data de ínicio"
          today-button
          reset-button
          label-reset-button="Resetar"
          label-today-button="Escolher hoje"
        />
        <b-form-invalid-feedback>
          Selecione uma data de ínicio
        </b-form-invalid-feedback>
      </b-col>
      <b-col lg="5">
        <h5>Data final:</h5>
        <b-form-datepicker
          v-model="finalDate"
          :state="isFinalDateValid"
          placeholder="Escolha uma data final"
          today-button
          reset-button
          label-reset-button="Resetar"
          label-today-button="Escolher hoje"
        />
        <b-form-invalid-feedback>
          Selecione uma data final
        </b-form-invalid-feedback>
      </b-col>
      <b-col lg="2">
        <b-button variant="primary" @click="fetchBoth" :disabled="areDatesEmpty"
          >BUSCAR</b-button
        >
      </b-col>
    </b-row>

    <b-row v-if="items.length > 0">
      <b-col lg="12" class="mb-4">
        <b-form-checkbox
          v-model="checked"
          name="check-button"
          switch
          @change="toggleComponent"
        >
          Modo gráfico
        </b-form-checkbox>
      </b-col>
      <b-col lg="12">
        <component :is="component" :items="items" :charts="charts" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import RelatoriosTable from '../components/RelatoriosTable.vue';
import RelatorioGraphs from '../components/RelatorioGraphs.vue';
export default {
  components: {
    RelatoriosTable,
    RelatorioGraphs,
  },
  data() {
    return {
      items: [],
      charts: [],
      checked: false,
      finalDate: '',
      component: RelatoriosTable,
      initialDate: '',
    };
  },
  computed: {
    dates() {
      return { data_inicio: this.initialDate, data_fim: this.finalDate };
    },
    isFinalDateValid() {
      return this.finalDate.length > 0;
    },
    isInitialDateValid() {
      return this.initialDate.length > 0;
    },
    areDatesEmpty() {
      return this.initialDate.length === 0 || this.finalDate.length === 0;
    },
  },
  methods: {
    toggleComponent() {
      if (this.checked) {
        this.component = RelatorioGraphs;
        return;
      }
      this.component = RelatoriosTable;
    },
    areDatesInvalid() {
      return new Date(this.finalDate) <= new Date(this.initialDate);
    },
    spreadArray(arr) {
      return arr.map(elem => elem.nome).join(', ');
    },
    async fetchGraphsData() {
      try {
        const params = { ...this.dates };
        const { data } = await this.$http.get('/graficos', {
          params,
        });
        this.charts = data;
      } catch (error) {
        console.error(error);
      }
    },
    fetchBoth() {
      Promise.all([this.fetchProcessos(), this.fetchGraphsData()]);
    },
    async fetchProcessos() {
      if (this.areDatesInvalid()) {
        this.$swal({
          title: 'Erro!',
          text: 'A data final é maior que a data de início!',
          icon: 'error',
          confirmButtonText: 'Ok',
        });
        return;
      }
      try {
        const params = {
          data_inicio: this.initialDate,
          data_fim: this.finalDate,
        };
        const { data } = await this.$http.get('/processos/relatorios', {
          params,
        });
        if (data.processos.data.length === 0) {
          this.$swal({
            title: 'Erro!',
            text: 'Não há dados para o período selecionado!',
            icon: 'warning',
            confirmButtonText: 'Ok',
          });
          return;
        }
        this.items = data.processos.data.map(elem => {
          return {
            protocolo: elem.protocolo,
            eixo: elem.eixo.descricao,
            subeixo: elem.subeixo.descricao,
            municipio: elem.prefeitura,
            dataCriacao: elem.created_at,
            valorConvenio: elem.valor_convenio,
            valorLicitatorio: elem.valor_processo_licitatorio,
            periodoExec: elem.periodo_execucao,
            fiscais: this.spreadArray(elem.fiscais),
          };
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
