<template>
  <div class="vh-100 vw-100">
    <b-row class="justify-content-center h-100">
      <div class="col-12 col-lg-6 d-flex position-relative">
        <form class="m-auto text-center w-75">
          <div class="font-weight-bolder text-left mt-5">
            <b-form-group id="input-group-1" label="Informe o e-mail" label-for="email">
              <b-form-input
                id="email"
                placeholder="Digite seu e-mail"
                v-model="email"
                required
                type="text"
                v-on:keydown.enter.prevent="RecuperarEmail"
              ></b-form-input>
            </b-form-group>
          </div>

          <p
            class="text-left text-primary"
            style="text-decoration: underline; cursor: pointer"
          >
            <router-link to="/login">Voltar</router-link>
          </p>

          <button
            class="btn w-100 mt-5 bg-info text-white font-weight-bold"
            type="button"
            @click="RecuperarEmail"
          >
            Recuperar senha
          </button>
        </form>
        <figure
          class="position-absolute d-flex align-items-center"
          style="left: 2em; top: 1em"
        >
          <img
            src="@/assets/logo_no_bg.png"
            alt="Logo SDC"
            style="height: 52px"
          />
          <figcaption class="ml-3">
            <h4 class="font-weight-bolder">SDC</h4>
            <span>Sistema de Gestão de Convênios</span>
          </figcaption>
        </figure>
      </div>
      <div class="col-6 d-none d-lg-flex position-relative bg-info">
        <img src="@/assets/logo_no_bg.png" alt="Logo SDC" class="h-50 m-auto" />
        <div class="position-absolute" style="right: 2em; top: 1em">
          <img src="@/assets/brazao 1.svg" alt="Brazão Amapá" height="52px" />
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'RecuperarEmail',
  data() {
    return {
      email: '',
    };
  },
  methods: {
    async RecuperarEmail() {
      if (this.email != '') {
        try {
          console.log('getRequest')
          const { data } = await this.$http.post('esqueci-senha', {
            email: this.email,
          });

          this.email = ''

          this.$swal({
            title: 'E-mail informado com sucesso!',
            text: data.message,
            icon: 'success',
          });
        } catch (error) {
          this.$swal({
            title: 'E-mail não consta no sistema!',
            text: 'Verifique o e-mail informado',
            icon: 'error',
          });
        }
      } else {
        this.$swal({
          title: 'E-mail não consta no sistema!',
          text: 'Verifique o e-mail informado',
          icon: 'error',
        });
      }
    },
  },
  async mounted() {
  },
};
</script>
