<template>
  <div class="table-responsive">
    <b-table v-if="data.length > 0" hover :items="data" :fields="fields">
      <template #cell(status)="row">
        <span
          :class="statusClass(row.value)"
          class="py-1 px-2 rounded-pill text-white font-weight-bolder"
          >{{ row.value }}</span
        >
      </template>
      <template #cell(actions)="row">
        <b-button
          size="sm"
          variant="primary"
          @click="openModal(row.item.id, row.item.name)"
        >
          <b-icon icon="eye" />
        </b-button>
      </template>
    </b-table>
    <b-modal
      lazy
      id="modal-fiscais"
      title="Detalhes dos Processos Relacionados"
      ok-only
      size="xl"
      scrollable
      @shown="fetchProcesso"
      @hide="clear"
    >
      <div v-if="isLoading" class="d-flex justify-content-center w-100 py-5">
        <b-spinner variant="primary"></b-spinner>
      </div>
      <div style="color: #003e5d" v-else>
        <h4 class="mb-4">{{ fiscalName }}:</h4>
        <b-table
          hover
          :items="fiscalDetails"
          :fields="fieldsDetails"
          :busy="isLoading"
          show-empty
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #empty>
            <h5 class="text-center">O fiscal não tem processos vinculados</h5>
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: 'TabelaFiscais',
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: '##',
          sortDirection: 'asc',
        },
        {
          key: 'name',
          label: 'Nome',
        },
        {
          key: 'processos_count',
          label: 'Processos Vinculados',
        },
        {
          key: 'actions',
          label: 'Ações',
        },
      ],
      fieldsDetails: [
        {
          key: 'protocolo',
          label: 'Protocolo',
          sortDirection: 'asc',
        },
        {
          key: 'titulo',
          label: 'Título',
        },
      ],
      fiscalId: 0,
      fiscalName: '',
      fiscalDetails: [],
      isLoading: false,
    };
  },
  props: {
    data: {
      required: true,
      type: Array,
    },
  },
  methods: {
    async fetchProcesso() {
      this.isLoading = true;
      try {
        const response = await this.$http.get(
          `fiscais/${this.fiscalId}/processos-relacionados/`
        );
        this.fiscalDetails = response.data[0];
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    openModal(index, name) {
      this.fiscalId = index;
      this.fiscalName = name;
      this.$bvModal.show('modal-fiscais');
    },
    clear() {
      this.fiscalId = 0;
      this.fiscalName = '';
      this.fiscalDetails = [];
    },
  },
};
</script>
