<template>
  <main>
    <b-container fluid class="p-0">
      <b-modal v-if="exibirModal" size="xl" id="visualizar" style="width: 70%">
        <embed :src="local_link" width="100%" height="400px" />

        <template v-slot:modal-footer="{ hide }">
          <b-button size="sm" variant="outline-danger" @click="hide()">
            Fechar
          </b-button>
        </template>
      </b-modal>

      <div>
        <b-form @click="handleClick($event)" @submit.prevent="submit($event)">
          <h3 class="font-weight-bold">
            <router-link to="/processos/minha-unidade">
              <b-icon
                icon="arrow-left"
                style="cursor: pointer; color: #003e5d"
              />
            </router-link>
            Criar Novo Processo
          </h3>
          <hr class="pb-3" />
          <b-row>
            <div class="col-12 mb-3">
              <label class="font-weight-bolder" style="color: #003e5d"
                >Título do Processo *</label
              >
              <b-form-input
                :disabled="isLoading"
                v-model="processo.titulo"
                class="form-control"
                required
                placeholder="Digite o nome do processo..."
              ></b-form-input>
            </div>
            <div class="col-12 col-sm-4 mb-3">
              <b-form-group
                label="Eixo:"
                class="font-weight-bolder"
                required
                style="color: #003e5d"
              >
                <b-form-select
                  v-model="eixo"
                  :disabled="isLoading"
                  :options="eixos"
                  @change="getSubEixo($event)"
                >
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-12 col-sm-4">
              <b-form-group
                label="SubEixo:"
                class="font-weight-bolder"
                style="color: #003e5d"
                required
              >
                <b-form-select
                  v-model="subeixo"
                  :disabled="isLoading || !eixo"
                  :options="subeixos"
                  @change="setItensChecklist()"
                ></b-form-select>
              </b-form-group>
            </div>
          </b-row>
          <div>
            <label class="font-weight-bolder" style="color: #003e5d"
              >Descrição do Processo *</label
            >
            <b-form-textarea
              v-model="processo.descricao"
              class="form-control"
              cols="30"
              rows="10"
              :disabled="isLoading"
              required
            ></b-form-textarea>
          </div>
          <div class="card mt-3 p-3" v-if="exibeChecklist()">
            <h5 class="mb-4">{{ this.subeixo.checklist.descricao }}</h5>

            <div v-if="this.topicos.length > 0">
              <b-row v-for="topic in this.topicos" :key="topic.id" class="mt-2">
                <b-row class="col-12 mb-2 no-gutters">
                  <b-col lg="10">
                    <h6>{{ topic.titulo }}</h6>
                  </b-col>
                </b-row>

                <b-col class="col-12">
                  <div class="table-responsive">
                    <b-table
                      :busy="isLoading"
                      show-empty
                      empty-text="Nenhum item cadastrado para o tópico!"
                      striped
                      fixed
                      hover
                      :fields="[
                        'descricao',
                        { key: 'actions', label: 'Ações' },
                      ]"
                      :items="topic.itens_checklist"
                      caption-top
                    >
                      <template #cell(actions)="data">
                        <td>
                          <label
                            v-b-tooltip.hover
                            title="Carregar Anexo"
                            class="btn btn-default btn-file"
                          >
                            <b-icon
                              icon="file-earmark-plus"
                              v-if="!itemTemUpload(data.item)"
                              variant="dark"
                            ></b-icon>
                            <b-icon
                              icon="file-check"
                              v-else
                              variant="secondary"
                            ></b-icon>
                            <input
                              type="file"
                              accept=".pdf"
                              v-if="!itemTemUpload(data.item)"
                              @change="handleFileUpload(data.item, $event)"
                              style="display: none"
                            />
                          </label>
                        </td>
                        <td>
                          <label
                            v-b-tooltip.hover
                            v-if="itemTemUpload(data.item)"
                            title="Visualizar Anexo"
                            class="btn btn-default btn-file"
                          >
                            <b-icon icon="eye"></b-icon>
                            <b-button
                              v-b-modal.visualizar
                              @click="showFile(data.item)"
                              style="display: none"
                            ></b-button>
                          </label>
                        </td>
                        <td>
                          <template v-if="itemTemUpload(data.item)">
                            <b-icon
                              v-b-tooltip.hover
                              title="Anexo Carregado"
                              class="btn btn-default"
                              icon="check-square-fill"
                              variant="success"
                            ></b-icon>
                          </template>
                        </td>
                        <td>
                          <label
                            v-b-tooltip.hover
                            v-if="itemTemUpload(data.item)"
                            title="Excluir Anexo"
                            class="btn btn-default btn-file"
                          >
                            <b-icon icon="trash-fill" variant="danger"></b-icon>
                            <b-button
                              v-b-modal.visualizar
                              @click="removeFile(data.item)"
                              style="display: none"
                            ></b-button>
                          </label>
                        </td>
                      </template>
                      <template #table-colgroup="scope">
                        <col
                          v-for="field in scope.fields"
                          :key="field.key"
                          :style="{
                            width: field.key === 'actions' ? '30%' : '70%',
                          }"
                        />
                      </template>
                    </b-table>
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-row class="col-12 mb-2" v-else>
              <b-col lg="10">
                <h6>Não existem tópicos para o checklist atual!</h6>
              </b-col>
            </b-row>
          </div>
          <!--  FIM TABELA CHECKLIST-->
          <div style="text-align: right">
            <b-button
              type="submit"
              class="mt-3 mr-3"
              :disabled="isLoading"
              @click="cancelarProcesso()"
              variant="danger"
              >Cancelar
            </b-button>
            <b-button
              name="enviar"
              type="submit"
              class="mt-3"
              variant="primary"
              :disabled="isLoading"
              >Enviar
            </b-button>
          </div>
        </b-form>
      </div>
    </b-container>
  </main>
</template>

<script>
export default {
  data() {
    return {
      exibirModal: false,
      isLoading: false,
      local_link: '',
      files: [],
      eixo: null,
      eixos: [],
      subeixo: null,
      subeixos: [],
      processo: {},
      topicos: [],
      botaoClicado: '',
    };
  },
  methods: {
    async getEixos() {
      this.isLoading = true;
      this.eixos = [{ value: null, text: 'Selecione um eixo...' }];
      this.subeixos = [{ value: null, text: 'Selecione um subeixo...' }];
      try {
        const res = await this.$http.get('eixos', {});
        res.data.forEach(eixo => {
          this.eixos.push({ value: eixo, text: eixo.descricao });
        });
      } catch (error) {
        console.log(error.response);
      } finally {
        this.isLoading = false;
      }
    },
    getSubEixo(event) {
      if (!this.eixo) {
        this.subeixos = [{ value: null, text: 'Selecione um subeixo...' }];
        this.subeixo = null;
        return;
      }
      this.subeixo = null;
      this.files = [];
      this.subeixos = [{ value: null, text: 'Selecione um subeixo...' }];
      event.subeixos.forEach(subeixo => {
        this.subeixos.push({ value: subeixo, text: subeixo.descricao });
      });
    },
    setItensChecklist() {
      if (this.subeixo && this.subeixo.checklist) {
        this.files = [];
        this.topicos = [];
        this.topicos = this.subeixo.checklist.topicos;
      }
    },
    exibeChecklist() {
      return this.subeixo && !!this.subeixo.checklist;
    },
    handleFileUpload(item, event) {
      let file = event.target.files[0];
      this.files.push({
        item_id: item.id,
        anexo: file,
        local_link: URL.createObjectURL(file),
      });
    },
    removeFile(item) {
      const index = this.files.findIndex(file => {
        return item.id === file.item_id;
      });
      this.files.splice(index, 1);
      this.$swal({
        title: 'O anexo deste item foi removido com sucesso',
        icon: 'success',
      });
    },
    showFile(item) {
      try {
        this.exibirModal = true;
        let file = this.files.find(el => el.item_id == item.id);
        this.local_link = file.local_link;
      } catch (e) {
        this.local_link = '';
        this.exibirModal = false;
        this.$swal({ title: 'Não há anexo para este item', icon: 'error' });
      }
    },
    itemTemUpload(item) {
      try {
        let file = this.files.find(el => el.item_id == item.id);
        return file !== undefined;
      } catch (e) {
        return false;
      }
    },
    handleClick(event) {
      this.botaoClicado = event.target.name;
    },
    submit() {
      let somaTotal = 0;

      for (let i = 0; i < this.topicos.length; i++) {
        const itensChecklist = this.topicos[i].itens_checklist;
        somaTotal += itensChecklist.length;
      }
      if (this.files.length === somaTotal) {
        let formData = new FormData();
        formData.append('prefeitura_id', localStorage.getItem('prefeitura_id'));
        formData.append('status', 'Salvo');
        formData.append('user_id', localStorage.getItem('user_id'));
        formData.append('eixo_id', this.eixo.id);
        formData.append('subeixo_id', this.subeixo.id);
        formData.append('titulo', this.processo.titulo);
        formData.append('descricao', this.processo.descricao);
        formData.append('tem_capa', 0);
        for (var i = 0; i < this.files.length; i++) {
          let file = this.files[i];
          formData.append('anexo_checklist[' + i + ']', file.anexo);
          formData.append('itens_id[' + i + ']', file.item_id);
        }
        this.salvarProcesso(formData);
      } else {
        this.$swal({ title: 'Carregue todos os anexos!', icon: 'error' });
      }
    },
    async enviarProcesso() {
      this.processo.status = 'Encaminhado';
      this.isLoading = true;
      try {
        await this.$http.put(
          `processos/${this.processo.id}`,
          this.processo,
          {}
        );
        this.$swal({
          title: 'Processo enviado com sucesso!',
          icon: 'success',
        });
        this.$router.push('/processos/minha-unidade');
      } catch (error) {
        this.$swal({
          title: 'Ocorreu um erro ao enviar  o processo!',
          icon: 'error',
        });
      } finally {
        this.isLoading = false;
      }
    },
    async salvarProcesso(formData) {
      this.isLoading = true;
      try {
        const { data } = await this.$http.post('/processos', formData);
        if (this.botaoClicado === 'enviar') {
          this.processo = data;
          await this.enviarProcesso();
          this.limparDados();
        } else {
          this.$swal({
            title: 'Processo salvo com sucesso!',
            icon: 'success',
          });
          this.$router.push('/processos/minha-unidade');
        }
      } catch (_) {
        this.$swal({
          title: 'Ocorreu um erro ao salvar o processo!',
          icon: 'error',
        });
      } finally {
        this.isLoading = false;
      }
    },
    cancelarProcesso() {
      this.limparDados();
      this.$swal({ title: 'Cancelado!', icon: 'error' });
      this.$router.push('/processos/minha-unidade');
    },
    limparDados() {
      (this.exibirModal = false),
        (this.local_link = ''),
        (this.files = []),
        (this.eixo = {}),
        (this.eixos = []),
        (this.subeixo = {}),
        (this.subeixos = []),
        (this.processo = {}),
        (this.topicos = []);
    },
  },
  created() {
    this.getEixos();
  },
};
</script>
