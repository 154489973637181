<template>
  <main>
    <b-container fluid class="p-4 border">
      <div class="d-flex align-items-center justify-content-between mb-4">
        <h4>Servidores</h4>
        <b-button
          @click="openCreateModal"
          v-b-modal.modal-1
          variant="outline-primary"
          :disabled="isLoading"
          class="ml-1 float-right"
          >Cadastrar
        </b-button>
      </div>
      <b-overlay :show="isLoading" rounded="sm">
        <div class="table-responsive">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th scope="col" class="col-3">
                  <b-form-input
                    v-model="filtros.name"
                    class="form-rounded"
                    placeholder="Nome"
                    size="sm"
                    @input="debouncedPesquisar()"
                    style="border-radius: 7px"
                  />
                </th>
                <th scope="col" class="col-3">
                  <b-form-input
                    v-model="filtros.email"
                    class="form-rounded"
                    placeholder="Email"
                    size="sm"
                    @input="debouncedPesquisar()"
                    style="border-radius: 7px"
                  />
                </th>
                <th scope="col" class="2">
                  <b-form-input
                    v-model="filtros.cpf"
                    class="form-rounded"
                    placeholder="CPF"
                    size="sm"
                    @input="debouncedPesquisar()"
                    style="border-radius: 7px"
                  />
                </th>
                <th scope="col" class="1">
                  <b-form-input
                    v-model="filtros.matricula"
                    class="form-rounded"
                    placeholder="Matrícula"
                    size="sm"
                    @input="debouncedPesquisar()"
                    style="border-radius: 7px"
                  />
                </th>
                <th scope="col" class="col-1">
                  <b-form-select
                    :options="['Resultados', '10', '20', '30', 50]"
                    v-model="filtros.limit"
                    @input="debouncedPesquisar()"
                  ></b-form-select>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in usuarios.data" :key="user.id">
                  <td>{{ user.name }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.cpf }}</td>
                  <td>{{ user.matricula }}</td>
                  <!--    <td>{{ user.telefone }}</td> -->
                  <td>
                    <div class="d-flex align-items-center justify-content-center">
                      <button
                        v-b-tooltip.hover
                        title="Editar"
                        :disabled="isLoading"
                        class="btn btn-primary btn-sm"
                        @click="openEditModal(user)"
                      >
                        <b-icon icon="pencil"></b-icon>
                      </button>

                      <button
                        v-b-tooltip.hover
                        title="Excluir"
                        :disabled="isLoading"
                        class="btn btn-danger btn-sm ml-2"
                        @click="excluirUsuario(user)"
                      >
                        <b-icon icon="trash"></b-icon>
                      </button>
                    </div>
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>
      <footer class="d-flex justify-content-end align-items-center">
        <b-pagination
          v-if="usuarios.total"
          v-model="filtros.page"
          :total-rows="usuarios.total"
          :per-page="usuarios.per_page"
          first-number
          last-number
          @input="(filtros.page = $event), getUsuarios()"
          class="w-auto"
        />
      </footer>
    </b-container>
    <b-modal
      id="modal-1"
      size="lg"
      :title="titulo"
      title-class="font-weight-bold"
      @ok="saveButtonMethod"
    >
      <b-form @submit.stop.prevent>
        <template v-if="isSdc">
          <div class="row">
            <div class="col-12">
              <b-form-group
                label="Grupo Perfil:"
                required
                label-class="font-weight-bolder"
              >
                <b-form-select
                  :disabled="isLoading"
                  v-model="selectedGrupoPerfil"
                  :options="grupoPerfis"
                  @change="updateLocalProfiles"
                >
                </b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <b-form-group
                label="Perfil:"
                required
                label-class="font-weight-bolder"
              >
                <b-form-select
                  :disabled="isLoading"
                  v-model="selectedPerfil"
                  :options="perfis"
                >
                </b-form-select>
                <form-error
                  v-if="validationErrors.perfil_id"
                  :error="validationErrors.perfil_id"
                />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-12" v-if="showPrefeitura">
              <b-form-group
                label="Prefeitura:"
                required
                label-class="font-weight-bolder"
              >
                <b-form-select
                  :disabled="isLoading"
                  v-model="prefeituraId"
                  :options="prefeituras"
                >
                </b-form-select>
                <form-error
                  v-if="validationErrors.prefeitura_id"
                  :error="validationErrors.prefeitura_id"
                />
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-12" v-if="showSetor">
              <b-form-group
                label="Setor:"
                required
                label-class="font-weight-bolder"
              >
                <b-form-select
                  :disabled="isLoading"
                  v-model="selectedSetor"
                  :options="setores"
                >
                </b-form-select>
                <form-error
                  v-if="validationErrors.setor_id"
                  :error="validationErrors.setor_id"
                />
              </b-form-group>
            </div>
          </div>
        </template>
        <div class="row">
          <div class="col-6">
            <b-form-group label="Nome:" label-class="font-weight-bolder">
              <b-form-input
                v-model="name"
                placeholder="Digite o nome..."
                required
              ></b-form-input>
              <form-error
                v-if="validationErrors.name"
                :error="validationErrors.name"
              />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group label="Telefone:" label-class="font-weight-bolder">
              <b-form-input
                v-mask="'(##) # ####-####'"
                v-model="telefone"
                placeholder="Digite um telefone..."
                type="tel"
                required
              ></b-form-input>
              <form-error
                v-if="validationErrors.telefone"
                :error="validationErrors.telefone"
              />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group label="CPF:" label-class="font-weight-bolder">
              <b-form-input
                v-mask="'###.###.###-##'"
                placeholder="Digite o CPF..."
                v-model="cpf"
                required
              ></b-form-input>
              <form-error
                v-if="validationErrors.cpf"
                :error="validationErrors.cpf"
              />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group label="Matrícula:" label-class="font-weight-bolder">
              <b-form-input
                v-model="matricula"
                placeholder="Digite o número da matrícula..."
                required
              ></b-form-input>
              <form-error
                v-if="validationErrors.matricula"
                :error="validationErrors.matricula"
              />
            </b-form-group>
          </div>
          <div class="col-12">
            <b-form-group label="Email:" label-class="font-weight-bolder">
              <b-form-input
                v-model="email"
                placeholder="Digite um e-mail..."
                required
              ></b-form-input>
              <form-error
                v-if="validationErrors.email"
                :error="validationErrors.email"
              />
            </b-form-group>
          </div>
            <div class="col-12" v-if="showSetor">
              <b-form-group
                label="Selecione o tipo de fiscal:"
                label-class="font-weight-bolder"
              >
              <b-form-checkbox-group
                :disabled="isLoading"
                id="checkbox-group-1"
                v-model="selectedFiscals"
                :options="fiscalsOptions"
                name="fiscals-1"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
          <div class="col-12 col-lg-6" v-if="!editar || VerificaUsuarioSuporte()">
            <b-form-group :label="!editar ? 'Senha:' : 'Edita Senha:'" label-class="font-weight-bolder">
              <b-input-group class="mb-2">
                <b-form-input
                  v-model="password"
                  placeholder="Digite uma senha..."
                  :type="inputType"
                  :required="!editar"
                ></b-form-input>

                <b-input-group-prepend is-text>
                  <div @click="changePasswordInputType(1)">
                    <div style="width: 15px">
                      <span v-if="isInputTypePassword(1)">
                        <b-icon icon="eye"></b-icon>
                      </span>
                      <span v-else>
                        <b-icon icon="eye-slash"></b-icon>
                      </span>
                    </div>
                  </div>
                </b-input-group-prepend>
              </b-input-group>
              <form-error
                v-if="validationErrors.password"
                :error="validationErrors.password"
              />
            </b-form-group>
          </div>
          <div class="col-12 col-lg-6" v-if="!editar || VerificaUsuarioSuporte()">
            <b-form-group
              label="Confirma Senha:"
              label-class="font-weight-bolder"
            >
              <b-input-group class="mb-2">
                <b-form-input
                  v-model="checkPassword"
                  :type="inputType2"
                  placeholder="Repita a senha..."
                  :required="!editar"
                ></b-form-input>

                <b-input-group-prepend is-text>
                  <div @click="changePasswordInputType(2)">
                    <div style="width: 15px">
                      <span v-if="isInputTypePassword(2)">
                        <b-icon icon="eye"></b-icon>
                      </span>
                      <span v-else>
                        <b-icon icon="eye-slash"></b-icon>
                      </span>
                    </div>
                  </div>
                </b-input-group-prepend>
              </b-input-group>
              <form-error
                v-if="validationErrors.password"
                :error="validationErrors.password"
              />
            </b-form-group>
          </div>
        </div>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button variant="danger" @click="cancel()" :disabled="isLoading">
          CANCELAR
        </b-button>
        <b-button variant="primary" @click="ok()" :disabled="isLoading">
          {{ editar ? 'EDITAR' : 'CADASTRAR' }}
        </b-button>
      </template>
    </b-modal>
  </main>
</template>

<script>
import FormError from '@/components/FormError';
var _ = require('lodash');

export default {
  components: { FormError },
  props: {
    search: String,
  },
  data() {
    return {
      isLoading: false,
      selectedFiscals: [],
      fiscalsOptions: [
        { text: 'Fiscal Administrativo', value: 'fiscal_administrativo' },
        { text: 'Fiscal Técnico', value: 'fiscal_tecnico' },
        { text: 'Gestor de Convênio', value: 'gestor_convenio' },
      ],
      userId: '',
      titulo: '',
      editar: false,
      name: '',
      email: '',
      password: '',
      cpf: '',
      telefone: '',
      matricula: '',
      usuarios: {},
      selectedPerfil: null,
      perfis: [{ value: null, text: 'Selecione' }],
      selectedGrupoPerfil: null,
      grupoPerfis: [],
      prefeituraId: '',
      prefeituras: [{ value: null, text: 'Selecione' }],
      selectedSetor: null,
      setores: [{ value: null, text: 'Selecione' }],
      showPrefeitura: false,
      showSetor: false,
      inputType: 'password',
      inputType2: 'password',
      checkPassword: '',
      validationErrors: {
        name: null,
        telefone: null,
        cpf: null,
        matricula: null,
        password: null,
        nova_senha: null,
        email: null,
        perfil_id: null,
        prefeitura_id: null,
        setor_id: null,
      },
      filtros: {
        name: '',
        usuario_id: '',
        email: '',
        perfil_id: '',
        matricula: '',
        telefone: '',
        cpf: '',
        limit: 10,
        page: 1,
      },
    };
  },
  methods: {
    VerificaUsuarioSuporte(){
      let usuarioSuporte = JSON.parse(localStorage.getItem('user'));
      if (usuarioSuporte.suporte){
        return true;
      } else {
        return false;
      }
    },
    //USER REQUESTS
    async addUsuario() {
      if (!this.isPasswordsEqual()) return;
      const payload = this.payloadFactory();
      this.editar = false;
      this.isLoading = true;
      if (this.isPrefeitura()) {
        payload.prefeitura_id = localStorage.getItem('prefeitura_id');
      }

      try {
        await this.$http.post('usuarios', payload);
        this.$nextTick(() => {
          this.$bvModal.hide('modal-1');
        });
        this.$swal({
          title: 'Servidor cadastrado com sucesso!',
          icon: 'success',
        });
        await this.getUsuarios();
        this.$bvModal.hide('modal-1');
      } catch (error) {
        this.errorHandler(error);
        this.$swal({
          title: 'Erro ao cadastrar servidor! Tente novamente!',
          icon: 'error',
        });
      } finally {
        this.isLoading = false;
      }
    },
    async editarUsuario() {
      if (!this.isPasswordsEqual()) return;
      this.isLoading = true;
      try {
        const payload = this.payloadFactory();

        if(payload.password == "") {
          delete payload.password;
        }

        await this.$http.put(`usuarios/${this.userId}`, payload);

        this.$nextTick(() => {
          this.$bvModal.hide('modal-1');
        });

        this.$swal({
          title: 'Servidor editado com sucesso!',
          icon: 'success',
        });

        await this.getUsuarios();
        this.$bvModal.hide('modal-1');
      } catch (error) {
        console.log(error);
        this.errorHandler(error);
        this.$swal({
          title: 'Erro ao editar servidor! Tente novamente!',
          icon: 'error',
        });
      } finally {
        this.isLoading = false;
      }
    },
    async excluirUsuario(user) {
      const result = await this.$swal({
        icon: 'warning',
        title: 'Tem certeza que deseja excluir esse servidor?',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#3085d6',
        denyButtonText: 'Cancelar',
      });

      if (result.isConfirmed) {
        this.isLoading = true;
        try {
          await this.$http.delete(`usuarios/${user.id}`);
          this.$swal('Servidor removido com sucesso!', '', 'success');
          this.usuarios.data = this.usuarios.data.filter(
            usuario => usuario.id !== user.id
          );
        } catch (error) {
          this.$swal({
            title: 'Erro ao excluir servidor! Tente novamente!',
            icon: 'error',
          });
        } finally {
          this.isLoading = false;
        }
        return;
      }
      this.$swal('Uffa! Foi por pouco.', '', 'warning');
    },
    //HTTP GETTERS
    async getUsuarios() {
      this.isLoading = true;
      try {
        const { data } = await this.$http.get('usuarios', {
          params: this.filtros,
        });
        this.usuarios = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getGrupoPerfis() {
      this.isLoading = true;
      try {
        const res = await this.$http.get('grupo-perfis');
        this.grupoPerfis = res.data.map(grupoPerfil => {
          return {
            value: grupoPerfil.id,
            text: grupoPerfil.descricao,
          };
        });
      } catch (error) {
        console.log(error.response);
      } finally {
        this.isLoading = false;
      }
    },
    async getSetores() {
      this.isLoading = true;
      try {
        const res = await this.$http.get('setores', {});
        this.setores = res.data.map(setor => {
          return { value: setor.id, text: setor.nome };
        });
        this.prefeituraId = null;
        this.isLoading = false;
      } catch (error) {
        console.log(error.response);
        this.isLoading = false;
      }
    },
    async getPrefeituras() {
      this.isLoading = true;
      try {
        const response = await this.$http.get('prefeituras');
        this.prefeituras = response.data.map(prefeitura => {
          return {
            value: prefeitura.id,
            text: prefeitura.nome,
          };
        });
        this.selectedSetor = null;
        this.isLoading = false;
      } catch (error) {
        console.log(error.response);
        this.isLoading = false;
      }
    },
    async getPerfis(grupoPerfilId) {
      this.isLoading = true;
      try {
        const response = await this.$http.get(`grupos-perfis/${grupoPerfilId}/perfis`);
        this.isLoading = false;
        return response.data;
      } catch (error) {
        console.log(error.response);
        this.isLoading = false;
      }
    },
    //MODAL
    async updateLocalProfiles(groupId) {
      let profiles = await this.getPerfis(groupId);

      if (!Array.isArray(profiles)) {
        profiles = [profiles];
      }

      this.perfis = profiles.map(perfil => ({
        value: perfil.id,
        text: perfil.nome,
      }));

      this.showSetor = groupId === 1; // Se o grupo perfil for SDC
      this.showSetor ? this.getSetores() : this.getPrefeituras();
      this.showPrefeitura = !this.showSetor;
      this.$bvModal.show('modal-1');
    },
    async openEditModal(user) {
      this.clear();
      this.titulo = 'Editar Servidor';
      this.editar = true;
      this.userId = user.id;
      this.name = user.name;
      this.email = user.email;
      this.telefone = user.telefone;
      this.cpf = user.cpf;
      this.matricula = user.matricula;
      this.selectedGrupoPerfil = user.perfil.grupo_perfil_id;
      this.selectedPerfil = user.perfil_id;
      this.selectedSetor = user.setor_id;
      this.prefeituraId = user.prefeitura_id;

      const propertyNames = [
        'fiscal_administrativo',
        'gestor_convenio',
        'fiscal_tecnico',
      ];

      for (const propertyName of propertyNames) {
        if (user[propertyName]) {
          this.selectedFiscals.push(propertyName);
        }
      }
      await this.updateLocalProfiles(user.perfil.grupo_perfil_id);
    },
    openCreateModal() {
      this.clear();
      this.titulo = 'Novo Servidor';
      this.editar = false;
    },
    //UTILS
    payloadFactory() {
      const data = {
        name: this.name,
        email: this.email,
        password: this.password,
        cpf: this.cpf,
        matricula: this.matricula,
        telefone: this.telefone,
        perfil_id: this.selectedPerfil,
        fiscal_administrativo: this.selectedFiscals.includes(
          'fiscal_administrativo'
        ),
        fiscal_tecnico: this.selectedFiscals.includes('fiscal_tecnico'),
        gestor_convenio: this.selectedFiscals.includes('gestor_convenio'),
      };
      if (this.prefeituraId) {
        data.prefeitura_id = this.prefeituraId;
        data.setor_id = null;
      } else {
        data.prefeitura = null;
        data.prefeitura_id = null;
        data.setor_id = this.selectedSetor;
      }

      return data;
    },
    errorHandler(error) {
      const fields = [
        'name',
        'telefone',
        'cpf',
        'matricula',
        'password',
        'nova_senha',
        'email',
        'perfil_id',
        'prefeitura_id',
        'setor_id',
      ];

      for (const field of fields) {
        this.validationErrors[field] = error.response.data[field];
      }
    },
    clear() {
      this.userId = '';
      this.checkPassword = '';
      this.name = '';
      this.selectedGrupoPerfil = null;
      this.email = '';
      this.telefone = '';
      this.matricula = '';
      this.cpf = '';
      this.password = '';
      this.selectedPerfil = null;
      this.perfis = [];
      this.selectedSetor = null;
      this.setores = [];
      this.prefeituras = [];
      this.prefeituraId = '';
      this.selectedFiscals = [];
      this.validationErrors.name = null;
      this.validationErrors.telefone = null;
      this.validationErrors.cpf = null;
      this.validationErrors.matricula = null;
      this.validationErrors.password = null;
      this.validationErrors.nova_senha = null;
      this.validationErrors.email = null;
      this.validationErrors.perfil_id = null;
      this.validationErrors.prefeitura_id = null;
      this.validationErrors.setor_id = null;
    },
    isPrefeitura() {
      return JSON.parse(localStorage.getItem('user')).perfil.grupo_perfil_id == '2';
    },
    isSdc() {
      return JSON.parse(localStorage.getItem('user')).perfil.grupo_perfil_id == '1';
    },
    changePasswordInputType(inputNumber) {
      switch (inputNumber) {
        case 1:
          this.inputType = this.isInputTypePassword(1) ? 'text' : 'password';
          break;
        case 2:
          this.inputType2 = this.isInputTypePassword(2) ? 'text' : 'password';
          break;
      }
    },
    isPasswordsEqual() {
      if (this.checkPassword !== this.password) {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          title: 'As senhas são diferentes !',
          icon: 'error',
        });
        return false;
      }
      return true;
    },
    isInputTypePassword(inputNum) {
      return inputNum === 1
        ? this.inputType === 'password'
        : this.inputType2 === 'password';
    },
    saveButtonMethod(bvModalEvent) {
      bvModalEvent.preventDefault();
      return this.editar ? this.editarUsuario() : this.addUsuario();
    },
  },
  mounted() {
    this.filtros.prefeitura_id = localStorage.getItem('prefeitura_id');
    Promise.all([
      this.getUsuarios(this.filtros),
      this.getGrupoPerfis(),
      this.getPrefeituras(),
    ]).then(() => {
      this.debouncedPesquisar = _.debounce(this.getUsuarios, 300);
    });
  },
};
</script>

<style scoped>
h3 {
  color: #003e5d;
}

td,
th {
  vertical-align: middle !important;
  padding: 10px !important;
}
</style>
