<template>
  <main class="p-4 border h-100">
    <b-row class="mb-4">
      <b-col class="md-6">
        <h4 class="font-weight-bolder" v-once>Fiscais Administrativos</h4>
      </b-col>
      <b-col class="md-6">
        <b-form-checkbox v-model="isFiscalsFilterOn" switch>
          Fiscais sem processos vinculados
        </b-form-checkbox>
      </b-col>
    </b-row>
    <TabelaFiscais :data="toggleTableData" />
  </main>
</template>
<script>
import TabelaFiscais from '../../components/TabelaFiscais.vue';
export default {
  components: { TabelaFiscais },
  data() {
    return {
      tableData: [{ name: 'Carregando...', processos_count: 0 }],
      modalData: {
        name: '',
      },
      isFiscalsFilterOn: false,
      filteredTableData: [],
    };
  },
  methods: {
    toggleModal(fiscalName) {
      this.modalData.name = fiscalName;
      this.$bvModal.show('modal-administrativo');
    },
    async fetchFiscals() {
      try {
        const response = await this.$http.get(
          'fiscais?fiscal_administrativo=true'
        );
        this.tableData = response.data[0].map(fiscal => {
          const fiscalMapped = {
            id: fiscal.id,
            name: fiscal.name,
            processos_count: fiscal.processos_count,
          };
          return fiscalMapped;
        });
      } catch (error) {
        this.$swal({
          title:
            'Erro ao listar os Fiscais Administrativos! Tente recarregar a página!',
          icon: 'error',
        });
      }
    },
  },
  computed: {
    toggleTableData() {
      return this.isFiscalsFilterOn ? this.filteredTableData : this.tableData;
    },
  },
  async mounted() {
    await this.fetchFiscals();
    this.filteredTableData = this.tableData.filter(
      fiscal => fiscal.processos_count === 0
    );
  },
  beforeCreate() {
    //If user is not allowed to see this page, redirect to 'Minha Unidade'
    if (!this.$helper.verificaPermissao('visualizar_menu_fiscais')) {
      this.$router.push('/processos/minha-unidade');
    }
  },
};
</script>
