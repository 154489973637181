<template>
  <section>
    <div class="d-flex flex-row items-center justify-content-end" style="margin-bottom: 20px;">
      <b-button @click="abrirModalAdicionar()" type="button" style="background-color: #1B7E9F;">Adicionar Parcela</b-button>
    </div>
    <section style="overflow-x: auto;">
      <table class="table table-bordered" style="min-width: 100%;">
        <thead>
          <tr style="background-color: rgba(27, 126, 159, 0.3); color: rgba(55, 65, 81, 1);" v-if="form.cronograma && form.cronograma.length > 0">
            <th scope="col" class="text-center" style="color: #1F2937;">Item</th>
            <th scope="col" class="text-center" style="color: #1F2937;">Parcela</th>
            <th scope="col" class="text-center" style="color: #1F2937;">Período</th>
            <th scope="col" class="text-center" style="color: #1F2937;">Convenente</th>
            <th scope="col" class="text-center" style="color: #1F2937;">Concedente</th>
            <th scope="col" class="text-center" style="color: #1F2937;">Ações</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(cronograma, index) in form.cronograma">
            <tr style="background-color: #E5E7EB; color: #374151; font-weight: bold;">

              <td v-if="cronograma.itens.length > 0" style="display:flex; justify-content: center; text-align: center; white-space: nowrap; border: none;" scope="row">
                <div
                  @click="cronograma.open = !cronograma.open"
                  v-b-tooltip.hover
                  :title="cronograma.open ? 'Ocultar itens' : 'Expandir itens'"
                >
                  <b-icon style="cursor: pointer;" :icon="cronograma.open ? 'caret-down-fill' : 'caret-right-fill'"></b-icon>
                </div>
              </td>

              <td v-else style="display:flex; justify-content: center; text-align: center; white-space: nowrap; border: none;" scope="row">
                <div>
                  -
                </div>
              </td>

              <td class="text-center align-middle">Parcela {{index + 1}}</td>
              <td class="text-center align-middle">
                {{ formatDate(cronograma.periodo_inicial) }} -
                {{ formatDate(cronograma.periodo_final) }}
              </td>
              <td class="text-center align-middle">{{ cronograma.convenente }}</td>
              <td class="text-center align-middle">{{ cronograma.concedente }}</td>
              <td style="text-align: center; white-space: nowrap;">
                <!-- icone adicionar -->
                <svg v-b-modal.modal-subitem v-b-tooltip.hover @click="AbrirModalSubitem(cronograma)" title="Adicionar subitem" style="margin-right: 14px; cursor: pointer;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18ZM14 9H11V6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5C9.73479 5 9.48043 5.10536 9.2929 5.29289C9.10536 5.48043 9 5.73478 9 6V9H6C5.73479 9 5.48043 9.10536 5.2929 9.29289C5.10536 9.48043 5 9.73478 5 10C5 10.2652 5.10536 10.5196 5.2929 10.7071C5.48043 10.8946 5.73479 11 6 11H9V14C9 14.2652 9.10536 14.5196 9.2929 14.7071C9.48043 14.8946 9.73479 15 10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14V11H14C14.2652 11 14.5196 10.8946 14.7071 10.7071C14.8946 10.5196 15 10.2652 15 10C15 9.73478 14.8946 9.48043 14.7071 9.29289C14.5196 9.10536 14.2652 9 14 9Z" fill="#01455E"/>
                </svg>
                <!-- icone editar -->
                <svg @click="abrirModalEditar(index)" style="cursor: pointer; margin-right: 10px; " width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 11.9999C20.7348 11.9999 20.4804 12.1053 20.2929 12.2928C20.1054 12.4804 20 12.7347 20 12.9999V18.9999C20 19.2652 19.8946 19.5195 19.7071 19.707C19.5196 19.8946 19.2652 19.9999 19 19.9999H5C4.73478 19.9999 4.48043 19.8946 4.29289 19.707C4.10536 19.5195 4 19.2652 4 18.9999V4.99994C4 4.73472 4.10536 4.48037 4.29289 4.29283C4.48043 4.1053 4.73478 3.99994 5 3.99994H11C11.2652 3.99994 11.5196 3.89458 11.7071 3.70705C11.8946 3.51951 12 3.26516 12 2.99994C12 2.73472 11.8946 2.48037 11.7071 2.29283C11.5196 2.1053 11.2652 1.99994 11 1.99994H5C4.20435 1.99994 3.44129 2.31601 2.87868 2.87862C2.31607 3.44123 2 4.20429 2 4.99994V18.9999C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 21.9999 5 21.9999H19C19.7956 21.9999 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 18.9999V12.9999C22 12.7347 21.8946 12.4804 21.7071 12.2928C21.5196 12.1053 21.2652 11.9999 21 11.9999ZM6 12.7599V16.9999C6 17.2652 6.10536 17.5195 6.29289 17.707C6.48043 17.8946 6.73478 17.9999 7 17.9999H11.24C11.3716 18.0007 11.5021 17.9755 11.6239 17.9257C11.7457 17.8759 11.8566 17.8026 11.95 17.7099L18.87 10.7799L21.71 7.99994C21.8037 7.90698 21.8781 7.79637 21.9289 7.67452C21.9797 7.55266 22.0058 7.42195 22.0058 7.28994C22.0058 7.15793 21.9797 7.02722 21.9289 6.90536C21.8781 6.7835 21.8037 6.6729 21.71 6.57994L17.47 2.28994C17.377 2.19621 17.2664 2.12182 17.1446 2.07105C17.0227 2.02028 16.892 1.99414 16.76 1.99414C16.628 1.99414 16.4973 2.02028 16.3754 2.07105C16.2536 2.12182 16.143 2.19621 16.05 2.28994L13.23 5.11994L6.29 12.0499C6.19732 12.1434 6.12399 12.2542 6.07423 12.376C6.02446 12.4979 5.99924 12.6283 6 12.7599ZM16.76 4.40994L19.59 7.23994L18.17 8.65994L15.34 5.82994L16.76 4.40994ZM8 13.1699L13.93 7.23994L16.76 10.0699L10.83 15.9999H8V13.1699Z" fill="#01455E"/>
                </svg>
                <!-- icone excluir -->
                <svg @click="deletarParcela(index)" style="cursor: pointer;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM20 6H16V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2H11C10.2044 2 9.44129 2.31607 8.87868 2.87868C8.31607 3.44129 8 4.20435 8 5V6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H5V19C5 19.7956 5.31607 20.5587 5.87868 21.1213C6.44129 21.6839 7.20435 22 8 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V8H20C20.2652 8 20.5196 7.89464 20.7071 7.70711C20.8946 7.51957 21 7.26522 21 7C21 6.73478 20.8946 6.48043 20.7071 6.29289C20.5196 6.10536 20.2652 6 20 6ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM17 19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V8H17V19ZM14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11V17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18Z" fill="#DC2626"/>
                </svg>
              </td>
            </tr>

            <template v-if="cronograma.open" v-for="(itens, itemIndex) in cronograma.itens">
              <tr style="background-color: rgb(255,255, 255);">
                <td style="background-color: #e5e7eb7c; color: #374151; text-align: center; white-space: nowrap;font-weight: 600;">{{ (Number(itemIndex) + 1).toFixed(1) }}</td>
                <td colspan="4"style="text-align: center; white-space: nowrap;background-color: #e5e7eb7c; color: #374151;" >
                  <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; font-weight: 700;">
                     {{ itens.item }}
                  </div>
                </td>
                <td style="text-align: center; white-space: nowrap;background-color: #e5e7eb7c; color: #374151;">
                  <!-- EXCLUIR ITEM -->
                  <svg v-b-modal.modal-remover-item v-b-tooltip.hover title="Remover item" @click="removerItem(itens, cronograma.id)" style="margin-right: 10px; cursor: pointer;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM20 6H16V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2H11C10.2044 2 9.44129 2.31607 8.87868 2.87868C8.31607 3.44129 8 4.20435 8 5V6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H5V19C5 19.7956 5.31607 20.5587 5.87868 21.1213C6.44129 21.6839 7.20435 22 8 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V8H20C20.2652 8 20.5196 7.89464 20.7071 7.70711C20.8946 7.51957 21 7.26522 21 7C21 6.73478 20.8946 6.48043 20.7071 6.29289C20.5196 6.10536 20.2652 6 20 6ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM17 19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V8H17V19ZM14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11V17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18Z" fill="#DC2626"/>
                  </svg>
                </td>
              </tr>
              <template v-if="itens.subitens.length > 0" v-for="(value, subitemIndex) in itens.subitens">
                <tr style="background-color: rgb(255,255, 255);">
                  <td style="text-align: center;"><strong style="font-weight: 600;">{{ Number(itemIndex) + 1 }}.{{ Number(subitemIndex) + 1 }}</strong></td>
                  <td style="text-align: left; overflow-wrap: break-word; text-align: justify;" colspan="3">
                     {{ value.descricao }}
                  </td>
                  <td style="text-align: center; white-space: nowrap;">{{value.total}}</td>
                  <!-- EXCLUIR SUBITEM -->
                  <td style="text-align: center; white-space: nowrap;">
                    <svg
                      v-b-modal.modal-remover-subitem
                      v-b-tooltip.hover
                      title="Remover subitem"
                      @click="removerSubitem(value, cronograma.id)"
                      style="margin-right: 10px; cursor: pointer;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                    >
                    <path d="M10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM20 6H16V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2H11C10.2044 2 9.44129 2.31607 8.87868 2.87868C8.31607 3.44129 8 4.20435 8 5V6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H5V19C5 19.7956 5.31607 20.5587 5.87868 21.1213C6.44129 21.6839 7.20435 22 8 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V8H20C20.2652 8 20.5196 7.89464 20.7071 7.70711C20.8946 7.51957 21 7.26522 21 7C21 6.73478 20.8946 6.48043 20.7071 6.29289C20.5196 6.10536 20.2652 6 20 6ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM17 19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V8H17V19ZM14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11V17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18Z" fill="#DC2626"/>
                    </svg>
                  </td>
                </tr>
              </template>
            </template>
          </template>

          <tr style="background-color: #E5E7EB; color: #374151;" v-if="form.cronograma && form.cronograma.length > 0">
            <td class="text-center" style="font-weight: bold;" colspan="4">Total Concedente</td>
            <td class="text-center" style="font-weight: bold;" colspan="1">{{ totalConcedente }}</td>
            <td></td>
          </tr>
          <tr style="background-color: #E5E7EB; color: #374151;" v-if="form.cronograma && form.cronograma.length > 0">
            <td class="text-center" style="font-weight: bold;" colspan="4">Total Convenente</td>
            <td class="text-center" style="font-weight: bold;" colspan="1">{{ totalConvenente }}</td>
            <td></td>
          </tr>
          <tr style="background-color: #E5E7EB; color: #374151;" v-if="form.cronograma && form.cronograma.length > 0">
            <td class="text-center" style="font-weight: bold;" colspan="4">Total Geral</td>
            <td class="text-center" style="font-weight: bold;" colspan="1">{{ totalGeral }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </section>
    <!-- Editar ou Adicionar PARCELA -->
    <b-modal
      id="modal-1"
      :title="editar ? 'Editar Parcela' : 'Adicionar Parcela'"
      :hide-footer="true"
      title-class="font-weight-bold"
      size="lg"
    >
      <b-form>
        <div class="row">
          <div class="col-12">
            <b-form-group label="Valor Concedente" label-class="font-weight-bold">
              <b-input type="text" v-mask="currencyMask" v-model="novaParcela.concedente" placeholder="Inserir valor concedente">
              </b-input>
            </b-form-group>
          </div>

          <div class="col-12">
            <b-form-group label="Valor Convenente" label-class="font-weight-bold">
              <b-input type="text" v-mask="currencyMask" v-model="novaParcela.convenente" placeholder="Inserir valor convenente">
              </b-input>
            </b-form-group>
          </div>

          <div class="d-flex" style="width: 99%;">
            <b-form-group class="col-6" label="Período (início)" label-class="font-weight-bolder">
              <b-input
                required
                type="date"
                v-model="novaParcela.periodo_inicial"
              ></b-input>
            </b-form-group>

            <b-form-group class="col-6 mx-2" label="Período (fim)" label-class="font-weight-bolder">
              <b-input
                required
                v-model="novaParcela.periodo_final"
                type="date"
              ></b-input>
            </b-form-group>
          </div>
        </div>

        <div class="d-flex justify-content-center mt-4 mb-4">
          <b-button style="background-color: #99BBC8; border: none; font-weight: 600; border-radius: 6px; padding: 12px 18px 12px 18px; width: 160px; height: 46px;" @click="cancelar" class="mr-4">Cancelar</b-button>
          <b-button style="background-color: #1B7E9F; border: none; font-weight: 600; border-radius: 6px; padding: 12px 18px 12px 18px; width: 160px; height: 46px;" @click="editar ? editarParcela() : adicionarParcela()">{{editar ? 'Editar' : 'Adicionar'}}</b-button>
        </div>
      </b-form>
    </b-modal>
    <!-- ADICIONAR SUBITEM -->
    <b-modal
      id="modal-subitem"
      title="Adicionar Subitem"
      :hide-footer="true"
      title-class="font-weight-bold"
      size="lg"
      >
      <!-- selecionar item -->
      <!-- <div class="col-12"> -->
        <b-form-group label="Itens">
          <b-form-select
            required
            :options="listaDeItens"
            v-model="selectedItem"
            :value="selectedItem"
          />
        </b-form-group>
      <!-- </div> -->

      <!-- selecionar subitem -->
      <table class="table table-bordered" v-if="listaDeSubitens.length > 0">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col">Subitem</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="subitem in listaDeSubitens">
            <td>
              <b-form-checkbox
                :id="`${checkbox}-${subitem.id}`"
                v-model="subitem.checked"
                v-b-tooltip.hover
                :name="`${checkbox}-${subitem.id}`"
                title="Selecionar subitem"
              >
              </b-form-checkbox>
            </td>
            <td>{{subitem.descricao}}</td>
          </tr>
        </tbody>
      </table>

      <div class="d-flex justify-content-center mt-4 mb-4">
        <b-button style="background-color: #99BBC8; border: none; font-weight: 600; border-radius: 6px; padding: 12px 18px 12px 18px; width: 160px; height: 46px;" @click="cancelar" class="mr-4">Cancelar</b-button>
        <b-button
          style="background-color: #1B7E9F; border: none; font-weight: 600; border-radius: 6px; padding: 12px 18px 12px 18px; width: 160px; height: 46px;"
          @click="adicionarSubitem()">
          Adicionar
        </b-button>
      </div>
    </b-modal>
  </section>
</template>

<script>
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { watch} from 'vue';

const currencyMask = createNumberMask({
  prefix: 'R$ ',
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  thousandsSeparatorSymbol: '.',
  decimalSymbol: ',',
});

  export default {
    props:{
      form:{ type: Object },
      setForm: { type: Function }
    },
    data () {
      return {
        novaParcela: {
          concedente: '',
          convenente: '',
          periodo_inicial: '',
          periodo_final: '',
        },
        indiceEditando: null,
        editar: false,
        currencyMask,
        selectedItem: null,
        listaDeItens: [],
        listaDeSubitens: [],
        selectedCronograma: null,
        copiaItens: [],
      }
    },
    methods: {
      adicionarParcela(){
        this.editar = false;
        if (!this.novaParcela.periodo_inicial || !this.novaParcela.periodo_final || !this.novaParcela.concedente || !this.novaParcela.convenente) {
          this.$swal({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            title: 'Campos obrigatórios',
            text: 'Por favor, preencha todos os campos',
            icon: 'error'
          });
          return;
        }
        let novoPlano = {
          id: Date.now(),
          open: false,
          editar: false,
          concedente: this.novaParcela.concedente,
          convenente: this.novaParcela.convenente,
          periodo_inicial: this.novaParcela.periodo_inicial,
          periodo_final: this.novaParcela.periodo_final,
          itens: []
        };
        this.setForm({...this.form, cronograma: [...this.form.cronograma, novoPlano]});
        this.$bvModal.hide('modal-1');
      },
      adicionarSubitem(){
        const subitensMarcados = this.listaDeSubitens.filter(item => item?.checked)

        console.log('selected', this.selectedItem)

        if(!this.selectedItem || this.selectedItem == 'Selecione um item'){
          this.$swal({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            title: 'Erro',
            text: 'Por favor, selecione 1 item',
            icon: 'error'
          });

          return
        }

        if(subitensMarcados.length == 0 && this.form.itens.filter(value => value.id == this.selectedItem)[0].subitens.length > 0){
          this.$swal({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            title: 'Erro',
            text: 'Por favor, selecione 1 subitem',
            icon: 'error'
          });

          return
        }

        // this.copiaItens.filter(item => item.id == subitensMarcados[0].id)

        this.setForm({...this.form, cronograma: this.form.cronograma.map(parcela => {
         if(parcela.id == this.selectedCronograma.id){
          return {...parcela, itens: parcela.itens.filter(item => item.id == this.selectedItem).length == 0
            ? [...parcela.itens, {...this.form.itens.filter(value => value.id == this.selectedItem)[0], subitens: subitensMarcados}] :
            parcela.itens.map(value => value.id == this.selectedItem ? ({ ...value, subitens: [...value.subitens, ...subitensMarcados] }) : value)
          }
         }

         /* [...parcela.itens,
            parcela.itens.filter(item => item.id == this.selectedItem).length == 0 ?
            {...this.form.itens.filter(value => value.id == this.selectedItem)[0], subitens: subitensMarcados} :
            {...item, subitens: subitensMarcados}
          ] */

         return parcela
        })});

        this.$bvModal.hide('modal-subitem');
      },
      AbrirModalSubitem(cronograma){
        this.selectedCronograma = cronograma;

        this.selectedItem = null
      },
      editarParcela(){
        if (!this.novaParcela.periodo_inicial || !this.novaParcela.periodo_final || !this.novaParcela.concedente || !this.novaParcela.convenente) {
          this.$swal({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            title: 'Erro',
            text: 'Por favor, preencha todos os campos',
            icon: 'error'
          });
          return;
        }
        this.form.cronograma[this.indiceEditando].concedente = this.novaParcela.concedente;
        this.form.cronograma[this.indiceEditando].convenente = this.novaParcela.convenente;
        this.form.cronograma[this.indiceEditando].periodo_inicial = this.novaParcela.periodo_inicial;
        this.form.cronograma[this.indiceEditando].periodo_final = this.novaParcela.periodo_final;

        this.setForm(this.form);
        this.editar = false;
        this.$bvModal.hide('modal-1');
      },
      deletarParcela(index){
        this.$swal({
          title: 'Deseja realmente excluir esta parcela?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#17a2b8',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
            this.form.cronograma.splice(index, 1);
            this.setForm(this.form);
            this.$swal( {title:'Parcela excluída com sucesso!', icon: 'success'});
          }
          this.clear();
        });
      },
      removerSubitem(subitem, parcela_id){
        this.$swal({
          title: 'Deseja realmente excluir este subitem?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#17a2b8',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if(result.isConfirmed) {
            this.setForm({...this.form, cronograma: this.form.cronograma.map(parcela => {
              if(parcela.id == parcela_id){
                return {
                  ...parcela,
                  itens: parcela.itens.map(item => {
                    if ( item.id == subitem.item.id && item.subitens.filter(value => value.id != subitem.id).length > 0 ){
                      return {...item, subitens: item.subitens.filter(value => value.id != subitem.id)}
                    }

                    if ( item.id == subitem.item.id && item.subitens.filter(value => value.id != subitem.id).length == 0 ){
                      return null
                    }

                    return item
                  }).filter(val => val)
                }
              }
              return parcela
            })})
          }
        })
      },
      removerItem(item, parcela_id){
        this.$swal({
          title: 'Deseja realmente excluir este item?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#17a2b8',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if(result.isConfirmed) {
            this.setForm({...this.form, cronograma: this.form.cronograma.map(parcela => {
              if(parcela.id == parcela_id){
                return {
                  ...parcela, itens: parcela.itens.filter(itemDoCronograma => {
                    if ( itemDoCronograma.id != item.id ){
                      return itemDoCronograma
                    }
                  })
                }
              }
              return parcela
            })})
          }
        })
      },
      abrirModalAdicionar() {
        this.clear();
        this.$bvModal.show('modal-1');
      },
      abrirModalEditar(index) {
        this.indiceEditando = index;
        this.novaParcela.concedente = this.form.cronograma[index].concedente;
        this.novaParcela.convenente = this.form.cronograma[index].convenente;
        this.novaParcela.periodo_inicial = this.form.cronograma[index].periodo_inicial;
        this.novaParcela.periodo_final = this.form.cronograma[index].periodo_final;

        this.editar = true;
        this.$bvModal.show('modal-1');
      },
      clear(){
        this.novaParcela.concedente = '';
        this.novaParcela.convenente = '';
        this.novaParcela.periodo_inicial = '';
        this.novaParcela.periodo_final = '';
      },
      cancelar(){
        this.clear();
        this.$bvModal.hide('modal-1');
        this.$bvModal.hide('modal-subitem');
      },
      formatDate(dateString){
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      },
    },
    computed: {
      totalGeral() {
        let total = 0;
        this.form.cronograma.forEach(item => {
          const valorConcedente = parseFloat((item.concedente || '0').replace(/[^\d.,]/g, '').replace(/\./g, '').replace(',', '.')) || 0;
          const valorConvenente = parseFloat((item.convenente || '0').replace(/[^\d.,]/g, '').replace(/\./g, '').replace(',', '.')) || 0;

          total += valorConcedente + valorConvenente;
        });

        const formattedTotal = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total);
        return formattedTotal;
      },
      totalConcedente() {
        let total = 0;
        this.form.cronograma.forEach(item => {
          const valorConcedente = parseFloat((item.concedente || '0').replace(/[^\d.,]/g, '').replace(/\./g, '').replace(',', '.')) || 0;
          total += valorConcedente;
        });

        const formattedTotal = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total);
        return formattedTotal;
      },
      totalConvenente() {
        let total = 0;
        this.form.cronograma.forEach(item => {
          const valorConvenente = parseFloat((item.convenente || '0').replace(/[^\d.,]/g, '').replace(/\./g, '').replace(',', '.')) || 0;
          total += valorConvenente;
        });

        const formattedTotal = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total);
        return formattedTotal;
      },
    },
    mounted() {
      // this.copiaItens = this.form.itens;
      console.log(this.form.itens)

      const aux = this.form.cronograma.length > 0 ? this.form.cronograma.map(parcela => parcela.itens.map(item => ({
        id: Number(item.id),
        subitens: item.subitens.map(subitem => Number(subitem.id))
      }))) : []

      const aux2 = [].concat.apply([], aux);

      const map = new Map(aux2.map(({id, subitens}) => [id, { id, subitens: [] }]));

      for (let {id, subitens} of aux2) map.get(id).subitens.push(...[subitens].flat());

      const itens_nao_disponiveis = [...map.values()]

      // const itens_nao_disponiveis = aux3.filter(subitem => subitem.id == this.selectedItem).length > 0 ? aux3.filter(subitem => subitem.id == this.selectedItem)[0].subitens : []

      console.log(itens_nao_disponiveis)

      this.listaDeItens = [
        { value: 'Selecione um item', text: 'Selecione um item' },
        ...this.form.itens.map(item => {
          if(itens_nao_disponiveis.length == 0){
            return ({ value: item.id, text: item.item })
          }

          if(
            !itens_nao_disponiveis.map(available => Number(available.id)).includes(Number(item.id)) ||
            itens_nao_disponiveis.filter(subitem => subitem.id == item.id)[0].subitens.length < item.subitens.length
          ){
            return ({ value: item.id, text: item.item })
          }

          return null
        })
      ].filter(item => item)

      /* console.log([
        { value: 'Selecione um item', text: 'Selecione um item' },
        ...this.form.itens.filter(item => {
          if(!itens_nao_disponiveis.includes(Number(item.id))){
            return ({ value: item.id, text: item.item })
          }
        })
      ]) */

      watch(()=> this.selectedItem, () => {
        const aux = this.form.cronograma.map(parcela => parcela.itens.map(item => ({
          id: Number(item.id),
          subitens: item.subitens.map(subitem => Number(subitem.id))
        })))

        const aux2 = [].concat.apply([], aux);

        const map = new Map(aux2.map(({id, subitens}) => [id, { id, subitens: [] }]));

        for (let {id, subitens} of aux2) map.get(id).subitens.push(...[subitens].flat());

        // console.log([...map.values()]);

        const aux3 = [...map.values()]

        const itens_nao_disponiveis = aux3.filter(subitem => subitem.id == this.selectedItem).length > 0 ? aux3.filter(subitem => subitem.id == this.selectedItem)[0].subitens : []

        // console.log('itens_nao_disponiveis', itens_nao_disponiveis)

        // [498543543,95688956]

        this.listaDeSubitens = this.form.itens.filter(item => item.id == this.selectedItem).length > 0 ? this.form.itens.filter(item => item.id == this.selectedItem)[0].subitens.map(subitem => itens_nao_disponiveis.includes(Number(subitem.id)) ? null : ({...subitem, checked: false})).filter(value => value) : []
      })

      watch(()=> this.form.cronograma, () => {
        const aux = this.form.cronograma.map(parcela => parcela.itens.map(item => ({
          id: Number(item.id),
          subitens: item.subitens.map(subitem => Number(subitem.id))
        })))

        const aux2 = [].concat.apply([], aux);

        /* const itens_nao_disponiveis = aux2.map(item => aux2.filter(subitem => subitem.id == item.id).length > 0
          ? item.id == aux2.filter(subitem => subitem.id == item.id)[0].id ? ({ ...item, subitens:  })
          : item : item
        ) */

        // let itens_nao_disponiveis = [];

        /* for(let i=0; i<aux2.length; i++){
          let existing = itens_nao_disponiveis.filter(function(v, i) {
            return v.id == aux2[i].id;
          });
          if (existing.length) {
            let existingIndex = itens_nao_disponiveis.indexOf(existing[0]);
            itens_nao_disponiveis[existingIndex].subitens = itens_nao_disponiveis[existingIndex].subitens.concat(aux2[i].subitens);
          } else {
            aux2[i].subitens = [aux2[i].subitens];
            itens_nao_disponiveis.push(aux2[i]);
          }
        } */

        const map = new Map(aux2.map(({id, subitens}) => [id, { id, subitens: [] }]));

        for (let {id, subitens} of aux2) map.get(id).subitens.push(...[subitens].flat());

        // console.log([...map.values()]);

        const itens_nao_disponiveis = [...map.values()]

        /* aux2.forEach(function(item) {
          let existing = itens_nao_disponiveis.filter(function(v, i) {
            return v.id == item.id;
          });
          if (existing.length) {
            let existingIndex = itens_nao_disponiveis.indexOf(existing[0]);
            itens_nao_disponiveis[existingIndex].subitens = itens_nao_disponiveis[existingIndex].subitens.concat(item.subitens);
          } else {
            if (typeof item.subitens == 'number')
              item.subitens = [item.subitens];
            itens_nao_disponiveis.push(item);
          }
        }); */

        // console.log('oi', itens_nao_disponiveis)

        this.listaDeItens = [
          { value: 'Selecione um item', text: 'Selecione um item' },
          ...this.form.itens.map(item => {
            if(itens_nao_disponiveis.length == 0){
              return ({ value: item.id, text: item.item })
            }

            if(
              !itens_nao_disponiveis.map(available => Number(available.id)).includes(Number(item.id)) ||
              itens_nao_disponiveis.filter(subitem => subitem.id == item.id)[0].subitens.length < item.subitens.length
            ){
              return ({ value: item.id, text: item.item })
            }

            return null
          })
        ].filter(item => item)
      }, { deep: true})
    }
  }
</script>
