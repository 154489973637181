<template>
  <b-container
    v-if="$helper.verificaPermissao('visualizar_dados_processo') || $helper.verificaPermissao('visualizar_processos_setor_vinculado') || $helper.verificaPermissao('visualizar_processos_prefeitura_vinculada')"
    class="p-4 border h-100"
    fluid
  >
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h4>Processos</h4>
      <slot name="header"></slot>
    </div>
    <b-overlay :show="isLoading" rounded="sm">
      <div class="table-responsive">
        <table class="table table-hover table-sm">
          <thead>
            <tr>
              <th scope="col" :class="perfil === 'sdc' ? 'col-2' : 'col-3'">
                <b-form-input
                  v-model="filtros.protocolo"
                  class="form-rounded"
                  placeholder="Protocolo"
                  size="sm"
                  @input="debouncedPesquisar()"
                  style="border-radius: 7px"
                />
              </th>
              <th scope="col" class="col-4">
                <b-form-input
                  v-model="filtros.titulo"
                  class="form-rounded"
                  placeholder="Título"
                  size="sm"
                  @input="debouncedPesquisar()"
                  style="border-radius: 7px"
                />
              </th>
              <th scope="col" class="col-2">
                <b-form-input
                  v-model="filtros.prefeitura"
                  class="form-rounded"
                  placeholder="Prefeitura"
                  size="sm"
                  @input="debouncedPesquisar()"
                  style="border-radius: 7px"
                >
                </b-form-input>
              </th>
              <th v-if="perfil === 'sdc'" scope="col" class="text-center col-1">
                Solicitação
              </th>
              <th scope="col" class="col-2 text-center">Status</th>
              <th scope="col" class="col-1">
                <b-form-select
                  :options="['Resultados', '10', '20', '30', 50]"
                  v-model="filtros.limit"
                  @input="debouncedPesquisar()"
                ></b-form-select>
              </th>
            </tr>
          </thead>
          <tbody v-if="this.processos">
            <tr v-for="processo in processos" :key="processo.id">
              <th scope="row" style="vertical-align: middle">
                {{ processo.protocolo }}
              </th>
              <td>
                {{ processo.titulo }}
              </td>
              <td class="text-center">
                <span
                  v-b-tooltip.hover.left
                  :title="processo.prefeitura.nome"
                  >{{ processo.prefeitura.sigla }}</span
                >
              </td>
              <td v-if="perfil === 'sdc'" class="text-center">
                {{ timerSolicitacao(processo.solicitacoes_pendentes) }}
              </td>
              <td class="text-center">
                <span
                  :class="statusClass(processo.status)"
                  class="py-1 px-2 rounded-pill text-white font-weight-bolder"
                  >{{ processo.status }}</span
                >
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <button
                    v-b-tooltip.hover.left
                    class="btn btn-primary btn-sm"
                    title="Visualizar Processo"
                    data-testid="button-visualizar-processo"
                    v-b-modal.visualizar
                    @click="showProcesso(processo)"
                  >
                    <b-icon icon="eye-fill"></b-icon>
                  </button>

                  <label
                    v-if="
                      perfil === 'prefeitura' &&
                      isPlanoRecusado(processo)
                    "
                    v-b-tooltip.hover.left
                    title="Plano de Trabalho Recusado"
                    class="ml-3 p-0 mb-0"
                  >
                    <b-icon
                      icon="exclamation-diamond-fill"
                      variant="danger"
                    ></b-icon>
                  </label>

                  <label
                    v-if="
                      $helper.verificaPermissao(
                        'solicitar_documentacao_convenio'
                      ) &&
                      perfil === 'prefeitura' &&
                      processo.solicitacoes_pendentes.length > 0
                    "
                    v-b-tooltip.hover.left
                    :title="pluralText(processo.solicitacoes_pendentes)"
                    class="ml-3 p-0 mb-0"
                  >
                    <b-icon
                      icon="exclamation-diamond-fill"
                      variant="danger"
                    ></b-icon>
                  </label>

                  <label
                    v-if="perfil === 'sdc' && processo.inativo"
                    v-b-tooltip.hover.left
                    :title="
                      'Este processo está há ' +
                      processo.ultima_atualizacao +
                      ' dias sem atualizações'
                    "
                    class="ml-3 p-0 mb-0"
                  >
                    <b-icon
                      icon="exclamation-diamond-fill"
                      variant="danger"
                    ></b-icon>
                  </label>
                </div>
              </td>
              <!-- <modal-processo
                :processo="processo"
                :exibir="exibir"
                :perfil="perfil"
                @fechaModal="exibir = 0"
                @processo="refreshProcesso($event)"
                @refresh="getProcessos"
              /> -->
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <th>Sem registros</th>
            </tr>
          </tbody>
        </table>
      </div>
    </b-overlay>

    <footer class="d-flex justify-content-end align-items-center">
      <b-pagination
        v-if="allProcessos.total"
        v-model="filtros.page"
        :total-rows="allProcessos.total"
        :per-page="allProcessos.per_page"
        first-number
        last-number
        @input="(filtros.page = $event), getProcessos()"
        class="w-auto"
      />
    </footer>
  </b-container>
</template>

<script>
// import ModalProcesso from '@/components/ModalProcesso';
var _ = require('lodash');

export default {
  name: 'TabelaProcessos',
  components: {
    // ModalProcesso
  },
  props: {
    search: String,
  },
  data() {
    return {
      exibir: 0,
      sigla: '',
      isLoading: false,
      processos: {
        total: 0,
      },
      allProcessos: {
        total: 0,
        per_page: 0
      },
      perfil: '',
      filtros: {
        protocolo: '',
        titulo: '',
        eixo: '',
        subeixo: '',
        setor: '',
        prefeitura: '',
        prefeitura_id: '',
        sdc: '',
        setor_id: '',
        limit: 10,
        page: 1,
      },
    };
  },
  computed: {},
  methods: {
    isPlanoRecusado(item){
      const last_plano = item.planos_de_trabalho

      if(last_plano.length > 0){
        return last_plano.slice(-1)[0].is_aprovado == false
      }else{
        return false
      }
    },
    pluralText(solicitation) {
      return solicitation.length > 1
        ? solicitation.length + ' Solicitações Pendentes'
        : solicitation.length + ' Solicitação Pendente';
    },
    showProcesso(processo) {
      this.exibir = processo.id;
      this.$router.push({
        name: 'ModalProcesso',
        params: {
          processo: processo,
          exibir: processo.id,
          perfil: this.perfil,
      }});
    },
    getPerfil() {
      if (JSON.parse(localStorage.getItem('user')).perfil.grupo_perfil_id == '2') {
        this.perfil = 'prefeitura';
      } else if (JSON.parse(localStorage.getItem('user')).perfil.grupo_perfil_id == '1') {
        this.perfil = 'sdc';
      } else {
        this.perfil = '';
      }
    },
    timerSolicitacao(solicitacoes) {
      if (solicitacoes?.length > 0) {
        let timers = solicitacoes.map(solicitacao => {
          return solicitacao.timer;
        });
        timers = timers.filter(function (timer) {
          return timer != null;
        });
        return timers[0];
      } else {
        return 'Nenhuma';
      }
    },
    getProcessos() {
      this.filtros.arquivado = false;
      this.processos = {};

      this.isLoading = true;
      this.$http
        .get('processos', { params: this.filtros })
        .then(res => {
          this.allProcessos = res.data
          this.processos = res.data.data;
          this.isLoading = false;

          this.$store.commit('addProcessos', res.data.data)
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    },
    reloadProcessos() {
      this.filtros.arquivado = false;

      this.$http
        .get('processos', { params: this.filtros })
        .then(res => {
          this.allProcessos = res.data
          this.processos = res.data.data;

          this.$store.commit('addProcessos', res.data.data)
        })
        .catch(error => {
          console.log(error);
        });
    },
    refreshProcesso(processo) {
      this.isLoading = true;
      this.$http
        .get(`processos/${processo.id}`, {})
        .then(({ data }) => {
          processo = data;

          this.processos = this.processos.map(element => {
            if (element.id === processo.id) {
              return processo;
            }
            return element;
          });
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    },
    statusClass(status) {
      const statusDictionary = {
        encaminhado: 'bg-info',
        recebido: 'bg-success',
        'em análise': 'bg-warning',
        concluído: 'bg-success',
        salvo: 'bg-success',
      };
      return statusDictionary[status.toLowerCase()];
    },
  },
  created() {
    if (
      this.$helper.verificaPermissao(
        'visualizar_processos_prefeitura_vinculada'
      )
    ) {
      this.filtros.prefeitura_id = localStorage.getItem('prefeitura_id');
    }

    if (
      this.$helper.verificaPermissao('visualizar_processos_setor_vinculado')
    ) {
      this.filtros.setor =
        localStorage.getItem('setor_id') !== 'null'
          ? localStorage.getItem('setor_id')
          : '';
    }
    this.getPerfil();

    this.processos = this.$store.state.processos;

    if(this.$store.state.processos.length == 0){
      this.getProcessos(this.filtros);
    }else{
      this.reloadProcessos(this.filtros);
    }

    this.debouncedPesquisar = _.debounce(this.getProcessos, 300);
  },
};
</script>

<style scoped>
td,
th {
  vertical-align: middle !important;
  padding: 10px !important;
}
</style>
