<template>
  <section>
    <b-form>
      <h1 style="color: #374151; font-weight: 600; font-size: 20px; line-height: 30px; margin-top: 40px; margin-bottom: 32px;">Assinatura do titular da Convenente</h1>
      <div class="row">
        <div class="col-md-4">
          <b-form-group label="Nome Completo" label-class="font-weight-bold">
            <b-input type="text" v-model="form.cadastrais_proponente.nome_completo_titular_responsavel" disabled>
            </b-input>
          </b-form-group>
        </div>

        <div class="col-md-4">
          <b-form-group label="Cargo" label-class="font-weight-bold">
            <b-input type="text" v-model="form.cadastrais_proponente.cargo" disabled>
            </b-input>
          </b-form-group>
        </div>

        <div class="col-md-4">
          <b-form-group label="E-mail" label-class="font-weight-bold">
            <b-input type="email" v-model="form.cadastrais_proponente.email_institucional" disabled>
            </b-input>
          </b-form-group>
        </div>
      </div>
    </b-form>
  </section>
</template>

<script>
export default {
  props:{
    form:{ type: Object },
    setForm: { type: Function },
    processo: {this: Object}
  },
}
</script>
