<template>
  <div>
    <b-form>
      <b-form-group id="input-group-1" label="Número:" label-for="numero">
        <b-form-input
          id="numero"
          v-model="oficio.numero"
          placeholder="Ofício Nº. XX/XXXX-GAB/SDC"
          required
          type="text"
        ></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-1" label="Destinatário:" label-for="numero">
        <b-form-textarea
          id="destinatario"
          v-model="oficio.destinatarios"
          max-rows="6"
          placeholder="A Sua Excelência o Senhor X - Prefeito do Município de Vitória do Jari"
          required
          rows="3"
          type="text"
        ></b-form-textarea>
      </b-form-group>
      <b-form-group id="input-group-1" label="Assunto:" label-for="assunto">
        <b-form-input
          id="assunto"
          v-model="oficio.assunto"
          placeholder="Assunto"
          required
          type="text"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Signatários:" required>
        <multiselect
          v-model="signatarios"
          :close-on-select="true"
          :multiple="true"
          :options="usuarios"
          :searchable="true"
          :show-labels="false"
          :taggable="true"
          placeholder="Selecione..."
        ></multiselect>
      </b-form-group>
      <b-form-group id="input-group-1">
        <div class="form-group">
          <vue-editor
            v-model="mensagem"
            :editorToolbar="editorToolbar"
          ></vue-editor>
        </div>
      </b-form-group>

      <b-button variant="primary" @click="redigirDocumento()" class="px-5 py-2"
        >Salvar
      </b-button>
    </b-form>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },
  name: 'Oficio',
  data() {
    return {
      editorToolbar: [
        ['bold', 'italic', 'underline'],
        [{ color: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: 'center' }, { align: 'justify' }, { align: 'right' }],
      ],
      mensagem: '',
      oficio: {},
      usuarios: [],
      signatarios: [],
      usuariosIds: [],
    };
  },
  methods: {
    redigirDocumento() {
      let indexes = this.usuarios.map((value, index) => {
        if (this.signatarios.includes(value)) {
          return index;
        }
      });
      let signatariosIds = this.usuariosIds.filter((value, index) => {
        if (indexes.includes(index)) {
          return value;
        }
      });

      if(!this.mensagem || !this.oficio.assunto || !this.oficio.destinatarios || !this.oficio.numero || signatariosIds.length == 0){
        this.$swal({
          title: 'Preencha todos os campos!',
          icon: 'warning',
        });

        return null
      }

      let data = {
        mensagem: this.mensagem,
        destinatarios: this.oficio.destinatarios,
        assunto: this.oficio.assunto,
        numero: this.oficio.numero,
        tipo_documentos_id: this.tipo_documentos_id,
        processo: this.processo,
        processo_id: this.processo.id,
        signatarios: signatariosIds,
      };
      this.$http.post('documentos-redigidos', data).catch(error => {
        console.log(error.response);
      });

      this.$swal({ title: 'Documento redigido com sucesso!', icon: 'success' });
      this.$emit('refreshProcesso');

      this.mensagem = '';
      this.tipo_documentos_id = '';
      this.limparDados();
    },
    getUsuarios() {
      this.$http
        .get('servidores-sdc')
        .then(res => {
          res.data.forEach(user => {
            this.usuarios.push(user.name);
            this.usuariosIds.push(user.id);
          });
        })
        .catch(error => {
          console.log(error.response);
        });
    },
    limparDados() {
      this.mensagem = '';
      this.oficio.destinatarios = '';
      this.signatarios = [];
      this.oficio.assunto = '';
      this.oficio.numero = '';
    },
  },
  props: {
    tipo_documentos_id: {
      type: Number,
    },
    processo: {
      type: Object,
    },
  },
  created() {
    this.getUsuarios();
  },
};
</script>
