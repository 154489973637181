<template>
  <main>
    <router-link
      :to="`/eixos/${this.$route.params.id}/${this.$route.params.subid}/checklist`"
    >
      <b-icon icon="arrow-left" />Tópicos</router-link
    >
    <b-container fluid class="mt-4 p-4 border">
      <b-row class="mb-5">
        <b-col class="md-6">
          <h2 class="mb-0">Tópicos</h2>
        </b-col>
        <b-col class="md-6">
          <b-button
            v-b-modal.modal-newtopic
            :disabled="isLoading"
            variant="outline-primary"
            class="ml-1 float-right"
            >Cadastrar
          </b-button>
        </b-col>
      </b-row>

      <b-row v-if="topics.length === 0" class="col-12">
        <h5>Sem tópicos criados para este checklist!</h5>
      </b-row>

      <b-row v-for="(topic, index) in topics" :key="topic.id" class="mt-2">
        <b-row class="col-12 mb-2 no-gutters">
          <b-col lg="10">
            <h5>{{ topic.titulo }}</h5>
          </b-col>
          <b-col lg="2">
            <b-button
              v-b-modal.modal-1
              :disabled="isLoading"
              @click="activeTopicIdx = index"
              variant="outline-primary"
              class="btn-sm mr-4"
              >Novo Campo
            </b-button>
            <b-button
              class="btn btn-danger btn-sm"
              @click="deleteTopic(topic.id, index)"
              :disabled="isLoading"
            >
              <b-icon icon="trash"></b-icon>
            </b-button>
          </b-col>
        </b-row>

        <b-col class="col-12">
          <div class="table-responsive">
            <b-table
              :busy="isLoading"
              show-empty
              empty-text="Nenhum item cadastrado para o tópico!"
              striped
              fixed
              hover
              :fields="['descricao', { key: 'actions', label: 'Ações' }]"
              :items="topic.itens_checklist"
              caption-top
            >
              <template #cell(actions)="data">
                <b-button
                  class="btn btn-danger btn-sm"
                  @click="deleteItem(data.item, index)"
                  :disabled="isLoading"
                >
                  <b-icon icon="trash"></b-icon>
                </b-button>
              </template>
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{
                    width: field.key === 'actions' ? '10%' : '90%',
                  }"
                />
              </template>
            </b-table>
          </div>
        </b-col>
      </b-row>

      <b-modal
        id="modal-1"
        size="lg"
        title="Adicione os Documentos"
        title-class="font-weight-bold"
        @hide="clearState()"
      >
        <form v-if="isCreatingItem" class="mt-3">
          <b-form-group label="Item:" label-class="font-weight-bolder">
            <b-form-input
              v-model.trim="newItemDescription"
              required
              :disabled="isLoading"
            />
            <div class="mt-4 d-flex">
              <b-button
                variant="danger"
                @click="closeCampoAdd()"
                class="mr-2"
                :disabled="isLoading"
                >Cancelar</b-button
              >
              <b-button
                variant="primary"
                @click="addItemChecklist"
                :disabled="isLoading"
                >Salvar</b-button
              >
            </div>
          </b-form-group>
        </form>

        <div class="d-flex justify-content-between mt-4 mb-3">
          <h5>Lista de documentos</h5>
          <b-button
            variant="success"
            @click="isCreatingItem = true"
            :disabled="isLoading"
            >Adicionar item</b-button
          >
        </div>

        <hr />
        <div v-if="filteredItems.length > 0">
          <b-form-checkbox
            v-for="item in filteredItems"
            :key="item.id"
            :disabled="isLoading"
            :value="item.id"
            v-model="itensSelecionados"
            class="my-3"
          >
            {{ item.descricao }}
          </b-form-checkbox>
        </div>
        <br />

        <template #modal-footer>
          <b-btn
            :disabled="isLoading"
            variant="secondary"
            @click="$bvModal.hide('modal-1')"
            size="sm"
          >
            Cancelar</b-btn
          >
          <b-button
            variant="primary"
            size="sm"
            @click="enviarItens()"
            :disabled="isLoading"
          >
            Selecionar itens</b-button
          >
        </template>
      </b-modal>

      <b-modal id="modal-newtopic" :hide-footer="true">
        <template #modal-title>
          <h3 class="font-weight-bold">Novo Tópico</h3>
        </template>
        <b-form>
          <div class="row">
            <div class="col-12">
              <b-form-group label="Titulo:" label-class="font-weight-bolder">
                <b-form-input
                  :disabled="isLoading"
                  v-model.trim="title"
                  required
                  placeholder="Digite um título..."
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-12 mt-4 canhoto">
              <b-button
                :disabled="title.length === 0 || isLoading"
                variant="primary"
                @click="createTopic()"
                >Salvar</b-button
              >
            </div>
          </div>
        </b-form>
      </b-modal>
    </b-container>
  </main>
</template>

<script>
export default {
  name: 'Topics',
  data() {
    return {
      itensChecklist: [],
      itensSelecionados: [],
      activeTopicIdx: 0,
      title: '',
      isLoading: false,
      newItemDescription: '',
      isCreatingItem: false,
      topics: [],
    };
  },
  methods: {
    async deleteTopic(topicId, topicIndex) {
      this.$bvModal
        .msgBoxConfirm('Tem certeza que quer remover este tópico?', {
          title: 'Confirmação',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async accepted => {
          if (accepted) {
            this.isLoading = true;
            try {
              await this.$http.delete(`topicos/${topicId}`);
              this.topics.splice(topicIndex, 1);
              this.$swal({
                title: 'Tópico deletado com sucesso!',
                icon: 'success',
                timer: 3000,
              });
            } catch (error) {
              console.error(error.message);
            } finally {
              this.isLoading = false;
            }
          }
        });
    },
    async getTopics() {
      this.isLoading = true;
      try {
        const res = await this.$http.get('topicos', {
          params: { checklist_id: Number(this.$route.params.checkid) },
        });
        this.topics = res.data.topicos;
      } catch (error) {
        console.error('Erro ao listar tópicos');
      } finally {
        this.isLoading = false;
      }
    },
    async addItemChecklist() {
      const data = {
        descricao: this.newItemDescription,
      };
      this.isLoading = true;
      try {
        const res = await this.$http.post('item-checklist', data);
        this.itensChecklist.push(res.data);
        this.$swal({
          title: 'Documento cadastrado com sucesso!',
          icon: 'success',
        });
      } catch (error) {
        console.error('Erro ao adicionar o item ao checklist:', error);
      } finally {
        this.closeCampoAdd();
        this.isLoading = false;
      }
    },
    async deleteItem(item, topicIdx) {
      this.$bvModal
        .msgBoxConfirm('Tem certeza que quer remover este item deste tópico?', {
          title: 'Confirmação',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async accepted => {
          if (accepted) {
            this.isLoading = true;
            try {
              await this.$http.delete('topico-itens', {
                params: { topico_id: this.topics[topicIdx].id, item: item.id },
              });
              this.topics[topicIdx].itens_checklist = this.topics[
                topicIdx
              ].itens_checklist.filter(obj => obj.id !== item.id);
              this.$swal({
                title: 'Item deletado do tópico com sucesso!',
                icon: 'success',
                timer: 3000,
              });
            } catch (error) {
              console.error(error.message);
            } finally {
              this.isLoading = false;
            }
          }
        });
    },
    async enviarItens() {
      const data = {
        itens: this.itensSelecionados,
        topico_id: this.topics[this.activeTopicIdx].id,
      };

      this.isLoading = true;
      try {
        await this.$http.post('topico-itens', data);
        const itens = this.itensChecklist.filter(
          elem => this.itensSelecionados.indexOf(elem.id) !== -1
        );
        this.topics[this.activeTopicIdx].itens_checklist.push(...itens);
        this.$swal({
          title: 'Itens de checklist selecionados com sucesso!',
          icon: 'success',
          timer: 3000,
        });
        this.$bvModal.hide('modal-1');
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getItensChecklist() {
      this.isLoading = true;
      try {
        const response = await this.$http.get('item-checklist');
        this.itensChecklist = response.data;
      } catch (error) {
        console.log('Erro ao renderizar os itens da checklist:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async createTopic() {
      this.isLoading = true;
      try {
        const data = {
          titulo: this.title,
          checklist_id: Number(this.$route.params.checkid),
        };
        const response = await this.$http.post('topicos', data);
        this.topics.push(response.data.topico);
        this.$bvModal.hide('modal-newtopic');
      } catch (error) {
        console.error(error.message);
      } finally {
        this.isLoading = false;
      }
    },
    clearState() {
      this.itensSelecionados = [];
      this.title = '';
      this.newItemDescription = '';
      this.isCreatingItem = false;
    },
    closeCampoAdd() {
      this.isCreatingItem = false;
      this.newItemDescription = '';
    },
  },
  created() {
    Promise.all([this.getItensChecklist(), this.getTopics()]);
  },
  computed: {
    filteredItems() {
      return this.itensChecklist.filter(checklist => {
        if (this.topics[this.activeTopicIdx]) {
          return !this.topics[this.activeTopicIdx].itens_checklist.some(
            obj => obj.id === checklist.id
          );
        } else {
          return [];
        }
      });
    },
  },
};
</script>

<style scoped></style>
