<template>
  <div>
    <div v-if="isItemInCronograma()" class="alert alert-danger" role="alert">
      Você possui itens adicionados em parcelas no cronograma de desembolso, qualquer alteração irá impactar na reinserção dos itens nas parcelas!
    </div>

    <b-form-group style="width: 100%;" id="input-group-3" label="Tipo de Tabela" label-for="input-table">
      <b-form-select
        id="input-table"
        v-model="tipo_tabela"
        :options="tipos_tabela"
        required
        :disabled="data.length > 0"
      ></b-form-select>
    </b-form-group>

    <div v-if="tipo_tabela" class="d-flex flex-row items-center justify-content-end" style="margin-top: 20px; margin-bottom: 20px;">
      <b-button v-b-modal.modal-item @click="showNovoItem" type="button" variant="info">Adicionar Item</b-button>
    </div>

    <div class="table-responsive">
      <table v-if="tipo_tabela" class="table table-bordered">
        <thead style="background-color: rgba(27, 126, 159, 0.3); color: rgba(55, 65, 81, 1);">
          <tr>
            <th scope="col" style="color: #1F2937; text-align: center; white-space: nowrap;">Item</th>
            <th v-if="tipo_tabela == 'Serviço'" scope="col" style="color: #1F2937; text-align: center; white-space: nowrap;">Código SINAP</th>
            <th scope="col" style="color: #1F2937; text-align: center; white-space: nowrap;">Descrição dos Serviços</th>
            <th scope="col" style="color: #1F2937; text-align: center; white-space: nowrap;">Unidade</th>
            <th scope="col" style="color: #1F2937; text-align: center; white-space: nowrap;">Quant.</th>
            <th scope="col" style="color: #1F2937; text-align: center; white-space: nowrap;">Valor Unit.</th>
            <th scope="col" style="color: #1F2937; text-align: center; white-space: nowrap;">Total</th>
            <th scope="col" style="color: #1F2937; text-align: center; white-space: nowrap;">Ações</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in data">
            <tr style="background-color: rgba(229, 231, 235, 1); font-weight: bold; color: rgba(55, 65, 81, 1);">
              <td v-if="item.subitens.length > 0" @click="item.open = !item.open" v-b-tooltip.hover :title="item.open ? 'Ocultar subitens' : 'Expandir subitens'" style="cursor: pointer; text-align: right; white-space: nowrap;" scope="row">
                <b-icon style="cursor: pointer; margin-right: 15px;" :icon="item.open ? 'caret-down-fill' : 'caret-right-fill'"></b-icon><span>{{ Number(Number(index) + 1).toFixed(1) }}</span>
              </td>
              <td style="cursor: pointer; text-align: center; white-space: nowrap;" scope="row" v-else>
                <span>{{ Number(Number(index) + 1).toFixed(1) }}</span>
              </td>
              <td style="text-align: center; white-space: nowrap;" :colspan="tipo_tabela == 'Serviço' ? '5' : '4'">{{ item.item }}</td>
              <td style="text-align: center; white-space: nowrap;">{{ money_mask(Number(only_price_to_number(String(item.total).split('R$')[1])).toFixed(2)) }}</td>
              <td style="text-align: center; white-space: nowrap;">
                <svg v-b-modal.modal-subitem v-b-tooltip.hover @click="() => showNovoSubItem(item)" title="Adicionar subitem" style="margin-right: 14px; cursor: pointer;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7363 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C18 12.1217 17.1572 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18ZM14 9H11V6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5C9.73479 5 9.48043 5.10536 9.2929 5.29289C9.10536 5.48043 9 5.73478 9 6V9H6C5.73479 9 5.48043 9.10536 5.2929 9.29289C5.10536 9.48043 5 9.73478 5 10C5 10.2652 5.10536 10.5196 5.2929 10.7071C5.48043 10.8946 5.73479 11 6 11H9V14C9 14.2652 9.10536 14.5196 9.2929 14.7071C9.48043 14.8946 9.73479 15 10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14V11H14C14.2652 11 14.5196 10.8946 14.7071 10.7071C14.8946 10.5196 15 10.2652 15 10C15 9.73478 14.8946 9.48043 14.7071 9.29289C14.5196 9.10536 14.2652 9 14 9Z" fill="#01455E"/>
                </svg>

                <svg v-b-modal.modal-item v-b-tooltip.hover @click="() => showEditarItem(item)" title="Editar item" style="margin-right: 10px; cursor: pointer;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 11.9999C20.7348 11.9999 20.4804 12.1053 20.2929 12.2928C20.1054 12.4804 20 12.7347 20 12.9999V18.9999C20 19.2652 19.8946 19.5195 19.7071 19.707C19.5196 19.8946 19.2652 19.9999 19 19.9999H5C4.73478 19.9999 4.48043 19.8946 4.29289 19.707C4.10536 19.5195 4 19.2652 4 18.9999V4.99994C4 4.73472 4.10536 4.48037 4.29289 4.29283C4.48043 4.1053 4.73478 3.99994 5 3.99994H11C11.2652 3.99994 11.5196 3.89458 11.7071 3.70705C11.8946 3.51951 12 3.26516 12 2.99994C12 2.73472 11.8946 2.48037 11.7071 2.29283C11.5196 2.1053 11.2652 1.99994 11 1.99994H5C4.20435 1.99994 3.44129 2.31601 2.87868 2.87862C2.31607 3.44123 2 4.20429 2 4.99994V18.9999C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 21.9999 5 21.9999H19C19.7956 21.9999 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 18.9999V12.9999C22 12.7347 21.8946 12.4804 21.7071 12.2928C21.5196 12.1053 21.2652 11.9999 21 11.9999ZM6 12.7599V16.9999C6 17.2652 6.10536 17.5195 6.29289 17.707C6.48043 17.8946 6.73478 17.9999 7 17.9999H11.24C11.3716 18.0007 11.5021 17.9755 11.6239 17.9257C11.7457 17.8759 11.8566 17.8026 11.95 17.7099L18.87 10.7799L21.71 7.99994C21.8037 7.90698 21.8781 7.79637 21.9289 7.67452C21.9797 7.55266 22.0058 7.42195 22.0058 7.28994C22.0058 7.15793 21.9797 7.02722 21.9289 6.90536C21.8781 6.7835 21.8037 6.6729 21.71 6.57994L17.47 2.28994C17.377 2.19621 17.2664 2.12182 17.1446 2.07105C17.0227 2.02028 16.892 1.99414 16.76 1.99414C16.628 1.99414 16.4973 2.02028 16.3754 2.07105C16.2536 2.12182 16.143 2.19621 16.05 2.28994L13.23 5.11994L6.29 12.0499C6.19732 12.1434 6.12399 12.2542 6.07423 12.376C6.02446 12.4979 5.99924 12.6283 6 12.7599ZM16.76 4.40994L19.59 7.23994L18.17 8.65994L15.34 5.82994L16.76 4.40994ZM8 13.1699L13.93 7.23994L16.76 10.0699L10.83 15.9999H8V13.1699Z" fill="#01455E"/>
                </svg>

                <svg v-b-modal.modal-remover-subitem v-b-tooltip.hover title="Remover item" @click="() => {
                  selected = { isItem: true, ...item }
                }" style="margin-right: 10px; cursor: pointer;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM20 6H16V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2H11C10.2044 2 9.44129 2.31607 8.87868 2.87868C8.31607 3.44129 8 4.20435 8 5V6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H5V19C5 19.7956 5.31607 20.5587 5.87868 21.1213C6.44129 21.6839 7.20435 22 8 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V8H20C20.2652 8 20.5196 7.89464 20.7071 7.70711C20.8946 7.51957 21 7.26522 21 7C21 6.73478 20.8946 6.48043 20.7071 6.29289C20.5196 6.10536 20.2652 6 20 6ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM17 19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V8H17V19ZM14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11V17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18Z" fill="#DC2626"/>
                </svg>
              </td>
            </tr>

            <tr v-if="item.open" v-for="subitem in item.subitens" style="background-color: rgb(255,255, 255);">
              <td style="text-align: right;white-space: nowrap;" scope="row">{{ Number(index) + 1 }}.{{ Number(subitem.index + 1) }}</td>
              <td v-if="tipo_tabela == 'Serviço'" style="text-align: center;">{{ subitem.sinap }}</td>
              <td style="text-align: center; white-space: nowrap;">{{ subitem.descricao }}</td>
              <td style="text-align: center; white-space: nowrap;">{{ subitem.unidade }}</td>
              <td style="text-align: center; white-space: nowrap;">{{ subitem.quantidade }}</td>
              <td style="text-align: center; white-space: nowrap;">{{ subitem.valor_unitario }}</td>
              <td style="text-align: center; white-space: nowrap;">{{ subitem.total }}</td>
              <td style="text-align: center; white-space: nowrap;">
                <svg v-b-modal.modal-subitem v-b-tooltip.hover title="Editar subitem" @click="() => showEditarSubItem(subitem)" style="margin-right: 10px; cursor: pointer;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 11.9999C20.7348 11.9999 20.4804 12.1053 20.2929 12.2928C20.1054 12.4804 20 12.7347 20 12.9999V18.9999C20 19.2652 19.8946 19.5195 19.7071 19.707C19.5196 19.8946 19.2652 19.9999 19 19.9999H5C4.73478 19.9999 4.48043 19.8946 4.29289 19.707C4.10536 19.5195 4 19.2652 4 18.9999V4.99994C4 4.73472 4.10536 4.48037 4.29289 4.29283C4.48043 4.1053 4.73478 3.99994 5 3.99994H11C11.2652 3.99994 11.5196 3.89458 11.7071 3.70705C11.8946 3.51951 12 3.26516 12 2.99994C12 2.73472 11.8946 2.48037 11.7071 2.29283C11.5196 2.1053 11.2652 1.99994 11 1.99994H5C4.20435 1.99994 3.44129 2.31601 2.87868 2.87862C2.31607 3.44123 2 4.20429 2 4.99994V18.9999C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 21.9999 5 21.9999H19C19.7956 21.9999 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 18.9999V12.9999C22 12.7347 21.8946 12.4804 21.7071 12.2928C21.5196 12.1053 21.2652 11.9999 21 11.9999ZM6 12.7599V16.9999C6 17.2652 6.10536 17.5195 6.29289 17.707C6.48043 17.8946 6.73478 17.9999 7 17.9999H11.24C11.3716 18.0007 11.5021 17.9755 11.6239 17.9257C11.7457 17.8759 11.8566 17.8026 11.95 17.7099L18.87 10.7799L21.71 7.99994C21.8037 7.90698 21.8781 7.79637 21.9289 7.67452C21.9797 7.55266 22.0058 7.42195 22.0058 7.28994C22.0058 7.15793 21.9797 7.02722 21.9289 6.90536C21.8781 6.7835 21.8037 6.6729 21.71 6.57994L17.47 2.28994C17.377 2.19621 17.2664 2.12182 17.1446 2.07105C17.0227 2.02028 16.892 1.99414 16.76 1.99414C16.628 1.99414 16.4973 2.02028 16.3754 2.07105C16.2536 2.12182 16.143 2.19621 16.05 2.28994L13.23 5.11994L6.29 12.0499C6.19732 12.1434 6.12399 12.2542 6.07423 12.376C6.02446 12.4979 5.99924 12.6283 6 12.7599ZM16.76 4.40994L19.59 7.23994L18.17 8.65994L15.34 5.82994L16.76 4.40994ZM8 13.1699L13.93 7.23994L16.76 10.0699L10.83 15.9999H8V13.1699Z" fill="#01455E"/>
                </svg>

                <svg v-b-modal.modal-remover-subitem v-b-tooltip.hover title="Remover subitem" @click="() => {
                  selected = { isItem: false, ...subitem }
                }" style="margin-right: 10px; cursor: pointer;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM20 6H16V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2H11C10.2044 2 9.44129 2.31607 8.87868 2.87868C8.31607 3.44129 8 4.20435 8 5V6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H5V19C5 19.7956 5.31607 20.5587 5.87868 21.1213C6.44129 21.6839 7.20435 22 8 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V8H20C20.2652 8 20.5196 7.89464 20.7071 7.70711C20.8946 7.51957 21 7.26522 21 7C21 6.73478 20.8946 6.48043 20.7071 6.29289C20.5196 6.10536 20.2652 6 20 6ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM17 19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V8H17V19ZM14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11V17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18Z" fill="#DC2626"/>
                </svg>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <table v-if="tipo_tabela" class="table table-bordered" style="margin-top: 50px;">
      <tbody>
        <tr>
          <td style="text-align: center;font-weight: bold; color: rgba(55, 65, 81, 1);">Total sem BDI</td>
          <td style="text-align: center;font-weight: bold; color: rgba(55, 65, 81, 1);">{{ getValorTotalItens() }}</td>
        </tr>
        <tr>
          <td style="text-align: center;font-weight: bold; color: rgba(55, 65, 81, 1);">Total do BDI - 25%</td>
          <td style="text-align: center;font-weight: bold; color: rgba(55, 65, 81, 1);">{{ getValorTotalBDI() }}</td>
        </tr>
        <tr>
          <td style="text-align: center;font-weight: bold; color: rgba(55, 65, 81, 1);">Total Geral</td>
          <td style="text-align: center;font-weight: bold; color: rgba(55, 65, 81, 1);">{{ getValorTotalItensComBDI() }}</td>
        </tr>
      </tbody>
    </table>

    <b-modal
      id="modal-item"
      title="Adicionar Item"
      :hide-footer="true"
      title-class="font-weight-bold"
      size="lg"
    >
      <template #modal-header="{ close }">
        <h5 class="font-weight-bold mt-1">Adicionar Item</h5>

        <button @click="closeModal()" type="button" aria-label="Close" class="close">×</button>
      </template>

      <b-form v-if="!success" @submit="saveItem">
        <div class="row">
          <div class="col-12">
            <b-form-group label="Descrição" label-class="font-weight-bolder">
              <b-form-textarea
                required
                v-model="item.item"
                placeholder="Descrição"
                @input="event => item.item = String(event).toUpperCase()"
              ></b-form-textarea>
            </b-form-group>
          </div>

          <div class="col-12">
            <b-form-group label="Valor Total" label-class="font-weight-bolder">
              <b-form-input
                required
                v-model="item.total"
                v-mask="currencyMask"
                placeholder="R$ 0,00"
              ></b-form-input>
            </b-form-group>
          </div>

          <div v-if="tipo_tabela == 'Aquisição'" class="col-12">
            <b-form-group label="Período (início)" label-class="font-weight-bolder">
              <b-form-input
                required
                type="date"
                v-model="item.periodo_inicial"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Período (fim)" label-class="font-weight-bolder">
              <b-form-input
                required
                v-model="item.periodo_final"
                type="date"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-center align-items-center col-12 mt-4 mb-4">
            <b-button
              @click="$bvModal.hide('modal-item')"
              variant="primary"
              class="float-right mr-3"
              type="button"
              style="background-color: rgba(153, 187, 200, 1); border: none; width: 150px; height: 40px;"
              >Cancelar</b-button
            >

            <b-button
              variant="primary"
              class="float-right"
              type="submit"
              style="background-color: rgba(27, 126, 159, 1); border: none; width: 150px; height: 40px;"
              >Salvar</b-button
            >
          </div>
        </div>
      </b-form>

      <div v-if="success">
        <div style="flex-direction: column; padding: 20px;" class="d-flex justify-content-center align-items-center">
          <h1 style="font-size: 24px; color: rgba(22, 163, 74, 1); margin-bottom: 20px;">Item adicionado com sucesso!</h1>

          <svg style="margin-bottom: 20px;"width="80" height="80" viewBox="0 0 152 152" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M96.672 51.604L64.068 84.284L51.528 71.744C50.8467 70.9484 50.0083 70.3022 49.0654 69.8461C48.1225 69.3899 47.0955 69.1335 46.0488 69.0931C45.0022 69.0527 43.9584 69.229 42.9832 69.6111C42.0079 69.9932 41.1221 70.5728 40.3815 71.3134C39.6408 72.0541 39.0613 72.9399 38.6792 73.9151C38.2971 74.8904 38.1207 75.9341 38.1612 76.9808C38.2016 78.0275 38.4579 79.0545 38.9141 79.9974C39.3703 80.9402 40.0164 81.7787 40.812 82.46L58.672 100.396C59.3822 101.1 60.2244 101.658 61.1503 102.036C62.0763 102.414 63.0678 102.606 64.068 102.6C66.0618 102.592 67.9724 101.8 69.388 100.396L107.388 62.396C108.1 61.6895 108.666 60.8489 109.052 59.9228C109.437 58.9966 109.636 58.0033 109.636 57C109.636 55.9967 109.437 55.0033 109.052 54.0772C108.666 53.1511 108.1 52.3105 107.388 51.604C105.964 50.1885 104.038 49.394 102.03 49.394C100.022 49.394 98.096 50.1885 96.672 51.604ZM76 0C60.9686 0 46.2748 4.45732 33.7767 12.8083C21.2786 21.1593 11.5374 33.0289 5.78519 46.916C0.0329296 60.8032 -1.47212 76.0843 1.46035 90.8268C4.39283 105.569 11.6311 119.111 22.2599 129.74C32.8887 140.369 46.4306 147.607 61.1732 150.54C75.9157 153.472 91.1968 151.967 105.084 146.215C118.971 140.463 130.841 130.721 139.192 118.223C147.543 105.725 152 91.0314 152 76C152 66.0195 150.034 56.1368 146.215 46.916C142.395 37.6953 136.797 29.3171 129.74 22.2599C122.683 15.2026 114.305 9.60451 105.084 5.78515C95.8632 1.9658 85.9805 0 76 0ZM76 136.8C63.9749 136.8 52.2199 133.234 42.2214 126.553C32.2228 119.873 24.43 110.377 19.8282 99.2671C15.2263 88.1574 14.0223 75.9325 16.3683 64.1385C18.7143 52.3445 24.5049 41.5109 33.0079 33.0079C41.511 24.5049 52.3445 18.7142 64.1385 16.3683C75.9326 14.0223 88.1574 15.2263 99.2672 19.8281C110.377 24.4299 119.873 32.2228 126.553 42.2213C133.234 52.2198 136.8 63.9749 136.8 76C136.8 92.1251 130.394 107.59 118.992 118.992C107.59 130.394 92.1252 136.8 76 136.8Z" fill="#16A34A"/>
          </svg>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-subitem"
      title="Adicionar Subitem"
      :hide-footer="true"
      title-class="font-weight-bold"
      size="lg"
    >
      <template #modal-header="{ close }">
        <h5 class="font-weight-bold mt-1">Adicionar Subitem</h5>

        <button @click="closeModal()" type="button" aria-label="Close" class="close">×</button>
      </template>

      <b-form v-if="!success" @submit="saveSubItem">
        <div class="row">
          <div v-if="tipo_tabela == 'Serviço'" class="col-12">
            <b-form-group label="Código SINAP" label-class="font-weight-bolder">
              <b-form-input
                type="text"
                min="0"
                oninput="this.value = this.value.replace(/[^0-9/]/g, '').replace(/(\..*?)\..*/g, '$1');"
                v-model="subitem.sinap"
                required
                placeholder="Insira o código SINAP"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-12">
            <b-form-group label="Descrição" label-class="font-weight-bolder">
              <b-form-textarea
                required
                placeholder="Descrição"
                v-model="subitem.descricao"
                @input="event => subitem.descricao = String(event).toUpperCase()"
              ></b-form-textarea>
            </b-form-group>
          </div>

          <div class="col-6">
            <b-form-group label="Unidade" label-class="font-weight-bolder">
              <b-form-select
                id="input-table"
                v-model="subitem.unidade"
                :options="tipos_unidade"
                required
              ></b-form-select>
            </b-form-group>

            <b-form-group label="Quantidade" label-class="font-weight-bolder">
              <b-form-input
                required
                type="number"
                min="0"
                placeholder="0"
                v-model="subitem.quantidade"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-6">
            <b-form-group label="Valor por Unidade" label-class="font-weight-bolder">
              <b-form-input
                required
                placeholder="R$ 0,00"
                @input="event => subitem.total = price_to_number(event)"
                v-model="subitem.valor_unitario"
                v-mask="currencyMask"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Valor Total" label-class="font-weight-bolder">
              <b-form-input
                required
                placeholder="R$ 0,00"
                disabled
                v-model="subitem.total"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-center align-items-center col-12 mt-4 mb-4">
            <b-button
              @click="$bvModal.hide('modal-subitem')"
              variant="primary"
              type="button"
              class="float-right mr-3"
              style="background-color: rgba(153, 187, 200, 1); border: none; width: 150px; height: 40px;"
              >Cancelar</b-button
            >

            <b-button
              variant="primary"
              class="float-right"
              type="submit"
              style="background-color: rgba(27, 126, 159, 1); border: none; width: 150px; height: 40px;"
              >Salvar</b-button
            >
          </div>
        </div>
      </b-form>

      <div v-if="success">
        <div style="flex-direction: column; padding: 20px;" class="d-flex justify-content-center align-items-center">
          <h1 style="font-size: 24px; color: rgba(22, 163, 74, 1); margin-bottom: 20px;">Subitem adicionado com sucesso!</h1>

          <svg style="margin-bottom: 20px;"width="80" height="80" viewBox="0 0 152 152" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M96.672 51.604L64.068 84.284L51.528 71.744C50.8467 70.9484 50.0083 70.3022 49.0654 69.8461C48.1225 69.3899 47.0955 69.1335 46.0488 69.0931C45.0022 69.0527 43.9584 69.229 42.9832 69.6111C42.0079 69.9932 41.1221 70.5728 40.3815 71.3134C39.6408 72.0541 39.0613 72.9399 38.6792 73.9151C38.2971 74.8904 38.1207 75.9341 38.1612 76.9808C38.2016 78.0275 38.4579 79.0545 38.9141 79.9974C39.3703 80.9402 40.0164 81.7787 40.812 82.46L58.672 100.396C59.3822 101.1 60.2244 101.658 61.1503 102.036C62.0763 102.414 63.0678 102.606 64.068 102.6C66.0618 102.592 67.9724 101.8 69.388 100.396L107.388 62.396C108.1 61.6895 108.666 60.8489 109.052 59.9228C109.437 58.9966 109.636 58.0033 109.636 57C109.636 55.9967 109.437 55.0033 109.052 54.0772C108.666 53.1511 108.1 52.3105 107.388 51.604C105.964 50.1885 104.038 49.394 102.03 49.394C100.022 49.394 98.096 50.1885 96.672 51.604ZM76 0C60.9686 0 46.2748 4.45732 33.7767 12.8083C21.2786 21.1593 11.5374 33.0289 5.78519 46.916C0.0329296 60.8032 -1.47212 76.0843 1.46035 90.8268C4.39283 105.569 11.6311 119.111 22.2599 129.74C32.8887 140.369 46.4306 147.607 61.1732 150.54C75.9157 153.472 91.1968 151.967 105.084 146.215C118.971 140.463 130.841 130.721 139.192 118.223C147.543 105.725 152 91.0314 152 76C152 66.0195 150.034 56.1368 146.215 46.916C142.395 37.6953 136.797 29.3171 129.74 22.2599C122.683 15.2026 114.305 9.60451 105.084 5.78515C95.8632 1.9658 85.9805 0 76 0ZM76 136.8C63.9749 136.8 52.2199 133.234 42.2214 126.553C32.2228 119.873 24.43 110.377 19.8282 99.2671C15.2263 88.1574 14.0223 75.9325 16.3683 64.1385C18.7143 52.3445 24.5049 41.5109 33.0079 33.0079C41.511 24.5049 52.3445 18.7142 64.1385 16.3683C75.9326 14.0223 88.1574 15.2263 99.2672 19.8281C110.377 24.4299 119.873 32.2228 126.553 42.2213C133.234 52.2198 136.8 63.9749 136.8 76C136.8 92.1251 130.394 107.59 118.992 118.992C107.59 130.394 92.1252 136.8 76 136.8Z" fill="#16A34A"/>
          </svg>
        </div>
      </div>
    </b-modal>

    <b-modal
      id="modal-remover-subitem"
      title=""
      :hide-footer="true"
      title-class="font-weight-bold"
    >
      <b-form>
        <div>
          <div class="d-flex justify-content-center align-items-center">
            <h1 style="font-size: 18px;">Tem certeza que deseja excluir esse item?</h1>
          </div>
          <div class="d-flex justify-content-center align-items-center col-12 mt-4" style="margin-bottom: 40px;">
            <b-button
              @click="$bvModal.hide('modal-remover-subitem')"
              variant="primary"
              class="float-right mr-3"
              style="background-color: rgba(153, 187, 200, 1); border: none;"
              >Não</b-button
            >

            <b-button
              variant="primary"
              class="float-right"
              @click="() => selected?.isItem ? removeItem() : removeSubItem()"
              style="background-color: rgba(27, 126, 159, 1); border: none;"
              >Sim</b-button
            >
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { watch } from 'vue'

import moment from 'moment'

import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const currencyMask = createNumberMask({
  prefix: 'R$ ',
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  thousandsSeparatorSymbol: '.',
  decimalSymbol: ',',
});

export default {
  data() {
    return {
      currencyMask,
      // tipo_tabela: process.env.VUE_APP_DEV ? 'Serviço' : null,
      tipos_tabela: [{ text: 'Selecione', value: null }, 'Serviço', 'Aquisição'],
      // tipos_unidade: [{ text: 'Selecione', value: null }, 'Área', 'Peso', 'Tamanho', 'Tempo'],
      tipos_unidade: [
        { text: 'Selecione', value: null },
        {
          label: 'Área',
          options: [
            { value: 'Metro quadrado (m²)', text: 'Metro quadrado (m²)' },
            { value: 'Quilômetro quadrado (Km²)', text: 'Quilômetro quadrado (Km²)' }
          ]
        },
        {
          label: 'Comprimento',
          options: [
            { value: 'Metros (m)', text: 'Metros (m)' },
            { value: 'Quilômetros (Km)', text: 'Quilômetros (Km)' }
          ]
        },
        {
          label: 'Volume',
          options: [
            { value: 'Litros (l)', text: 'Litros (l)' },
            { value: 'Metros cúbicos (m³)', text: 'Metros cúbicos (m³)' }
          ]
        },
        {
          label: 'Tempo',
          options: [
            { value: 'Horas', text: 'Horas' },
            { value: 'Dias', text: 'Dias' },
            { value: 'Semanas', text: 'Semanas' },
            { value: 'Meses', text: 'Meses' }
          ]
        },
        {
          label: 'Peso',
          options: [
            { value: 'Quilogramas (Kg)', text: 'Quilogramas (Kg)' },
            { value: 'Toneladas (t)', text: 'Toneladas (t)' }
          ]
        }
      ],

      success: false,
      selected: null,
      item: {
        item: '',
        total: '',
        periodo_inicial: '',
        periodo_final: ''
      },
      subitem: {
        sinap: '',
        descricao: '',
        unidade: null,
        quantidade: 0,
        valor_unitario: '',
        total: ''
      },
      data: process.env.VUE_APP_DEV ? [{
        id: '1711131974634',
        item: 'SERVIÇOS PRELIMINARES',
        total: 'R$ 26.324,34',
        periodo_inicial: '2024-06-01',
        periodo_final: '2024-12-31',
        open: false,
        editar: false,
        subitens: [
          {
            index: 0,
            id: '1711131991671',
            sinap: '74209/001',
            descricao: 'PLACA DE OBRA EM CHAPA DE AÇO GALVANIZADO',
            unidade: 'Área',
            quantidade: 18,
            valor_unitario: 'R$ 366,33',
            total: 'R$ 6.593,94',
            editar: false,
            item: {
              id: '1711131974634',
              item: 'SERVIÇOS PRELIMINARES',
              total: 'R$ 26.324,34',
              periodo_inicial: '2024-06-01',
              periodo_final: '2024-12-31',
              open: false,
              editar: false,
            }
          }
        ]
      }] : []
    };
  },
  methods: {
    showNovoItem(){
      this.item = {
        id: Date.now(),
        item: '',
        total: '',
        periodo_inicial: '',
        periodo_final: '',
        open: false,
        editar: false,
        subitens: []
      }
    },
    showEditarItem(item){
      this.item = { ...item, editar: true }
    },
    showNovoSubItem(item){
      this.subitem = {
        id: Date.now(),
        sinap: '',
        descricao: '',
        unidade: null,
        quantidade: 0,
        valor_unitario: '',
        total: '',
        editar: false,
        item: item
      }
    },
    showEditarSubItem(subitem){
      this.subitem = { ...subitem, editar: true }
    },
    saveItem(event){
      event.preventDefault()

      if(moment(this.item.periodo_inicial).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }).isBefore(moment().set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
        }))){
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          title: 'Campos obrigatórios',
          text: 'Por favor, insira corretamente o período inicial',
          icon: 'error'
        });

        return
      }

      if(moment(this.item.periodo_final).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        }).isBefore(moment(this.item.periodo_inicial).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
        }))){
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          title: 'Campos obrigatórios',
          text: 'Por favor, insira corretamente os períodos',
          icon: 'error'
        });

        return
      }

      if(this.item.editar){
        this.data = this.data.map(item => item.id == this.item.id ? this.item : item)

        this.$bvModal.hide('modal-item')
      } else {
        this.data = [...this.data, this.item]

        this.success = true
      }
    },
    saveSubItem(event){
      event.preventDefault()

      if(this.subitem.editar){
        this.data = this.data.map(item => item.id == this.subitem.item.id ? ({
          ...item,
          subitens: item.subitens.map(subitem => subitem.id == this.subitem.id ? this.subitem : subitem)
        }) : item)

        this.$bvModal.hide('modal-subitem')
      } else {
        this.data = this.data.map(item => item.id == this.subitem.item.id ? ({ ...item, subitens: [...item.subitens, { index: item.subitens.length, ...this.subitem }] }) : item)

        this.success = true
      }
    },
    closeModal(){
      this.success = false
      this.$bvModal.hide('modal-item')
      this.$bvModal.hide('modal-subitem')
    },
    removeItem(){
      this.data = this.data.filter(item => item.id != this.selected.id)
      this.$bvModal.hide('modal-remover-subitem')
    },
    removeSubItem(){
      this.data = this.data.map(item => item.id == this.selected.item.id ? ({
        ...item,
        subitens: item.subitens.filter(sub => sub.id != this.selected.id)
      }) : item)
      this.$bvModal.hide('modal-remover-subitem')
    },
    money_mask(v) {
      v = v.replace(/\D/g, '');
      v = v.replace(/(\d{1})(\d{15})$/, '$1.$2');
      v = v.replace(/(\d{1})(\d{11})$/, '$1.$2');
      v = v.replace(/(\d{1})(\d{8})$/, '$1.$2');
      v = v.replace(/(\d{1})(\d{5})$/, '$1.$2');
      v = v.replace(/(\d{1})(\d{1,2})$/, '$1,$2');
      return `R$ ${v}`;
    },
    price_to_number(v) {
      if (!v) { return 'R$ 0,00'; }
      v = v.split('.').join('');
      v = v.split(',').join('.');

      return this.money_mask(Number(String(Number(v.replace(/[^0-9.]/g, "")).toFixed(2)) * this.subitem.quantidade).toFixed(2))
    },
    only_price_to_number(v) {
      if (!v) { return 0; }
      v = v.split('.').join('');
      v = v.split(',').join('.');

      return v
    },
    getValorTotalItens(){
      const atual = this.data.map(item => Number(this.only_price_to_number(String(item.total).split('R$')[1].trim())))

      const valor = atual.reduce(function (acumulador, valorAtual, indice, array) {
        return acumulador + valorAtual;
      }, 0)

      return this.money_mask(Number(String(Number(valor).toFixed(2))).toFixed(2))
    },
    getValorTotalBDI(){
      const atual = this.data.map(item => Number(this.only_price_to_number(String(item.total).split('R$')[1].trim())))

      const valor = atual.reduce(function (acumulador, valorAtual, indice, array) {
        return acumulador + valorAtual;
      }, 0)

      const bdi = valor * 0.25;

      return this.money_mask(Number(String(Number(bdi).toFixed(2))).toFixed(2))
    },
    getValorTotalItensComBDI(){
      const atual = this.data.map(item => Number(this.only_price_to_number(String(item.total).split('R$')[1].trim())))

      const valor = atual.reduce(function (acumulador, valorAtual, indice, array) {
        return acumulador + valorAtual;
      }, 0)

      const bdi = valor * 0.25;

      const total = valor + bdi;

      return this.money_mask(Number(String(Number(total).toFixed(2))).toFixed(2))
    },
    isItemInCronograma(){
      return this.form.cronograma.filter(item => item.itens.length > 0).length > 0
    }
  },
  props: {
    form: {
      type: Object,
    },
    setForm: {
      type: Function
    },
    tipo_tabela: {
      type: String | null
    },
    set_tipo_tabela: {
      type: Function
    }
  },
  created() {
    this.data = this.form.itens

    watch(
      () => this.data,
      () => {
        this.setForm({ ...this.form, itens: this.data, cronograma: this.form.cronograma.map(item => ({ ...item, itens: [] })) })
      }
    )

    watch(
      () => this.tipo_tabela,
      () => {
        this.set_tipo_tabela(this.tipo_tabela)
      }
    )
  }
};
</script>
