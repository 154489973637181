<template>
  <main>
    <router-link :to="`/eixos/${this.$route.params.id}`">
      <b-icon icon="arrow-left" /> SubEixos</router-link
    >
    <b-container fluid class="mt-4 p-4 border">
      <b-row class="mb-4">
        <b-col class="md-6">
          <h4 class="mb-0">CheckList</h4>
        </b-col>
        <b-col class="md-6">
          <b-button
            v-if="checklists.length === 0"
            @click="modalNovo"
            v-b-modal.modal-1
            :disabled="isLoading"
            variant="outline-primary"
            class="ml-1 float-right"
            >Cadastrar
          </b-button>
        </b-col>
      </b-row>

      <div class="table-responsive">
        <table class="table table-hover table-sm">
          <thead>
            <tr>
              <th scope="col" class="col-8 col-lg-9">Titulo</th>
              <th scope="col" class="col-4 col-lg-3">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="checklist in checklists" :key="checklist.id">
              <td>{{ checklist.descricao }}</td>
              <td>
                <div class="d-flex align-items-center">
                  <button
                    v-b-modal.modal-1
                    class="btn btn-primary"
                    title="Editar"
                    :disabled="isLoading"
                    @click="editar(checklist)"
                  >
                    <b-icon icon="pencil"></b-icon>
                  </button>
                  <button
                    :disabled="isLoading"
                    class="btn btn-danger ml-2"
                    @click="deletarChecklist(checklist)"
                  >
                    <b-icon icon="trash"></b-icon>
                  </button>
                  <button
                    :disabled="isLoading"
                    @click="
                      $router.push(
                        `/eixos/${checklist.sub_eixo.eixo_id}/${checklist.sub_eixo.id}/checklist/${checklist.id}`
                      )
                    "
                    class="btn btn-warning ml-2"
                  >
                    <b-icon icon="arrow-right"></b-icon>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <b-modal id="modal-1" :hide-footer="true">
        <template #modal-title>
          <h3 class="font-weight-bold" v-if="modalTitle === 1">
            Novo Checklist
          </h3>
          <h3 class="font-weight-bold" v-if="modalTitle !== 1">Checklist</h3>
        </template>
        <b-form>
          <div class="row">
            <div class="col-12">
              <b-form-group label="Titulo:" label-class="font-weight-bolder">
                <b-form-input
                  v-model.trim="titulo"
                  required
                  :disabled="isLoading"
                  placeholder="Digite um título..."
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-12 mt-4 canhoto">
              <b-button
                :disabled="titulo.length === 0 || isLoading"
                variant="primary"
                @click="modalTitle === 1 ? addChecklist() : editChecklist()"
                >{{ modalTitle === 1 ? 'Salvar' : 'Editar' }}</b-button
              >
            </div>
          </div>
        </b-form>
      </b-modal>
    </b-container>
  </main>
</template>

<script>
export default {
  data() {
    return {
      checklists: [],
      selectedChecklist: '',
      modalTitle: 0,
      titulo: '',
      pageTitle: '',
      novoItem: '',
      isLoading: false,
    };
  },
  methods: {
    async getChecklists() {
      this.isLoading = true;
      try {
        const response = await this.$http.get('checklist');
        const checklists = response.data.filter(checklist => {
          if (checklist.sub_eixo) {
            return checklist.sub_eixo.id === Number(this.$route.params.subid)
              ? checklist
              : null;
          }
        });

        this.checklists = checklists;
      } catch (error) {
        console.error('Erro ao buscar os checklists:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async addChecklist() {
      this.isLoading = true;
      const data = {
        subeixo_id: Number(this.$route.params.subid),
        descricao: this.titulo,
      };
      try {
        await this.$http.post('checklist', data);
        await this.getChecklist();
        this.clearEntries();
        this.$swal({
          title: 'Checklist salvo com sucesso!',
          icon: 'success',
        });
      } catch (error) {
        console.error('Erro ao adicionar o checklist:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async editChecklist() {
      const data = {
        subeixo_id: Number(this.$route.params.subid),
        descricao: this.titulo,
      };
      this.isLoading = true;
      try {
        const res = await this.$http.put(
          `checklist/${this.selectedChecklist.id}`,
          data
        );
        this.checklists = this.checklists.map(checklist =>
          checklist.id === res.data.id
            ? { ...checklist, descricao: res.data.descricao }
            : checklist
        );
        this.$swal({
          title: 'Checklist atualizado com sucesso!',
          icon: 'success',
        });
      } catch (error) {
        console.log(error);
      }
    },
    clearEntries() {
      this.selectedChecklist = null;
      this.titulo = '';
      this.modalTitle = null;
    },
    modalNovo() {
      this.clearEntries();
      this.modalTitle = 1;
    },
    editar(checklist) {
      this.modalTitle = 2;
      this.selectedChecklist = checklist;
      this.titulo = checklist.descricao;
    },
    async deletarChecklist(checklist) {
      try {
        const response = await this.$http.delete(`checklist/${checklist.id}`);
        this.checklists = this.checklists.filter(
          chklist => chklist.id !== response.data.id
        );

        this.$swal({
          title: 'Checklist apagado com sucesso!',
          icon: 'success',
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  created() {
    this.getChecklists();
  },
  beforeCreate() {
    //Check if profile is SDC, if not, redirect to home.
    if (JSON.parse(localStorage.getItem('user')).perfil.grupo_perfil_id != '1') {
      this.$router.push('/processos/minha-unidade');
    }
  },
};
</script>

<style scoped>
td,
th {
  vertical-align: middle !important;
}
</style>
