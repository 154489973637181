<template>
  <b-container
    fluid
    class="p-4 border h-100"
    v-if="$helper.verificaPermissao('visualizar_busca_geral')"
  >
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h4>Busque por processos:</h4>
    </div>
    <b-row class="mb-2">
      <b-col lg="5">
        <h5>Título</h5>
        <b-input
          :disabled="protocol.length > 0"
          v-model.trim="title"
          @keyup.enter="fetchProcessos"
        />
      </b-col>
      <b-col lg="2" class="d-flex align-items-end justify-content-center">
        <h4>OU</h4>
      </b-col>
      <b-col lg="5">
        <h5>Número de protocolo:</h5>
        <b-input
          :disabled="title.length > 0"
          v-model.trim="protocol"
          @keyup.enter="fetchProcessos"
        />
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col lg="12">
        <b-button variant="primary" @click="fetchProcessos">BUSCAR</b-button>
      </b-col>
    </b-row>
    <ReadonlyTable :items="items" @openModal="openModal" />
    <footer class="d-flex justify-content-end align-items-center">
      <b-pagination
        v-if="paginator.total"
        v-model="filtros.page"
        :total-rows="paginator.total"
        :per-page="paginator.perPage"
        first-number
        last-number
        @input="(filtros.page = $event), fetchProcessos()"
        class="w-auto"
      />
    </footer>
    <!-- <ModalReadonly
      @close-readonly="clearModalData"
      modal-id="busca-modal"
      :processId="modalProcessId"
    /> -->
  </b-container>
  <h1 v-else>Você não tem permissões!</h1>
</template>

<script>
// const ModalReadonly = () => import('../components/readonly/ModalReadonly.vue');
const ReadonlyTable = () => import('../components/readonly/ReadonlyTable.vue');
export default {
  data() {
    return {
      items: [],
      paginator: {
        perPage: null,
        total: null,
      },
      filtros: {
        limit: 10,
        page: 1,
      },
      title: '',
      protocol: '',
      modalProcessId: '',
    };
  },
  methods: {
    openModal(item) {
      this.modalProcessId = String(item.id);
      this.$bvModal.show('busca-modal');

      this.$router.push({
        name: 'ModalReadonly',
        params: {
          processId: this.modalProcessId,
          modalId: "busca-modal"
      }});
    },
    clearModalData() {
      this.modalProcessId = '';
    },
    spreadUnidadesCompartilhadas(unidades) {
      return unidades.map(unidade => unidade.user.name).join(', ');
    },
    parameters() {
      const params = { ...this.filtros };
      if (this.protocol.length > 0) {
        return { ...params, protocolo: this.protocol };
      }
      if (this.title.length > 0) {
        return { ...params, titulo: this.title };
      }
      return {};
    },
    async fetchProcessos() {
      try {
        const params = {};
        if (this.protocol.length > 0) {
          params.protocolo = this.protocol;
        }
        if (this.title.length > 0) {
          params.titulo = this.title;
        }

        if (Object.keys(params).length === 0) {
          this.$swal({
            title: 'Preencha um dos campos de pesquisa',
            icon: 'warning',
          });
          return;
        }

        const { data } = await this.$http.get('/processos', {
          params: {
            ...params,
            ...this.filtros
          },
        });

        const aux = data.data.map(elem => {
          return {
            id: elem.id,
            protocolo: elem.protocolo,
            titulo: elem.titulo,
            prefeitura: elem.prefeitura.nome,
            unidades_compartilhadas: this.spreadUnidadesCompartilhadas(
              elem.compartilhamento
            ),
            status: elem.status,
          };
        })

        this.paginator.perPage = data.per_page;
        this.paginator.total = data.total;

        this.items = aux;
      } catch (error) {
        console.log(error);
      }
    },
  },
  components: {
    // ModalReadonly,
    ReadonlyTable
  },
};
</script>

<style scoped>
td,
th {
  vertical-align: middle !important;
  padding: 10px !important;
}
</style>
