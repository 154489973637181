<template>
  <footer class="text-left text-white" style="background-color: #134ca3">
    <!-- Grid container -->
    <b-container fluid class="py-4 pb-0">
      <div class="row">
        <section class="col-12 col-md-8 d-flex justify-content-center">
          <div class="footer-content">
            <p>CIDADES - Secretaria do Desenvolvimento das Cidades</p>
            <p>
              Rua Eliézer Levy nº 2353, CEP 68.900-083 - Macapá, Amapá - (96)
              3131-7201
            </p>
            <p>Email: secretariadascidades@sdc.ap.gov.br</p>
            <p>2017 - 2022 Licença Creative Commons 3.0 International</p>
          </div>
        </section>
        <div class="col-12 col-md-4">
          <img
            src="@/assets/brazao 1.svg"
            alt="Amapá Brazão"
            height="80px"
            class="float-left"
          />
        </div>
      </div>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: 'footer-layout',
};
</script>

<style>
.footer-content p {
  margin: 5px 0px !important;
}
</style>
