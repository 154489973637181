import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    processos: [],
    inativos: [],
    arquivados: [],
  },
  mutations: {
    addProcessos (state, payload) {
      state.processos = payload
    },
    addInativos (state, payload) {
      state.inativos = payload
    },
    addArquivados (state, payload) {
      state.arquivados = payload
    },
    clean (state, payload) {
      console.log(payload)
      state.processos = []
      state.inativos = []
      state.arquivados = []
    }
  },
  actions: {},
  modules: {},
});
