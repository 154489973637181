<template>
  <b-container
    class="p-4 border h-100"
    fluid
    v-if="$helper.verificaPermissao('visualizar_arquivados')"
  >
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h4>Processos Arquivados</h4>
      <slot name="header"></slot>
    </div>
    <b-overlay :show="isLoading" rounded="sm">
      <div class="table-responsive">
        <table class="table table-hover table-sm">
          <thead>
            <tr>
              <th scope="col" :class="perfil === 'sdc' ? 'col-2' : 'col-3'">
                <b-form-input
                  v-model="filtros.protocolo"
                  class="form-rounded"
                  placeholder="Protocolo"
                  size="sm"
                  @input="debouncedPesquisar()"
                  style="border-radius: 7px"
                />
              </th>
              <th scope="col" class="col-4">
                <b-form-input
                  v-model="filtros.titulo"
                  class="form-rounded"
                  placeholder="Título"
                  size="sm"
                  @input="debouncedPesquisar()"
                  style="border-radius: 7px"
                />
              </th>
              <th scope="col" class="col-2">
                <b-form-input
                  v-model="filtros.prefeitura"
                  class="form-rounded"
                  placeholder="Prefeitura"
                  size="sm"
                  @input="debouncedPesquisar()"
                  style="border-radius: 7px"
                >
                </b-form-input>
              </th>
              <th v-if="perfil === 'sdc'" scope="col" class="text-center col-1">
                Solicitação
              </th>
              <th scope="col" class="col-2 text-center">Status</th>
              <th scope="col" class="col-1">
                <b-form-select
                  :options="['Resultados', '10', '20', '30', 50]"
                  v-model="filtros.limit"
                  @input="debouncedPesquisar()"
                ></b-form-select>
              </th>
            </tr>
          </thead>
          <tbody v-if="this.processos">
            <tr v-for="processo in processos" :key="processo.id">
              <th scope="row" style="vertical-align: middle">
                {{ processo.protocolo }}
              </th>
              <td>
                {{ processo.titulo }}
              </td>
              <td class="text-center">
                <span
                  v-b-tooltip.hover.left
                  :title="processo.prefeitura.nome"
                  >{{ processo.prefeitura.sigla }}</span
                >
              </td>
              <td v-if="perfil === 'sdc'" class="text-center">
                {{ timerSolicitacao(processo.solicitacoes_pendentes) }}
              </td>
              <td class="text-center">
                <span
                  :class="statusClass(processo.status)"
                  class="py-1 px-2 rounded-pill text-white font-weight-bolder"
                  >{{ processo.status }}</span
                >
              </td>
              <td>
                <div class="d-flex align-items-center">
                  <button
                    v-b-tooltip.hover.left
                    class="btn btn-primary btn-sm mr-2"
                    title="Visualizar Processo"
                    v-b-modal.visualizar
                    @click="showProcesso(processo.id)"
                  >
                    <b-icon icon="eye-fill"></b-icon>
                  </button>
                  <button
                    v-b-tooltip.hover
                    class="btn btn-warning btn-sm ml-2"
                    title="Desarquivar Processo"
                    v-b-modal.visualizar
                    @click="desarquivar(processo.id)"
                    v-if="$helper.verificaPermissao('desarquivar_processo')"
                  >
                    <b-icon icon="file-zip"></b-icon>
                  </button>
                  <!-- <label
                    v-if="
                      $helper.verificaPermissao(
                        'solicitar_documentacao_convenio'
                      ) &&
                      perfil === 'prefeitura' &&
                      processo.solicitacoes_pendentes.length > 0
                    "
                    v-b-tooltip.hover.left
                    :title="pluralText(processo.solicitacoes_pendentes)"
                    class="ml-3 p-0 mb-0"
                  >
                    <b-icon
                      icon="exclamation-diamond-fill"
                      variant="danger"
                    ></b-icon>
                  </label> -->
                  <!-- <label
                    v-if="perfil === 'sdc' && processo.ultima_atualizacao >= 5"
                    v-b-tooltip.hover.left
                    :title="
                      'Este processo está há ' +
                      processo.ultima_atualizacao +
                      ' dias sem atualizações'
                    "
                    class="ml-3 p-0 mb-0"
                  >
                    <b-icon
                      icon="exclamation-diamond-fill"
                      variant="danger"
                    ></b-icon>
                  </label> -->
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <th>Sem registros</th>
            </tr>
          </tbody>
        </table>
      </div>
    </b-overlay>
    <!-- <ModalReadonly
      modalId="modalArquivados"
      :processId="modalProcessId"
      @close-readonly="closeModal"
    /> -->
    <footer class="d-flex justify-content-end align-items-center">
      <b-pagination
        v-if="allProcessos.total"
        v-model="filtros.page"
        :total-rows="allProcessos.total"
        :per-page="allProcessos.per_page"
        first-number
        last-number
        @input="(filtros.page = $event), getProcessos()"
        class="w-auto"
      />
    </footer>
  </b-container>
  <h1 v-else>Você não tem permissões!</h1>
</template>

<script>
// import ModalReadonly from '@/components/readonly/ModalReadonly';
var _ = require('lodash');

export default {
  components: {
    // ModalReadonly,
  },
  name: 'Arquivados',
  props: {
    search: String,
    processo: {
      type: Object,
    },
  },
  data() {
    return {
      exibir: 0,
      isLoading: false,
      sigla: '',
      processos: {
        total: 0,
      },
      allProcessos: {
        total: 0,
        per_page: 0
      },
      perfil: '',
      filtros: {
        protocolo: '',
        titulo: '',
        eixo: '',
        subeixo: '',
        prefeitura: '',
        prefeitura_id: '',
        sdc: '',
        limit: 10,
        page: 1,
      },
      modalProcessId: '',
    };
  },
  computed: {},
  methods: {
    showProcesso(processo) {
      this.modalProcessId = String(processo);
      this.$bvModal.show('modalArquivados');

      this.$router.push({
        name: 'ModalReadonly',
        params: {
          processId: this.modalProcessId,
          modalId: "modalArquivados"
      }});
    },
    closeModal() {
      this.modalProcessId = '';
    },
    getPerfil() {
      if (JSON.parse(localStorage.getItem('user')).perfil.grupo_perfil_id == '2') {
        this.perfil = 'prefeitura';
      } else if (JSON.parse(localStorage.getItem('user')).perfil.grupo_perfil_id == '1') {
        this.perfil = 'sdc';
      } else {
        this.perfil = '';
      }
    },
    timerSolicitacao(solicitacoes) {
      if (solicitacoes?.length > 0) {
        let timers = solicitacoes.map(solicitacao => {
          return solicitacao.timer;
        });
        timers = timers.filter(function (timer) {
          return timer != null;
        });
        return timers[0];
      } else {
        return 'Nenhuma';
      }
    },
    getProcessos() {
      this.isLoading = true;
      this.$http
        .get('processos', { params: { ...this.filtros, arquivado: true } })
        .then(res => {
          console.log(res.data);
          const { data } = res;
          this.allProcessos = res.data
          this.processos = data.data;
          this.isLoading = false;

          this.$store.commit('addArquivados', data.data)
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    },
    reloadProcessos() {
      this.$http
        .get('processos', { params: { ...this.filtros, arquivado: true } })
        .then(res => {
          console.log(res.data);
          const { data } = res;
          this.allProcessos = data
          this.processos = data.data;

          this.$store.commit('addArquivados', data.data)
        })
        .catch(error => {
          console.log(error);
        });
    },
    refreshProcesso(processo) {
      this.isLoading = true;
      this.$http
        .get(`processos/${processo.id}`, {})
        .then(({ data }) => {
          processo = data;

          this.processos.data = this.processos.data.map(element => {
            if (element.id === processo.id) {
              return processo;
            }
            return element;
          });
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    },
    desarquivar(processoId) {
      this.$swal({
        icon: 'warning',
        title: 'Deseja realmente desarquivar este processo?',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#3085d6',
        denyButtonText: 'Cancelar',
      }).then(result => {
        this.isLoading = true;
        if (result.isConfirmed) {
          this.$http
            .put(`processos/${processoId}/desarquivar`)
            .then(() => {
              this.$emit('processo', this.processo);
              this.$swal({
                title: 'Processo desarquivado com sucesso!',
                icon: 'success',
              });
              this.getProcessos();
              this.isLoading = false;
            })
            .catch(error => {
              this.$swal({ title: 'Erro!', icon: 'error' });
              console.log(error.response.data);
              this.validationErrors = error.response.data
                ? error.response.data
                : null;
              console.log(error);
              this.isLoading = false;
            });
        }
      });
    },
    statusClass(status) {
      const statusDictionary = {
        encaminhado: 'bg-info',
        recebido: 'bg-success',
        'em análise': 'bg-warning',
        concluído: 'bg-success',
        salvo: 'bg-success',
      };
      return statusDictionary[status.toLowerCase()];
    },
  },
  created() {
    // if (
    //   this.$helper.verificaPermissao(
    //     "visualizar_processos_prefeitura_vinculada"
    //   )
    // ) {
    //   this.filtros.prefeitura_id = localStorage.getItem("prefeitura_id");
    // }

    // if (
    //   this.$helper.verificaPermissao("visualizar_processos_setor_vinculado")
    // ) {
    //   this.filtros.setor =
    //     localStorage.getItem("setor_id") !== "null"
    //       ? localStorage.getItem("setor_id")
    //       : "";
    // }

    /*if (this.$helper.verificaPermissao("visualizar_todos_processos")) {
    }*/
    this.filtros.user_id = localStorage.getItem('user_id');
    this.getPerfil();

    this.processos = this.$store.state.arquivados;

    if(this.$store.state.arquivados.length == 0){
      this.getProcessos();
    }else{
      this.reloadProcessos();
    }

    this.debouncedPesquisar = _.debounce(this.getProcessos, 300);
  },
};
</script>

<style scoped>
.status {
  width: 250px;
  height: 50px;
  font-size: 20px;
  background: #4f4f8d;
  padding-left: 100px;
}

.arquivo {
  width: 280px;
  color: rgb(9, 102, 189);
  text-align: center;
  display: block;
  cursor: pointer;
  border-radius: 7px;
}

td,
th {
  vertical-align: middle !important;
  padding: 10px !important;
}
</style>
