<template>
  <div id="app">
    <div id="nav" class="overflow-hidden">
      <MainLayout v-if="isMainLayout">
        <router-view />
      </MainLayout>
      <template v-else>
        <router-view />
      </template>
    </div>
  </div>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue';
export default {
  name: 'app',
  components: { MainLayout },
  created() {
    document.documentElement.setAttribute('lang', 'pt-BR');
  },
  computed: {
    isMainLayout() {
      return (
        this.$route.name !== 'Home' &&
        this.$route.name !== 'Login' &&
        this.$route.name !== 'VerificarAssinatura' &&
        this.$route.name !== 'SolicitarAssinaturaPlano' &&
        this.$route.name !== 'EsqueciSenha' &&
        this.$route.name !== 'RecuperarSenha'
      );
    },
  },
};
</script>
