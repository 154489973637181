<template>
  <section>
    <div class="d-flex flex-row items-center justify-content-end" style="margin-bottom: 20px;">
      <b-button @click="abrirModalAdicionar()" type="button" style="background-color: #1B7E9F;">Adicionar</b-button>
    </div>
    <section style="overflow-x: auto;">
      <table class="table table-bordered" style="min-width: 100%;">
        <thead>
          <tr>
            <th style="text-align: center;">Natureza da Despesa</th>
            <th style="text-align: center;" colspan="2">Total</th>
            <th>Ações</th>
          </tr>
          <tr style="background-color: #E5E7EB; color: #374151;" v-if="form.planoDeAplicacao && form.planoDeAplicacao.length > 0">
            <th scope="col" class="text-center" style="color: #1F2937;">Código/Especificação</th>
            <th scope="col" class="text-center" style="color: #1F2937;">Concedente</th>
            <th scope="col" class="text-center" style="color: #1F2937;">Convenente</th>
            <th scope="col" class="text-center" style="color: #1F2937;">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in form.planoDeAplicacao" :key="index" >
            <td class="text-center align-middle" style="overflow-wrap: break-word; max-width: 600px;">{{ item.especificacao }}</td>
            <td class="text-center align-middle">{{ item.concedente }}</td>
            <td class="text-center align-middle">{{ item.convenente }}</td>
            <td class="text-center align-middle" style="width: 95px;">
              <svg @click="abrirModalEditar(index)" style="cursor: pointer; margin-right: 10px;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21 11.9999C20.7348 11.9999 20.4804 12.1053 20.2929 12.2928C20.1054 12.4804 20 12.7347 20 12.9999V18.9999C20 19.2652 19.8946 19.5195 19.7071 19.707C19.5196 19.8946 19.2652 19.9999 19 19.9999H5C4.73478 19.9999 4.48043 19.8946 4.29289 19.707C4.10536 19.5195 4 19.2652 4 18.9999V4.99994C4 4.73472 4.10536 4.48037 4.29289 4.29283C4.48043 4.1053 4.73478 3.99994 5 3.99994H11C11.2652 3.99994 11.5196 3.89458 11.7071 3.70705C11.8946 3.51951 12 3.26516 12 2.99994C12 2.73472 11.8946 2.48037 11.7071 2.29283C11.5196 2.1053 11.2652 1.99994 11 1.99994H5C4.20435 1.99994 3.44129 2.31601 2.87868 2.87862C2.31607 3.44123 2 4.20429 2 4.99994V18.9999C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 21.9999 5 21.9999H19C19.7956 21.9999 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 18.9999V12.9999C22 12.7347 21.8946 12.4804 21.7071 12.2928C21.5196 12.1053 21.2652 11.9999 21 11.9999ZM6 12.7599V16.9999C6 17.2652 6.10536 17.5195 6.29289 17.707C6.48043 17.8946 6.73478 17.9999 7 17.9999H11.24C11.3716 18.0007 11.5021 17.9755 11.6239 17.9257C11.7457 17.8759 11.8566 17.8026 11.95 17.7099L18.87 10.7799L21.71 7.99994C21.8037 7.90698 21.8781 7.79637 21.9289 7.67452C21.9797 7.55266 22.0058 7.42195 22.0058 7.28994C22.0058 7.15793 21.9797 7.02722 21.9289 6.90536C21.8781 6.7835 21.8037 6.6729 21.71 6.57994L17.47 2.28994C17.377 2.19621 17.2664 2.12182 17.1446 2.07105C17.0227 2.02028 16.892 1.99414 16.76 1.99414C16.628 1.99414 16.4973 2.02028 16.3754 2.07105C16.2536 2.12182 16.143 2.19621 16.05 2.28994L13.23 5.11994L6.29 12.0499C6.19732 12.1434 6.12399 12.2542 6.07423 12.376C6.02446 12.4979 5.99924 12.6283 6 12.7599ZM16.76 4.40994L19.59 7.23994L18.17 8.65994L15.34 5.82994L16.76 4.40994ZM8 13.1699L13.93 7.23994L16.76 10.0699L10.83 15.9999H8V13.1699Z" fill="#01455E"/>
              </svg>

              <svg @click="deletarPlano(index)" style="cursor: pointer;" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM20 6H16V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2H11C10.2044 2 9.44129 2.31607 8.87868 2.87868C8.31607 3.44129 8 4.20435 8 5V6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H5V19C5 19.7956 5.31607 20.5587 5.87868 21.1213C6.44129 21.6839 7.20435 22 8 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V8H20C20.2652 8 20.5196 7.89464 20.7071 7.70711C20.8946 7.51957 21 7.26522 21 7C21 6.73478 20.8946 6.48043 20.7071 6.29289C20.5196 6.10536 20.2652 6 20 6ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM17 19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V8H17V19ZM14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11V17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18Z" fill="#DC2626"/>
              </svg>
            </td>
          </tr>
          <tr style="background-color: #E5E7EB; color: #374151;" v-if="form.planoDeAplicacao && form.planoDeAplicacao.length > 0">
            <th></th>
            <th class="text-center">Total Geral</th>
            <th class="text-center" colspan="2"> {{ totalGeral}}</th>
          </tr>
        </tbody>
      </table>
    </section>
    <b-modal
      id="modal-1"
      :title="editar ? 'Editar' : 'Adicionar'"
      :hide-footer="true"
      title-class="font-weight-bold"
      size="lg"
    >
      <b-form>
        <div class="row">
          <div class="col-12">
            <b-form-group label="Natureza da despesa (Código/Especificação)" label-class="font-weight-bold">
              <b-textarea v-model="novoItem.especificacao" placeholder="Descrever código/especificação">
              </b-textarea>
            </b-form-group>
          </div>

          <div class="col-12">
            <b-form-group label="Valor Concedente" label-class="font-weight-bold">
              <b-input type="text" v-mask="currencyMask" v-model="novoItem.concedente" placeholder="Inserir valor concedente">
              </b-input>
            </b-form-group>
          </div>

          <div class="col-12">
            <b-form-group label="Valor Convenente" label-class="font-weight-bold">
              <b-input type="text" v-mask="currencyMask" v-model="novoItem.convenente" placeholder="Inserir valor convenente">
              </b-input>
            </b-form-group>
          </div>
        </div>

        <div class="d-flex justify-content-center mt-4 mb-4">
          <b-button style="background-color: #99BBC8; border: none; font-weight: 600; border-radius: 6px; padding: 12px 18px 12px 18px; width: 160px; height: 46px;" @click="cancelar" class="mr-4">Cancelar</b-button>
          <b-button style="background-color: #1B7E9F; border: none; font-weight: 600; border-radius: 6px; padding: 12px 18px 12px 18px; width: 160px; height: 46px;" @click="editar ? editarPlano() : adicionarPlano()">{{editar ? 'Editar' : 'Adicionar'}}</b-button>
        </div>
      </b-form>
    </b-modal>
  </section>
</template>

<script>
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const currencyMask = createNumberMask({
  prefix: 'R$ ',
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  thousandsSeparatorSymbol: '.',
  decimalSymbol: ',',
});

  export default {
    props:{
      form:{ type: Object },
      setForm: { type: Function }
    },
    data () {
      return {
        novoItem: {
          especificacao: '',
          concedente: '',
          convenente: ''
        },
        indiceEditando: null,
        editar: false,
        currencyMask
      }
    },
    methods: {
      adicionarPlano(){
        this.editar = false;
        if (!this.novoItem.especificacao || !this.novoItem.concedente || !this.novoItem.convenente) {
          this.$swal({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            title: 'Campos obrigatórios',
            text: 'Por favor, preencha todos os campos',
            icon: 'error'
          });
          return;
        }
        let novoPlano = {
          especificacao: this.novoItem.especificacao,
          concedente: this.novoItem.concedente,
          convenente: this.novoItem.convenente
        };
        this.form.planoDeAplicacao.push(novoPlano);
        this.setForm(this.form);
        this.$bvModal.hide('modal-1');
      },
      editarPlano(){
        if (!this.novoItem.especificacao || !this.novoItem.concedente || !this.novoItem.convenente) {
          this.$swal({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            title: 'Erro',
            text: 'Por favor, preencha todos os campos',
            icon: 'error'
          });
          return;
        }
        this.form.planoDeAplicacao[this.indiceEditando].especificacao = this.novoItem.especificacao;
        this.form.planoDeAplicacao[this.indiceEditando].concedente = this.novoItem.concedente;
        this.form.planoDeAplicacao[this.indiceEditando].convenente = this.novoItem.convenente;

        this.setForm(this.form);
        this.editar = false;
        this.$bvModal.hide('modal-1');
      },
      deletarPlano(index){
        this.$swal({
          title: 'Deseja realmente excluir este plano?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: 'rgb(27, 126, 159)',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
            this.form.planoDeAplicacao.splice(index, 1);
            this.setForm(this.form);
            this.$swal( {title:'Plano excluído com sucesso!', icon: 'success'});
          }
          this.clear();
        });
      },
      abrirModalAdicionar() {
        this.editar = false;
        this.clear();
        this.$bvModal.show('modal-1');
      },
      abrirModalEditar(index) {
        this.indiceEditando = index;
        this.novoItem.especificacao = this.form.planoDeAplicacao[index].especificacao;
        this.novoItem.concedente = this.form.planoDeAplicacao[index].concedente;
        this.novoItem.convenente = this.form.planoDeAplicacao[index].convenente;

        this.editar = true;
        this.$bvModal.show('modal-1');
      },
      clear(){
        this.novoItem.especificacao = '';
        this.novoItem.concedente = '';
        this.novoItem.convenente = '';
      },
      cancelar(){
        this.clear();
        this.$bvModal.hide('modal-1');
      },
    },
    computed: {
      totalGeral() {
        let total = 0;
        this.form.planoDeAplicacao.forEach(item => {
          const valorConcedente = parseFloat((item.concedente || '0').replace(/[^\d.,]/g, '').replace(/\./g, '').replace(',', '.')) || 0;
          const valorConvenente = parseFloat((item.convenente || '0').replace(/[^\d.,]/g, '').replace(/\./g, '').replace(',', '.')) || 0;

          total += valorConcedente + valorConvenente;
        });

        const formattedTotal = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total);
        return formattedTotal;
      }
    },
  }
</script>
