<template>
  <div>
    <h3
      v-if="processo.solicitacoes_pendentes.length === 0"
      class="font-weight-bold mt-4"
    >
      Nenhuma solicitação pendente
    </h3>

    <h3 v-else class="font-weight-bold mt-4">Pendências</h3>
    <div
      v-for="solicitacao in processo.solicitacoes_pendentes"
      :key="solicitacao.id"
    >
      <div v-if="solicitacao.respondida == 0" class="mb-1">
        <div>
          <div
            v-b-toggle="'index' + solicitacao.id"
            v-if="solicitacao.tipo_solicitacao_id === 1"
          >
            <p class="text-primary m-0">
              Solicitação de Diligência
              <label
                v-b-tooltip.hover
                :title="solicitacao.descricao"
                class="btn btn-default"
              >
                <b-icon icon="eye"></b-icon>
              </label>
            </p>
          </div>

          <div
            v-b-toggle="'index' + solicitacao.id"
            v-if="solicitacao.tipo_solicitacao_id === 2"
          >
            <p class="text-primary m-0">
              Solicitação de Documentação Pendente
              <label
                v-b-tooltip.hover
                :title="solicitacao.descricao"
                class="btn btn-default"
              >
                <b-icon icon="eye"></b-icon>
              </label>
            </p>
          </div>

          <div
            v-b-toggle="'index' + solicitacao.id"
            v-if="solicitacao.tipo_solicitacao_id === 3"
          >
            <div class="d-flex justify-content-between">
              <p class="my-auto text-primary">
                Solicitação de Documentação do Processo Licitatório
                <label
                  v-b-tooltip.hover
                  :title="solicitacao.descricao"
                  class="btn btn-default my-auto"
                >
                  <b-icon data-testid="button-visualizar-detalhe-solicitacao-processo" icon="eye" />
                </label>
              </p>
              <p class="ml-auto text-danger">{{ solicitacao.timer }}</p>
            </div>
          </div>
        </div>

        <b-collapse
          :id="'index' + solicitacao.id"
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body>
            <ResponderSolicitacao
              :solicitacao="solicitacao"
              :processo="processo"
              :processo_id="processo.id"
              @done="atualizaProcesso"
            />
          </b-card-body>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import ResponderSolicitacao from '@/components/ResponderSolicitacao';
export default {
  components: {
    ResponderSolicitacao,
  },
  emits: ['atualiza'],
  methods: {
    atualizaProcesso() {
      this.$emit('atualiza', this.processo);
    },
  },
  props: {
    processo: {
      type: Object,
    },
  },
};
</script>
