<template>
  <b-nav vertical fill class="d-none d-lg-flex">
    <img
      src="@/assets/logo.png"
      style="width: 104px"
      class="img-fluid mx-auto mb-4"
    />

    <b-nav-item
      exact-active-class="bg-info-50 border-item-left rounded-right"
      to="/dashboard"
      v-if="profile === 'sdc'"
    >
      <div class="p-0">
        <b-icon
          icon="bar-chart-fill"
          class="d-none d-xl-inline text-dark ml-2"
        />
        <b-nav-text class="ml-3 text-dark">Dashboard</b-nav-text>
      </div>
    </b-nav-item>

    <b-nav-item>
      <div v-b-toggle.collapse-3>
        <b-icon
          icon="file-earmark-text-fill"
          class="d-none d-xl-inline text-dark ml-2"
        />
        <b-nav-text class="ml-3 text-dark">Processos</b-nav-text>
      </div>
      <b-collapse id="collapse-3" class="mt-2" is-nav>
        <b-list-group>
          <b-list-group-item
            to="/processos/busca"
            exact-active-class="bg-info-50 font-weight-bolder"
            v-if="$helper.verificaPermissao('visualizar_busca_geral')"
          >
            Busca Geral
          </b-list-group-item>
          <b-list-group-item
            to="/processos/minha-unidade"
            exact-active-class="bg-info-50 font-weight-bolder"
            v-if="$helper.verificaPermissao('visualizar_processos_setor_vinculado') || $helper.verificaPermissao('visualizar_processos_prefeitura_vinculada')"
          >
            Minha unidade
          </b-list-group-item>
          <b-list-group-item
            to="/processos/inativos"
            exact-active-class="bg-info-50 font-weight-bolder"
            v-if="$helper.verificaPermissao('visualizar_processos_inativos')"
          >
            Inativos
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </b-nav-item>

    <b-nav-item
      exact-active-class="bg-info-50 border-item-left rounded-right"
      to="/arquivados"
      v-if="$helper.verificaPermissao('visualizar_arquivados')"
    >
      <div class="p-0">
        <b-icon icon="folder-fill" class="d-none d-xl-inline text-dark ml-2" />
        <b-nav-text class="ml-3 text-dark">Arquivados</b-nav-text>
      </div>
    </b-nav-item>

    <b-nav-item
      to="/usuario"
      exact-active-class="bg-info-50 border-item-left rounded-right"
      v-if="$helper.verificaPermissao('visualizar_menu_de_servidores')"
    >
      <b-icon icon="people-fill" class="d-none d-xl-inline text-dark ml-2" />
      <b-nav-text class="ml-3 text-dark">Servidores</b-nav-text>
    </b-nav-item>

    <b-nav-item v-if="$helper.verificaPermissao('visualizar_menu_fiscais')">
      <div v-b-toggle.collapse-2>
        <b-icon icon="person-badge" class="d-none d-xl-inline text-dark ml-2" />
        <b-nav-text class="ml-3 text-dark">Fiscais</b-nav-text>
      </div>
      <b-collapse id="collapse-2" class="mt-2" is-nav>
        <b-list-group>
          <b-list-group-item
            to="/fiscais-administrativos"
            exact-active-class="bg-info-50 font-weight-bolder"
          >
            Administrativo
          </b-list-group-item>
          <b-list-group-item
            to="/gestores-convenio"
            exact-active-class="bg-info-50 font-weight-bolder"
          >
            Gestores de Convênio
          </b-list-group-item>
          <b-list-group-item
            to="/fiscais-tecnicos"
            exact-active-class="bg-info-50 font-weight-bolder"
          >
            Técnico
          </b-list-group-item>
        </b-list-group>
      </b-collapse>
    </b-nav-item>

    <b-nav-item
      to="/relatorios"
      exact-active-class="bg-info-50 border-item-left rounded-right"
      v-if="$helper.verificaPermissao('visualizar_menu_relatorios')"
    >
      <b-icon
        icon="file-earmark-medical-fill"
        class="d-none d-xl-inline text-dark ml-2"
      />
      <b-nav-text class="ml-3 text-dark">Relatórios</b-nav-text>
    </b-nav-item>

    <b-nav-item
      to="/assinaturas"
      exact-active-class="bg-info-50 border-item-left rounded-right"
      v-if="$helper.verificaPermissao('visualizar_menu_assinaturas')"
    >
      <b-icon icon="pen-fill" class="d-none d-xl-inline text-dark ml-2" />
      <b-nav-text class="ml-3 text-dark">Assinaturas</b-nav-text>
      <span
        v-if="signatarios.length > 0"
        class="ml-2"
        v-b-tooltip.hover
        :title="
          signatarios.length > 1
            ? signatarios.length + ' Assinaturas Pendentes'
            : signatarios.length + ' Assinatura Pendente'
        "
      >
        <b-icon icon="exclamation-circle-fill" variant="danger" />
      </span>
    </b-nav-item>

    <!-- <b-nav-item to="/arquivados" class="mx-auto mx-lg-0 py-2">
      <b-icon icon="file-earmark"></b-icon> Processos Arquivados
    </b-nav-item> -->
    <b-nav-item v-if="($helper.verificaPermissao('visualizar_submenu_setor') || $helper.verificaPermissao('visualizar_submenu_eixos_subeixos') || $helper.verificaPermissao('visualizar_submenu_perfis') || $helper.verificaPermissao('visualizar_submenu_prefeituras'))">
      <div v-b-toggle.collapse-4>
        <b-icon icon="gear-fill" class="d-none d-xl-inline text-dark ml-2" />
        <b-nav-text data-testid="button-visualizar-configuracoes" class="ml-3 text-dark">Configurações</b-nav-text>
      </div>
      <b-collapse id="collapse-4" class="mt-2" is-nav>
        <b-list-group>
          <b-list-group-item
            data-testid="button-visualizar-setores"
            v-if="$helper.verificaPermissao('visualizar_submenu_setor')"
            to="/setor"
            exact-active-class="bg-info-50 font-weight-bolder"
            >Setor</b-list-group-item
          >
          <b-list-group-item
            v-if="$helper.verificaPermissao('visualizar_submenu_eixos_subeixos')"
            to="/eixos"
            exact-active-class="bg-info-50 font-weight-bolder"
            >Eixos</b-list-group-item
          >
          <b-list-group-item
            v-if="$helper.verificaPermissao('visualizar_submenu_perfis')"
            to="/perfil"
            exact-active-class="bg-info-50 font-weight-bolder"
            >Perfis e permissões</b-list-group-item
          >
          <b-list-group-item
            v-if="$helper.verificaPermissao('visualizar_submenu_prefeituras')"
            to="/prefeituras"
            exact-active-class="bg-info-50 font-weight-bolder"
            >Prefeituras</b-list-group-item
          >
        </b-list-group>
      </b-collapse>
    </b-nav-item>
    <slot></slot>
  </b-nav>
</template>

<script>
export default {
  name: 'Sidebar',
  data() {
    return {
      signatarios: [],
      profile: '',
    };
  },
  methods: {
    async getSignatarios() {
      let user_id = localStorage.getItem('user_id');
      try {
        const response = await this.$http.get('user-signatario/' + user_id);
        response.data.forEach(signatarios => {
          if (signatarios.pivot.status === null) {
            let tipo = signatarios.nome_original;
            tipo = tipo.substr(0, tipo.indexOf('-'));
            signatarios.tipo = tipo;
            this.signatarios.push(signatarios);
          }
        });
      } catch (error) {
        console.log(error.response);
      }
    },
    getPerfil() {
      JSON.parse(localStorage.getItem('user')).perfil.grupo_perfil_id == '1'
        ? (this.profile = 'sdc')
        : (this.profile = 'prefeitura');
    },
  },
  mounted() {
    this.getSignatarios();
    this.getPerfil();
  },
};
</script>
<style scoped>
.bg-info-50 {
  background-color: rgb(23, 162, 184, 0.2) !important;
}
.border-item-left {
  border-left: 5px solid rgb(23, 162, 184) !important;
}
</style>
