<template>
  <main>
    <router-link to="/eixos"> <b-icon icon="arrow-left" /> Eixos</router-link>
    <b-container fluid class="mt-4 p-4 border">
      <b-row class="mb-4">
        <b-col class="md-6">
          <h4 class="mb-0">SubEixos de {{ this.eixo.descricao }}</h4>
        </b-col>
        <b-col class="md-6">
          <b-button
            @click="showNovo"
            v-b-modal.modal-1
            variant="outline-primary"
            :disabled="isLoading"
            class="ml-1 float-right"
            >Cadastrar
          </b-button>
        </b-col>
      </b-row>

      <b-overlay :show="isLoading" rounded="sm">
        <div class="table-responsive">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th scope="col" class="col-2">#</th>
                <th scope="col">Descrição</th>
                <th scope="col" class="col-2">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="sub in subeixos" :key="sub.id">
                <td>{{ sub.id }}</td>
                <td>{{ sub.descricao }}</td>
                <td>
                  <div class="d-flex align-items-center">
                    <button
                      @click="showEditar(sub)"
                      v-b-modal.modal-1
                      class="btn btn-primary btn-sm"
                      title="Editar"
                    >
                      <b-icon icon="pencil"></b-icon>
                    </button>
                    <button
                      @click="excluirSubeixo(sub)"
                      class="btn btn-danger btn-sm ml-2"
                    >
                      <b-icon icon="trash"></b-icon>
                    </button>
                    <button
                      v-b-tooltip.hover
                      title="Ver checklist"
                      @click="
                        $router.push(
                          `/eixos/${$route.params.id}/${sub.id}/checklist`
                        )
                      "
                      class="btn btn-warning btn-sm ml-2"
                    >
                      <b-icon icon="arrow-right"></b-icon>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-overlay>

      <b-modal
        id="modal-1"
        :title="editar ? 'Editar SubEixo' : 'Novo SubEixo'"
        :hide-footer="true"
        title-class="font-weight-bold"
      >
        <b-form>
          <div class="row">
            <div class="col-12">
              <b-form-group
                label="Descrição: *"
                label-class="font-weight-bolder"
              >
                <b-form-input
                  v-model="subDescription"
                  required
                  placeholder="Digite uma descrição..."
                />
                <form-error
                  v-if="validationErrors.descricao"
                  :error="['O campo descrição é obrigatório']"
                />
              </b-form-group>
            </div>
            <div class="col-12">
              <b-button
                @click="editar ? editarSubeixo() : addCategoria()"
                variant="primary"
                :disabled="isLoading"
                class="float-right"
                >{{ editar ? 'Editar' : 'Salvar' }}</b-button
              >
            </div>
          </div>
        </b-form>
      </b-modal>
    </b-container>
  </main>
</template>

<script>
import FormError from '@/components/FormError';
export default {
  components: { FormError },
  data() {
    return {
      eixo: {},
      isLoading: false,
      subeixo: {},
      subeixos: [],
      subDescription: '',
      validationErrors: {
        descricao: null,
        eixo_id: null,
      },
      editar: false,
    };
  },
  methods: {
    addCategoria() {
      this.editar = false;

      let data = {
        eixo_id: this.eixo.id,
        descricao: this.subDescription,
      };
      this.isLoading = true;
      this.$http
        .post('subeixos', data, {})
        .then(res => {
          this.$swal({
            title: 'Subeixo cadastrado com sucesso!',
            icon: 'success',
          });
          this.subeixos.push(res.data);
          this.isLoading = false;
          this.clear();
          this.$bvModal.hide('modal-1');
        })
        .catch(error => {
          this.validationErrors.descricao =
            error.response.data.descricao ?? null;
          this.validationErrors.eixo_id = error.response.data.eixo_id ?? null;
          console.log(error.response.data);
          this.isLoading = false;
        });
    },
    getSubEixos() {
      this.isLoading = true;
      this.$http
        .get('eixos', {})
        .then(res => {
          const findEixo = res.data.find(
            eixo => eixo.id.toString() === this.$route.params.id
          );
          this.eixo = findEixo;
          this.subeixos = findEixo.subeixos;
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
    showNovo() {
      this.editar = false;
      this.subDescription = '';
    },
    showEditar(subeixo) {
      this.editar = true;
      this.subeixo = subeixo;
      this.subDescription = subeixo.descricao;
    },
    editarSubeixo() {
      this.isLoading = true;
      this.$http
        .put(
          `subeixos/${this.subeixo.id}`,
          { descricao: this.subDescription },
          {}
        )
        .then(res => {
          this.subeixos = this.subeixos.map(sub =>
            sub.id === res.data.id ? res.data : sub
          );
          this.isLoading = false;
          this.$swal({
            title: 'Subeixo editado com sucesso!',
            icon: 'success',
          });
          this.subeixo = {};
          this.subDescription = '';
          this.$bvModal.hide('modal-1');
        })
        .catch(error => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
    excluirSubeixo(sub) {
      this.$swal({
        icon: 'warning',
        title: 'Tem certeza que deseja excluir esse subeixo?',
        showDenyButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#3085d6',
        denyButtonText: 'Cancelar',
      })
        .then(result => {
          this.isLoading = true;
          if (result.isConfirmed) {
            this.$http.delete(`subeixos/${sub.id}`, {});
            this.$swal('Subeixo removido com sucesso!', '', 'success');
            this.subeixos = this.subeixos.filter(elem => elem.id !== sub.id);
          } else if (result.isDenied) {
            this.$swal('Uffa! Foi por pouco.', '', 'warning');
          }
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error.response);
          this.isLoading = false;
        });
    },
    clear() {
      this.subDescription = '';
      this.validationErrors.descricao = null;
      this.validationErrors.eixo_id = null;
    },
  },
  created() {
    this.getSubEixos();
  },
  beforeCreate() {
    //Check if profile is SDC, if not, redirect to home.
    if (JSON.parse(localStorage.getItem('user')).perfil.grupo_perfil_id != '1') {
      this.$router.push('/processos/minha-unidade');
    }
  },
};
</script>

<style scoped>
td,
th {
  vertical-align: middle !important;
}
</style>
